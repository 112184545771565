export default function useManualDownLoad() {
  function manualDownLoad(menuCode) {
    // Chcek!! 매뉴얼 수정 후 캐시 회피를 위해  /components/Aside.vue에서 useManualDown.js 파일 버전 수정 
    const lProductType = localStorage.getItem("productType");
     console.log("lProductType", lProductType);
     console.log("manualDownLoad", menuCode);
    let filePath = '/manual/pharmacy/UBIST 매뉴얼_Pharmacy 통합.pdf' 
    let fileName = 'UBIST 매뉴얼_Pharmacy 통합.pdf'
    
    if (menuCode === 'main')
    {
      if (lProductType == 1) {
        filePath = "/manual/pharmacy/UBIST 매뉴얼_Pharmacy 통합.pdf";
        fileName = "UBIST 매뉴얼_Pharmacy 통합.pdf";
      }else if (lProductType == 2) {
        filePath = "/manual/clinic/UBIST 매뉴얼_Clinic 통합.pdf";
        fileName = "UBIST 매뉴얼_Clinic 통합.pdf";
      }else if (lProductType == 3) {
        filePath = "/manual/HCD/UBIST 매뉴얼_HCD 통합.pdf";
        fileName = "UBIST 매뉴얼_HCD 통합.pdf";
      }

    } else {

      switch (menuCode) {
        case "S001":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Sales.pdf";
          fileName = "UBIST 매뉴얼_Sales 분석.pdf";
          break;
        case "S002": 
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Weekly.pdf";
          fileName = "UBIST 매뉴얼_Weekly 분석.pdf";
          break;
        case "S003":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Non-reimbursement.pdf";
          fileName = "UBIST 매뉴얼_Non-Reimbursement 분석.pdf";
          break;
        case "S004":	
          filePath = "/manual/pharmacy/UBIST 매뉴얼_GIS.pdf";
          fileName = "UBIST 매뉴얼_GIS 분석.pdf";
          break;
        case "S005":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_SoB.pdf"; 
          fileName = "UBIST 매뉴얼_SoB 분석.pdf";
          break;
        case "S007":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Combination 1(New).pdf";
          fileName = "UBIST 매뉴얼_Combination 1(New) 분석.pdf";
          break;
        case "S008":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Combination 2.pdf";
          fileName = "UBIST 매뉴얼_Combination 2 분석.pdf";
          break;
        case "S009":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Diagnosis 1.pdf";
          fileName = "UBIST 매뉴얼_Diagnosis 1 분석.pdf";
          break;
        case "S010":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Diagnosis 2.pdf";
          fileName = "UBIST 매뉴얼_Diagnosis 2 분석.pdf";
          break;
        case "S011":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Generic.pdf";
          fileName = "UBIST 매뉴얼_Generic 분석.pdf";
          break;
        case "S012":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Pattern Product.pdf";
          fileName = "UBIST 매뉴얼_Pattern Product 분석.pdf";
          break;
        case "S013":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Dosage.pdf";
          fileName = "UBIST 매뉴얼_Dosage 분석.pdf";
          break;
        case "S016":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Pattern Molecule.pdf";
          fileName = "UBIST 매뉴얼_Pattern Molecule 분석.pdf"
          break;
        case "S017":
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Pattern ATC.pdf";
          fileName = "UBIST 매뉴얼_Pattern ATC 분석.pdf";
          break;
  
        case "S027":
          filePath = "/manual/clinic/UBIST 매뉴얼_Clinic Vaccine Sales.pdf";
          fileName = "UBIST 매뉴얼_Clinic Vaccine Sales.pdf";
          break;

          case "S026":
          filePath = "/manual/clinic/UBIST 매뉴얼_Clinic Diagnosis.pdf";
          fileName = "UBIST 매뉴얼_Clinic Diagnosis.pdf";
          break;

  
        case "S015":  
          filePath = "/manual/HCD/UBIST 매뉴얼_HCD Sales.pdf";
          fileName = "UBIST 매뉴얼_HCD Sales.pdf";
          break;
        case "S024":  // Handling
          filePath = "/manual/HCD/UBIST 매뉴얼_HCD Handling.pdf";
          fileName = "UBIST 매뉴얼_HCD Handling.pdf";
          break;
        case "S025":  //Switchong
          filePath = "/manual/HCD/UBIST 매뉴얼_HCD Switching.pdf";
          fileName = "UBIST 매뉴얼_HCD Switching.pdf";
          break;

        case "S028":  // 과거데이터
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Pharmacy 과거 데이터.pdf";
          fileName = "UBIST 매뉴얼_Pharmacy 과거 데이터.pdf";
          break;
        case "S029":  // 과거데이터
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Pharmacy 과거 데이터.pdf";
          fileName = "UBIST 매뉴얼_Pharmacy 과거 데이터.pdf";
          break;
        case "S030":  // 과거데이터
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Pharmacy 과거 데이터.pdf";
          fileName = "UBIST 매뉴얼_Pharmacy 과거 데이터.pdf";
          break;
        case "S031":  // 과거데이터
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Pharmacy 과거 데이터.pdf";
          fileName = "UBIST 매뉴얼_Pharmacy 과거 데이터.pdf";
          break;
        case "S032":  // 과거데이터
          filePath = "/manual/pharmacy/UBIST 매뉴얼_Pharmacy 과거 데이터.pdf";
          fileName = "UBIST 매뉴얼_Pharmacy 과거 데이터.pdf";
          break;
      }
      
    }
   

    const downloadLink = document.createElement("a");
    downloadLink.href = filePath;
    downloadLink.download = fileName;
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
  }
  return { manualDownLoad };
}
