<template>
  <header class="header is-expansion">
    <!-- 20230612 is-expansion 클래스 추가 -->
    <div class="header-inner">
      <div class="header-logo" v-on:click="moveMenu('main', 'Y')">
        <img src="@/assets/img/logo.png" alt="UBIST" style="padding-right: 60px;"/>
        <!--<img src="@/assets/img/beta_logo.png" alt="UBIST" class="logo-size"/>-->
      </div>
      <nav class="header-menu-wrap">
        <ul class="header-menu">
          <li v-for="(menu, i) in menuList" v-bind:key="(menu, i)">
            <a v-if="menu.services.length > 0">
              {{ this.$i18n.locale == "en" ? menu.englishName : menu.name }}
            </a>
            <a v-else v-on:click="moveMenu(menu.code, menu.viewYn)">
              {{ this.$i18n.locale == "en" ? menu.englishName : menu.name }}
              <span
                v-show="menu.viewYn == 'Y'"
                @click="(event) => onClickPopup(event, menu.code, menu.viewYn)"
                >X</span
              >
            </a>
            <ul v-if="menu.services.length > 0">
              <li
                v-for="(menuChild, i) in menu.services"
                v-bind:key="(menuChild, i)"
              >
                <template v-if="!(menuChild.code === 'S032' && menuList[0].services[0].code !== 'S001')">
                  <a v-on:click="moveMenu(menuChild.code, menuChild.viewYn)">
                    {{
                      this.$i18n.locale == "en"
                        ? menuChild.englishName
                        : menuChild.name
                    }}
                    <span
                      class="material-symbols-outlined"
                      @click="
                        (event) =>
                          onClickPopup(event, menuChild.code, menuChild.viewYn)
                      "
                      >new_window</span
                    >
                  </a>
                </template>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <div class="userInfo">
        <button class="lang-button" @click="changeLocale">
          <span class="material-symbols-outlined">language</span>
          {{ $t("button.lang") }}
        </button>
        <button class="login-button" @click="authLogout">
          {{ $t("button.logout") }}
        </button>
      </div>
    </div>

    <AlertDialog
      v-if="isAlertDialogOpen"
      :maxWidthOption="alertDialog.maxWidthOption"
      :title="alertDialog.title"
      :message="alertDialog.message"
      @confirm="closeAlertDialog"
    />
  </header>
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import { ref, onMounted, defineComponent } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common";
import menuData from "@/data/menuData.js";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import constants from "@/data/constants.js";
import { logout } from "@/lib/loginService.js";
import ubistApi from "@/lib/ubistApi";

export default defineComponent({
  name: "Header",
  components: {
    AlertDialog,
  },
  setup() {
    const { locale, t } = useI18n();
    const router = useRouter();

    const menuName = "";

    // 스토어
    const commonStore = useCommonStore();
    const { lang, user, menuList, menuCode, productType } = storeToRefs(commonStore);

    // const productType = ref(null);

    // Menu navigation
    const moveMenu = (code, viewYn) => {
      const menuMap = menuData;
      const path = getMenuPath(menuMap, code);
      if (viewYn == "Y") {
        router.push({
          path: path,
        });
      } else {
        router.push({
          path: path,
        });
      }
      //매뉴얼 다운위한 메뉴코드 셋팅
      menuCode.value = code;
    };

    const getMenuCode = (menuMap, name) => {
      let code = "";
      menuMap.forEach((menu) => {
        if (name === menu.name) {
          code = menu.code;
          return true;
        }
      });
      return code;
    };

    // AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
    });

    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };

    const authLogout = async () => {
      await commonStore.logout();
      logout();
    };

    // Change locale
    const changeLocale = () => {
      if (locale.value == "en") {
        locale.value = "ko";
      } else {
        locale.value = "en";
      }
      localStorage.setItem("lang", locale.value);
      lang.value = locale.value;
      
      ubistApi.postLogin();
      ubistApi.postLogin2();
      // window.dispatchEvent(new CustomEvent('changedLang', { detail: locale.value }));
    };

    // Popup
    const onClickPopup = (event, code, viewYn) => {
      event.stopPropagation();
      const menuMap = menuData;
      const path = getMenuPath(menuMap, code);
      const menu = code;
      if (viewYn == "Y") {
        window.open(`${window.location.origin}` + path, "_blank");
        // window.open(`${window.location.origin}` + path, path);
      } else {
        //alert(commonStore.$t("message.validation_menu", { menu }));
        window.open(`${window.location.origin}` + path, "_blank");
        // window.open(`${window.location.origin}` + path, path);
      }
    };

    // Get Menu Path
    const getMenuPath = (menuMap, code) => {
      let path = "";
      menuMap.forEach((menu) => {
        if (code === menu.code) {
          path = menu.path;
          return true;
        }
      });
      return path;
    };

    // On Mounted (Lifecycle hook)
    onMounted(() => {
      if(localStorage.getItem('productType')) {
        commonStore.getMenuList(localStorage.getItem('productType'));
        // productType.value = localStorage.getItem('productType');
      } else {
        commonStore.getMenuList(0);
      }
    });

    return {
      productType,
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
      menuList,
      authLogout,
      changeLocale,
      onClickPopup,
      moveMenu,
      parentMenu: menuName,
      parentMenuCode: getMenuCode(menuData, menuName),
      constants,
    };
  },
});
</script>

<style scoped>
.header {
  position: relative; /* 20230712 수정 */
  width: 100%;
  height: 60px; /* 20230704 수정 */
  background-color: #fff;
  transition: padding 0.3s;
  z-index: 100;
}
.header::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  height: 1px;
  background-color: #ddd;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  /* padding: 0 40px; */
  padding: 0 0 0 76px;
}

.header-logo {
  line-height: 0;
  cursor: pointer;
}

.header-logo img {
  /* width: 142px;
  height: 32px; */
}

/* GNB 메뉴 */
.header-menu-wrap {
  margin-right: auto;
  margin-left: 43px;
}

.header-menu {
  display: flex;
}

.header-menu > li {
  position: relative;
}

.header-menu > li > a {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 60px; /* 20230704 수정 */
  height: 60px; /* 20230704 수정 */
  padding: 0 32px;
  transition: all 0.3s;
  cursor: pointer;
}

.header-menu > li ul {
  display: none;
  position: absolute;
  top: 52px; /* 20230704 수정 */
  left: 50%;
  z-index: 3;
  padding: 16px 10px;
  width: max-content;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 3px 4px 16px -2px rgba(0, 0, 0, 0.32);
  transform: translateX(-50%);
}

.header-menu > li ul li {
  margin-left: 0px;
}

.header-menu > li ul a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  padding: 3px 10px;
  background-color: #fff;
  border-radius: var(--border-radius);
  transition: all 0.3s;
  cursor: pointer;
}

.header-menu > li ul a:hover {
  background-color: #eee;
}

.header-menu > li:hover > a {
  color: var(--primary-color);
}

.header-menu > li ul li .material-symbols-outlined {
  display: inline-block;
  font-size: 18px;
  font-variation-settings: "wght" 200;
  margin-left: 12px;
}

.header-menu > li:hover ul {
  display: block;
}

.userInfo {
  display: flex;
  align-items: center;
  padding-right: 40px;
}

.lang-button {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  margin-right: 24px;
}

.lang-button .material-symbols-outlined {
  display: inline-block;
  margin-right: 4px;
  font-variation-settings: "wght" 200;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  width: 78px;
  height: 28px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 28px;
}

.sub-menu-modal {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 4px #0000001a;
  padding: 10px;
  border-radius: 4px;
  z-index: 200;
  margin-top: 10px;
}

.logo-size {
    width: 142px;
    height: 22px;
}
</style>
