import { createRouter, createWebHistory } from "vue-router";
import { useCommonStore } from "@/store/common";
import ubistApi from "@/lib/ubistApi";
import Main from "@/views/main/Main.vue";
import Login from "@/views/login/Login.vue";
import Error404 from "@/views/error/404.vue";
import Sales from "@/views/pharmarcy/Sales.vue";
import Weekly from "@/views/pharmarcy/Weekly.vue";
import NonReimbursement from "@/views/pharmarcy/NonReimbursement.vue";
import GIS from "@/views/pharmarcy/GIS.vue";
import SOB from "@/views/pharmarcy/SOB.vue";
import Clinic from "@/views/clinic/Clinic.vue";
import VaccineSalesNew from "@/views/clinic/VaccineSalesNew.vue";
import CL_Diagnosis1 from "@/views/clinic/Diagnosis1.vue";
import Hira from "@/views/hira/Hira.vue";
import CombinationTherapy1 from "@/views/pharmarcy/CombinationTherapy1.vue";
import CombinationTherapyNew from "@/views/pharmarcy/CombinationTherapyNew.vue";
import CombinationTherapy2 from "@/views/pharmarcy/CombinationTherapy2.vue";
import Diagnosis1 from "@/views/pharmarcy/Diagnosis1.vue";
import Diagnosis2 from "@/views/pharmarcy/Diagnosis2.vue";
import Generic from "@/views/pharmarcy/Generic.vue";
import Pattern_Product from "@/views/pharmarcy/Pattern_Product.vue";
import Pattern_Molecule from "@/views/pharmarcy/Pattern_Molecule.vue";
import Pattern_ATC from "@/views/pharmarcy/Pattern_ATC.vue";
import Dosage from "@/views/pharmarcy/Dosage.vue";
import VaccineSales from "@/views/pharmarcy/VaccineSales.vue";
import DrugInfo from "@/views/pharmarcy/DrugInfo.vue";
import ExpandReport from "@/views/pharmarcy/ExpandReport.vue";
import Notice from "@/views/userSupport/Notice.vue";
import NoticeView from "@/views/userSupport/NoticeView.vue";
import Archives from "@/views/userSupport/Archives.vue";
import Favorite from "@/views/favorite/Favorite.vue";
import Callback from "@/views/login/Callback.vue";
import Logout from "@/views/login/Logout.vue";
import Refresh from "@/views/login/Refresh.vue";
import NoAccess from "@/views/login/NoAccess.vue";
import SoB2 from "@/views/tcp/SoB2.vue";
import Handling from "@/views/tcp/Handling.vue";
import Switching from "@/views/tcp/Switching.vue";
import Old_D1_Sales from "@/views/userSupport/Old_D1_Sales.vue";
import Old_D1_Non from "@/views/userSupport/Old_D1_Non.vue";
import Old_UP_Sales from "@/views/userSupport/Old_UP_Sales.vue";
import Old_UP_Non from "@/views/userSupport/Old_UP_Non.vue";
import DrugInfomation from "@/views/pharmarcy/DrugInfomation.vue";

const routes = [
  { path: "/:pathMatch(.*)*", component: Error404 },
  { path: "/", name: "main", component: Main, props: true },
  {
    path: '/noaccess',
    name: "NoAccess",
    component: NoAccess, // "이용 권한이 없습니다" 메시지를 보여주는 컴포넌트
    props: true,
  },
  //{ path: "/main", name: "main", component: Main, props: true },
  { path: "/sales", name: "sales", component: Sales, props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S001")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  { path: "/weekly", name: "weekly", component: Weekly, props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));
      
      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S002")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/nonReimbursement",
    name: "nonReimbursement",
    component: NonReimbursement,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S003")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  { path: "/clinic", name: "clinic", component: Clinic, props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S014")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  { path: "/hira", name: "hira", component: Hira, props: true },
  // { path: "/auth", name: "Auth", component: Login, props: true },
  { path: "/auth/:productType", name: "Auth", component: Login, props: true },
  { path: "/logout", name: "Logout", component: Logout, props: true },
  { path: "/callback", name: "Callback", component: Callback, props: false },
  { path: "/refresh", name: "Refresh", component: Refresh, props: false },
  {
    path: "/gis",
    name: "gis",
    component: GIS,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S004")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/sob",
    name: "sob",
    component: SOB,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S005")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/combinationtherapy1",
    name: "combinationTherapy1",
    component: CombinationTherapy1,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S006")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/combinationtherapynew",
    name: "combinationTherapyNew",
    component: CombinationTherapyNew,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S007")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/combinationtherapy2",
    name: "combinationTherapy2",
    component: CombinationTherapy2,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S008")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/diagnosis1",
    name: "Diagnosis1",
    component: Diagnosis1,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S009")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/diagnosis2",
    name: "Diagnosis2",
    component: Diagnosis2,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S010")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/generic",
    name: "generic",
    component: Generic,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S011")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/patternP",
    name: "pattern-product",
    component: Pattern_Product,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S012")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/patternM",
    name: "pattern-molecule",
    component: Pattern_Molecule,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S016")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/patternA",
    name: "pattern-ATC",
    component: Pattern_ATC,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S017")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/dosage",
    name: "dosage",
    component: Dosage,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S013")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/vaccinesalesold",
    name: "vaccinesalesold",
    component: VaccineSales,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S014")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/vaccinesales",
    name: "vaccinesales",
    component: VaccineSalesNew,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S027")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/cl_diagnosis1",
    name: "CL_Diagnosis1",
    component: CL_Diagnosis1,
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S026")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/SoB2_96cb22bce4e827a8321ebeac9f280e5ca939b4d3c6c0a1f3d143e62257a305d7", 
    redirect :"/hcdsales"
  },
  {
    path: "/hcdsales", 
    name: "SoB2", 
    component: SoB2, 
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S015")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/Switching", 
    name: "Switching", 
    component: Switching, 
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S025")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/Handling", 
    name: "Handling", 
    component: Handling, 
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S024")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/Old_D1_Sales", 
    name: "Old_D1_Sales", 
    component: Old_D1_Sales, 
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S028")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/Old_D1_Non", 
    name: "Old_D1_Non", 
    component: Old_D1_Non, 
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S029")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/Old_UP_Sales", 
    name: "Old_UP_Sales", 
    component: Old_UP_Sales, 
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S030")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/Old_UP_Non", 
    name: "Old_UP_Non", 
    component: Old_UP_Non, 
    props: true,
    beforeEnter: async (to, from, next) => {
      const commonStore = useCommonStore();
      if (commonStore.userServiceList && commonStore.userServiceList.length === 0)
        commonStore.userServiceList = await ubistApi.getServices(localStorage.getItem('productType'));

      if ((commonStore.userServiceList && commonStore.userServiceList.length === 0) || !commonStore.userServiceList.find((l) => l.serviceCode === "S031")) {
        next('/noaccess'); // 조건에 맞지 않으면 'no-access' 페이지로 리다이렉트
      } else {
        next();
      }
    }
  },
  {
    path: "/ExpandReport",
    name: "ExpandReport",
    component: ExpandReport,
    props: true,
  },
  {
    path: "/DrugInfo",
    name: "DrugInfo",
    component: DrugInfo,
    props: true,
  },
  {
    path: "/Notice",
    name: "Notice",
    component: Notice,
    props: true,
  },
  {
    path: "/NoticeView",
    name: "NoticeView",
    component: NoticeView,
    props: true,
  },
  {
    path: "/Archives",
    name: "Archives",
    component: Archives,
    props: true,
  },
  {
    path: "/ArchivesView",
    name: "ArchivesView",
    component: NoticeView,
    props: true,
  },
  {
    path: "/ImagePopup",
    name: "ImagePopup",
    component: () => import("@/components/ImagePopup.vue"),
    props: true,
  },
  {
    path: "/Favorite",
    name: "favorite",
    component: Favorite,
    props: true,
  },
  {
    path: "/DrugInfomation/:drugCode",
    name: "DrugInfomation",
    component: DrugInfomation,
    props: true
  }
];

// 운영 개발 확인
if (process.env.NODE_ENV === "local") {
  routes.forEach((route) => {
    route.beforeEnter = undefined;
  });
}


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
