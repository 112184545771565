export default {
  "title": {
    "sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])};fn.source="Sales";return fn;})(),
    "weekly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])};fn.source="Weekly";return fn;})(),
    "non-reimbursement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-reimbursement"])};fn.source="Non-reimbursement";return fn;})(),
    "combinationⅡ": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination Ⅱ"])};fn.source="Combination Ⅱ";return fn;})(),
    "diagnosisⅡ": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnosis Ⅱ"])};fn.source="Diagnosis Ⅱ";return fn;})(),
    "dosage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosage"])};fn.source="Dosage";return fn;})(),
    "vaccine": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaccine Sales"])};fn.source="Vaccine Sales";return fn;})(),
    "gis": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIS"])};fn.source="GIS";return fn;})(),
    "sob": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SoB"])};fn.source="SoB";return fn;})(),
    "combination_therapy1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination I"])};fn.source="Combination I";return fn;})(),
    "combination_therapy_new": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination I (New)"])};fn.source="Combination I (New)";return fn;})(),
    "combination_therapy2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination Ⅱ"])};fn.source="Combination Ⅱ";return fn;})(),
    "diagnosis": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnosis"])};fn.source="Diagnosis";return fn;})(),
    "diagnosis1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnosis I"])};fn.source="Diagnosis I";return fn;})(),
    "diagnosis2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnosis Ⅱ"])};fn.source="Diagnosis Ⅱ";return fn;})(),
    "generic": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic"])};fn.source="Generic";return fn;})(),
    "pattern_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern Product"])};fn.source="Pattern Product";return fn;})(),
    "pattern_molecule": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern Molecule"])};fn.source="Pattern Molecule";return fn;})(),
    "pattern_ATC": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern ATC"])};fn.source="Pattern ATC";return fn;})(),
    "popup_prodDetail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Product Search"])};fn.source="Detail Product Search";return fn;})(),
    "popup_options": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result value options"])};fn.source="Result value options";return fn;})(),
    "notice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])};fn.source="Notice";return fn;})(),
    "archives": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archives"])};fn.source="Archives";return fn;})(),
    "drug_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drug Information"])};fn.source="Drug Information";return fn;})(),
    "vaccine_sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaccine Sales"])};fn.source="Vaccine Sales";return fn;})(),
    "unsubscribed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribed"])};fn.source="Unsubscribed";return fn;})(),
    "sob2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])};fn.source="Sales";return fn;})(),
    "handling": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handling"])};fn.source="Handling";return fn;})(),
    "switching": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching"])};fn.source="Switching";return fn;})(),
    "Old_Data": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Data"])};fn.source="Old Data";return fn;})(),
    "Old_D1_Sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D1 Sales (18)"])};fn.source="D1 Sales (18)";return fn;})(),
    "Old_D1_Non": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D1 Non-reimbursement (18)"])};fn.source="D1 Non-reimbursement (18)";return fn;})(),
    "Old_UP_Sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UP Sales (08~23)"])};fn.source="UP Sales (08~23)";return fn;})(),
    "Old_UP_Non": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UP Non-reimbursement (10~23)"])};fn.source="UP Non-reimbursement (10~23)";return fn;})()
  },
  "button": {
    "lang": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOREAN"])};fn.source="KOREAN";return fn;})(),
    "login": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])};fn.source="Login";return fn;})(),
    "logout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])};fn.source="Logout";return fn;})(),
    "search": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])};fn.source="Search";return fn;})(),
    "searchClean": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])};fn.source="Default";return fn;})(),
    "complete_confirm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])};fn.source="Confirm";return fn;})(),
    "complete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])};fn.source="Complete";return fn;})(),
    "combine": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combine"])};fn.source="Combine";return fn;})(),
    "put": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put"])};fn.source="Put";return fn;})(),
    "reporting": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting"])};fn.source="Reporting";return fn;})(),
    "reset": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])};fn.source="Reset";return fn;})(),
    "detailed_search": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adv.Search"])};fn.source="Adv.Search";return fn;})(),
    "prev_result": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])};fn.source="Result";return fn;})(),
    "favorite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])};fn.source="Favorites";return fn;})(),
    "excel_export": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report to Excel"])};fn.source="Report to Excel";return fn;})(),
    "email_export": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send via email"])};fn.source="Send via email";return fn;})(),
    "mdx_view": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show MDX"])};fn.source="Show MDX";return fn;})(),
    "openOption": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])};fn.source="Options";return fn;})(),
    "save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
    "close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])};fn.source="Close";return fn;})(),
    "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])};fn.source="Cancel";return fn;})(),
    "delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])};fn.source="Delete";return fn;})(),
    "confirm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])};fn.source="Confirm";return fn;})(),
    "product_search": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product search"])};fn.source="Product search";return fn;})(),
    "prev_step": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["◀ Previous Step"])};fn.source="◀ Previous Step";return fn;})(),
    "next_step": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Step ▶"])};fn.source="Next Step ▶";return fn;})(),
    "move_top": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move To Top"])};fn.source="Move To Top";return fn;})(),
    "move_up": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move Up"])};fn.source="Move Up";return fn;})(),
    "move_down": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move Down"])};fn.source="Move Down";return fn;})(),
    "move_bottom": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move To Bottom"])};fn.source="Move To Bottom";return fn;})(),
    "change_rowsCols": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace Row/Column"])};fn.source="Replace Row/Column";return fn;})(),
    "analyze": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyze"])};fn.source="Analyze";return fn;})(),
    "select": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])};fn.source="Select";return fn;})(),
    "editing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing"])};fn.source="Editing";return fn;})(),
    "manual_download": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual Download"])};fn.source="Manual Download";return fn;})(),
    "view_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View List"])};fn.source="View List";return fn;})(),
    "show_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show List"])};fn.source="Show List";return fn;})(),
    "new_folder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Folder"])};fn.source="Add Folder";return fn;})(),
    "add_folder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new Folder"])};fn.source="Add new Folder";return fn;})(),
    "add_folder2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Folder"])};fn.source="Add Folder";return fn;})(),
    "add_favorites": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Favorites"])};fn.source="Add Favorites";return fn;})(),
    "service_settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Settings"])};fn.source="Service Settings";return fn;})(),
    "contents_settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents Settings"])};fn.source="Contents Settings";return fn;})(),
    "set_location": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Location"])};fn.source="Set Location";return fn;})(),
    "market_definition": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Definition"])};fn.source="Market Definition";return fn;})(),
    "insert": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert"])};fn.source="Insert";return fn;})(),
    "favorites_settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites Settings"])};fn.source="Favorites Settings";return fn;})(),
    "add_current_service_settings_to_favorites": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add current service settings to favorites"])};fn.source="Add current service settings to favorites";return fn;})(),
    "add_current_contents_settings_to_favorites": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add current contents settings to favorites"])};fn.source="Add current contents settings to favorites";return fn;})(),
    "select_start_week": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Week"])};fn.source="Start Week";return fn;})(),
    "select_end_week": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Week"])};fn.source="End Week";return fn;})(),
    "rename": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])};fn.source="Rename";return fn;})(),
    "add": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])};fn.source="Add";return fn;})(),
    "summation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summation"])};fn.source="Summation";return fn;})(),
    "print": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])};fn.source="Print";return fn;})(),
    "spread": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])};fn.source="Show";return fn;})(),
    "basic_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Information"])};fn.source="Basic Information";return fn;})(),
    "drug_price": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drug Price"])};fn.source="Drug Price";return fn;})(),
    "patent_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patent Information"])};fn.source="Patent Information";return fn;})(),
    "dosage_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosage Information (1MAT)"])};fn.source="Dosage Information (1MAT)";return fn;})(),
    "sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales (1MAT)"])};fn.source="Sales (1MAT)";return fn;})(),
    "favorite_newTap": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open a new tab"])};fn.source="Open a new tab";return fn;})(),
    "favorite_newWindow": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open a new window"])};fn.source="Open a new window";return fn;})(),
    "favorite_newTap_multi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Open all ", _interpolate(_named("count")), " in a new tab"])};fn.source="Open all {count} in a new tab";return fn;})(),
    "favorite_newWindow_multi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Open all ", _interpolate(_named("count")), " in a new window"])};fn.source="Open all {count} in a new window";return fn;})(),
    "aside_hidden": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically hide sidebar"])};fn.source="Automatically hide sidebar";return fn;})(),
    "register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])};fn.source="Register";return fn;})()
  },
  "label": {
    "selected_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected item"])};fn.source="Selected item";return fn;})(),
    "sum": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])};fn.source="Sum";return fn;})(),
    "combine_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combine Name"])};fn.source="Combine Name";return fn;})(),
    "summation_count": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summation Count"])};fn.source="Summation Count";return fn;})(),
    "contents_sum1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the "])};fn.source="About the ";return fn;})(),
    "contents_sum2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Name "])};fn.source="Content Name ";return fn;})(),
    "choose_not1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["◈ If you don't choose any item, it means "])};fn.source="◈ If you don't choose any item, it means ";return fn;})(),
    "choose_not2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([])};fn.source="";return fn;})(),
    "search_word": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a search word"])};fn.source="Please enter a search word";return fn;})(),
    "search_integrated": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Integrated Search"])};fn.source=" Integrated Search";return fn;})(),
    "no_content_selected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No content selected"])};fn.source="No content selected";return fn;})(),
    "columns": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal axis"])};fn.source="Horizontal axis";return fn;})(),
    "rows": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical axis"])};fn.source="Vertical axis";return fn;})(),
    "select_parentItem": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a "])};fn.source="Please select a ";return fn;})(),
    "popup_std": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Std."])};fn.source="Std.";return fn;})(),
    "popup_exp": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp."])};fn.source="Exp.";return fn;})(),
    "popup_customizingName": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CustomizingName"])};fn.source="CustomizingName";return fn;})(),
    "step2_atc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ Searching GIS ATC is available from level 2."])};fn.source="※ Searching GIS ATC is available from level 2.";return fn;})(),
    "step4_atc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching ATC is available only level 4."])};fn.source="Searching ATC is available only level 4.";return fn;})(),
    "search_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only search in the summation area."])};fn.source="You can only search in the summation area.";return fn;})(),
    "select_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select ingredients/products and proceed."])};fn.source="Select ingredients/products and proceed.";return fn;})(),
    "select_product2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select products and proceed."])};fn.source="Select products and proceed.";return fn;})(),
    "option_notice1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This option is only valid for "])};fn.source="This option is only valid for ";return fn;})(),
    "option_notice2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" have selected."])};fn.source=" have selected.";return fn;})(),
    "option_notice3": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Content only applies when selected."])};fn.source=" Content only applies when selected.";return fn;})(),
    "option_notice4": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The default sort order is Unordered."])};fn.source="The default sort order is Unordered.";return fn;})(),
    "not_selected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not selected"])};fn.source="Not selected";return fn;})(),
    "combination_treatment_precautions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only choose a medical department if the type is a higher general hospital, general hospital, or clinic. The detailed department can only be selected if the type is a higher general hospital or a general hospital."])};fn.source="You can only choose a medical department if the type is a higher general hospital, general hospital, or clinic. The detailed department can only be selected if the type is a higher general hospital or a general hospital.";return fn;})(),
    "trend_view": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trend View"])};fn.source="Trend View";return fn;})(),
    "molecule_doses": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching Molecule is only available doses."])};fn.source="Searching Molecule is only available doses.";return fn;})(),
    "doseform_not_supported": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doseform search is not supported when selecting a product."])};fn.source="Doseform search is not supported when selecting a product.";return fn;})(),
    "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["title"])};fn.source="title";return fn;})(),
    "content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content"])};fn.source="content";return fn;})(),
    "market_analysis": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Analysis"])};fn.source="Market Analysis";return fn;})(),
    "education_information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education Information"])};fn.source="Education Information";return fn;})(),
    "the_latest_drug_price": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Latest Drug Price"])};fn.source="The Latest Drug Price";return fn;})(),
    "manual": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])};fn.source="Manual";return fn;})(),
    "no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])};fn.source="No";return fn;})(),
    "date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])};fn.source="Date";return fn;})(),
    "attachment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])};fn.source="Attachment";return fn;})(),
    "prev": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prev"])};fn.source="Prev";return fn;})(),
    "next": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])};fn.source="Next";return fn;})(),
    "attachment_file": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment File"])};fn.source="Attachment File";return fn;})(),
    "input_folder_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Folder Name."])};fn.source="Enter Folder Name.";return fn;})(),
    "edit_folder_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Folder Name"])};fn.source="Edit Folder Name";return fn;})(),
    "set_folder_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Folder Name"])};fn.source="Set Folder Name";return fn;})(),
    "folder_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder Name"])};fn.source="Folder Name";return fn;})(),
    "input_favorite_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Favorites Name."])};fn.source="Enter Favorites Name.";return fn;})(),
    "edit_favorite_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Favorites"])};fn.source="Edit Favorites";return fn;})(),
    "set_favorite_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Favorites"])};fn.source="Set Favorites";return fn;})(),
    "favorite_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites Name"])};fn.source="Favorites Name";return fn;})(),
    "favorite_location": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites Location"])};fn.source="Favorites Location";return fn;})(),
    "notice_combination_therapy2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* The base items and The combi items can be selected only one of following items."])};fn.source="* The base items and The combi items can be selected only one of following items.";return fn;})(),
    "only_disease": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The result format can only be selected if the criterion is selected as a disease."])};fn.source="The result format can only be selected if the criterion is selected as a disease.";return fn;})(),
    "measurement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement"])};fn.source="Measurement";return fn;})(),
    "measurement_info_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to select the same criteria as an existing SoB analysis"])};fn.source="How to select the same criteria as an existing SoB analysis";return fn;})(),
    "measurement_info_subtitle1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the same criteria as the existing SoB analytics"])};fn.source="Select the same criteria as the existing SoB analytics";return fn;})(),
    "measurement_info_subtitle2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the same criteria as the existing SoB analytics II"])};fn.source="Select the same criteria as the existing SoB analytics II";return fn;})(),
    "measurement_info_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparison : Previous 3 months"])};fn.source="Comparison : Previous 3 months";return fn;})(),
    "measurement_info_measurement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement : Patient"])};fn.source="Measurement : Patient";return fn;})(),
    "measurement_info_output_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output Period : Previous 2 Years"])};fn.source="Output Period : Previous 2 Years";return fn;})(),
    "notice_combination_new_base": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When analyzing within the combined use range, select only the combined use range without selecting a standard item."])};fn.source="When analyzing within the combined use range, select only the combined use range without selecting a standard item.";return fn;})(),
    "view_all": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])};fn.source="View all";return fn;})(),
    "age_over": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over"])};fn.source="Over";return fn;})(),
    "age_under": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under"])};fn.source="Under";return fn;})(),
    "onequantity_over": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over"])};fn.source="Over";return fn;})(),
    "onequantity_under": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under"])};fn.source="Under";return fn;})(),
    "perday_over": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over"])};fn.source="Over";return fn;})(),
    "perday_under": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under"])};fn.source="Under";return fn;})(),
    "totalday_over": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over"])};fn.source="Over";return fn;})(),
    "totalday_under": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under"])};fn.source="Under";return fn;})(),
    "start_week": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Week"])};fn.source="Start Week";return fn;})(),
    "end_week": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Week"])};fn.source="End Week";return fn;})(),
    "molecule_total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])};fn.source="All";return fn;})(),
    "molecule_volume_total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All(s)"])};fn.source="All(s)";return fn;})(),
    "drug_info_infomain": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["· Brand/Drug searches, Drug prices, and patent information provide weekly library baseline information with the latest drug changes for the month.\n· The medication information, prescription preparation fluid trends provide information on the basis of the Monthly library with drug changes up to the previous month."])};fn.source="· Brand/Drug searches, Drug prices, and patent information provide weekly library baseline information with the latest drug changes for the month.\n· The medication information, prescription preparation fluid trends provide information on the basis of the Monthly library with drug changes up to the previous month.";return fn;})(),
    "drug_info_message1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for drug information."])};fn.source="Search for drug information.";return fn;})(),
    "drug_info_message2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least 2 characters for the drug name."])};fn.source="Please enter at least 2 characters for the drug name.";return fn;})(),
    "detail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])};fn.source="Read More";return fn;})(),
    "more_notice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Announcement"])};fn.source="More Announcement";return fn;})(),
    "browse_article": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse the archives"])};fn.source="Browse the archives";return fn;})(),
    "none": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])};fn.source="Empty";return fn;})(),
    "total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])};fn.source="Total";return fn;})(),
    "market_definition_selection": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you uncheck ATC containing the selected product, it will not be analyzed.\nYou analyze by selecting ATC comprising the market."])};fn.source="When you uncheck ATC containing the selected product, it will not be analyzed.\nYou analyze by selecting ATC comprising the market.";return fn;})(),
    "comparisonPeriod": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparison Period"])};fn.source="Comparison Period";return fn;})()
  },
  "message": {
    "validation_empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a search word"])};fn.source="Please enter a search word";return fn;})(),
    "validation_length": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a search word. Search term must be at least two characters long."])};fn.source="Please enter a search word. Search term must be at least two characters long.";return fn;})(),
    "validation_menu": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This menu is for ", _interpolate(_named("menu")), " members only."])};fn.source="This menu is for {menu} members only.";return fn;})(),
    "select_newItem": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["New item '", _interpolate(_named("newItem")), "' will be added except for duplicate item '", _interpolate(_named("existItem")), "."])};fn.source="New item '{newItem}' will be added except for duplicate item '{existItem}.";return fn;})(),
    "validation_existsItem": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate entry"])};fn.source="Duplicate entry";return fn;})(),
    "validation_existsCombiItem": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("item")), "'is(are) already included in the item to be combined."])};fn.source="'{item}'is(are) already included in the item to be combined.";return fn;})(),
    "validation_intersectionCombiItem": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When summated the dependent categories of [", _interpolate(_named("subContents")), "] in the [", _interpolate(_named("content")), "],\nthe subcategory [", _interpolate(_named("lowSubContent")), "] will not be analyzed as it is added to the parent category [", _interpolate(_named("upSubContent")), "].\nPlease exclude the subcategory conditions for analysis."])};fn.source="When summated the dependent categories of [{subContents}] in the [{content}],\nthe subcategory [{lowSubContent}] will not be analyzed as it is added to the parent category [{upSubContent}].\nPlease exclude the subcategory conditions for analysis.";return fn;})(),
    "validation_existsItemName": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Item '", _interpolate(_named("item")), "' already exists. Please change it to a different name."])};fn.source="Item '{item}' already exists. Please change it to a different name.";return fn;})(),
    "validation_deleteItem": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the items you want to delete."])};fn.source="Select the items you want to delete.";return fn;})(),
    "check_loginId": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your ID."])};fn.source="Please enter your ID.";return fn;})(),
    "check_password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Password."])};fn.source="Please enter your Password.";return fn;})(),
    "select_columnsOrRows": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select at least one content on the horizontal and vertical axes."])};fn.source="You must select at least one content on the horizontal and vertical axes.";return fn;})(),
    "select_columns": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a column."])};fn.source="Please select a column.";return fn;})(),
    "select_rows": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a row."])};fn.source="Please select a row.";return fn;})(),
    "select_metrics": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a metrics."])};fn.source="Please select a metrics.";return fn;})(),
    "select_contents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No content selected. Please select the content you want to search."])};fn.source="No content selected. Please select the content you want to search.";return fn;})(),
    "select_items": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No item has been selected. Please select an item first."])};fn.source="No item has been selected. Please select an item first.";return fn;})(),
    "select_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a time period."])};fn.source="Please select a time period.";return fn;})(),
    "sametype_restriction": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only reference items of the same type can be selected."])};fn.source="Only reference items of the same type can be selected.";return fn;})(),
    "criterion_prerequisite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the criteria before excluding the product."])};fn.source="Please select the criteria before excluding the product.";return fn;})(),
    "exclusion_constraint": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products to be excluded cannot be excluded because they are not part of the selected criteria."])};fn.source="Products to be excluded cannot be excluded because they are not part of the selected criteria.";return fn;})(),
    "delete_contents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you delete a content, all of the details selected in that content will be deleted. Are you sure you want to delete it?"])};fn.source="If you delete a content, all of the details selected in that content will be deleted. Are you sure you want to delete it?";return fn;})(),
    "loading_data": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data is being analyzed."])};fn.source="Data is being analyzed.";return fn;})(),
    "validation_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unable to make a summation with any ", _interpolate(_named("contentName")), "."])};fn.source="Unable to make a summation with any {contentName}.";return fn;})(),
    "alert_metricShare": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you select share in measurement, share value may be inaccurate if analysis condition as below.\n(Note) Analysis condition \n1. If you have a combination of two or more content \n2. If it contains Merge Content \n3. If you selected a different level within the content \n(For example, ATC 2 and 3 are selected together)"])};fn.source="If you select share in measurement, share value may be inaccurate if analysis condition as below.\n(Note) Analysis condition \n1. If you have a combination of two or more content \n2. If it contains Merge Content \n3. If you selected a different level within the content \n(For example, ATC 2 and 3 are selected together)";return fn;})(),
    "validation_combiName_empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a vaid summation name."])};fn.source="Please enter a vaid summation name.";return fn;})(),
    "validation_combiName_100over": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The summation name cannot exceed 100 characters.Please re-enter summation name."])};fn.source="The summation name cannot exceed 100 characters.Please re-enter summation name.";return fn;})(),
    "validation_combi_edit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to add item(s) to the summation box."])};fn.source="Unable to add item(s) to the summation box.";return fn;})(),
    "combi_no_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no items to summation."])};fn.source="There are no items to summation.";return fn;})(),
    "search_no_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No summation items to search for."])};fn.source="No summation items to search for.";return fn;})(),
    "select_no_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are items that have not been selected yet."])};fn.source="There are items that have not been selected yet.";return fn;})(),
    "impossible_2over_total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A combination of two or more total values of ingredients, products and ATC cannot be analyzed."])};fn.source="A combination of two or more total values of ingredients, products and ATC cannot be analyzed.";return fn;})(),
    "impossible_combi_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The combination of types and beds cannot be analyzed."])};fn.source="The combination of types and beds cannot be analyzed.";return fn;})(),
    "impossible_combi_channel_region": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The combination of types and beds cannot be analyzed. 2"])};fn.source="The combination of types and beds cannot be analyzed. 2";return fn;})(),
    "impossible_null_1over_total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the ‘null value’ option is analyzed in combination with ingredients, products, and ATC, there may be cases where the result value is not displayed due to unnecessary analysis. \nPlease turn off ‘null value’ and analyze."])};fn.source="If the ‘null value’ option is analyzed in combination with ingredients, products, and ATC, there may be cases where the result value is not displayed due to unnecessary analysis. \nPlease turn off ‘null value’ and analyze.";return fn;})(),
    "not_exists_board_prev": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no previous posts."])};fn.source="There are no previous posts.";return fn;})(),
    "not_exists_board_next": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no next posts."])};fn.source="There are no next posts.";return fn;})(),
    "impossible_prodcombi_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The content below cannot be analyzed with a combination of Drugs ([Product]-Drug)\n - Content: Manufacturer, Distributer, ATC, Molecule, KFDA Code, DoseForm, Route"])};fn.source="The content below cannot be analyzed with a combination of Drugs ([Product]-Drug)\n - Content: Manufacturer, Distributer, ATC, Molecule, KFDA Code, DoseForm, Route";return fn;})(),
    "impossible_prodcust_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Product] 'C' items created with 'Advanced Search' on the content of [Manufacturer],[Distributer],[ATC],[Molecule],[KFDA Code],[DoseForm],[Route] can not be combined with content.\nPlease remove contents."])};fn.source="[Product] 'C' items created with 'Advanced Search' on the content of [Manufacturer],[Distributer],[ATC],[Molecule],[KFDA Code],[DoseForm],[Route] can not be combined with content.\nPlease remove contents.";return fn;})(),
    "impossible_etccombi_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contents below cannot be analyzed with Drugs ([Product]-Drug) when applying the combination\n - Content: Manufacturer, Distributer, ATC, Molecule, KFDA Code, DoseForm, Route"])};fn.source="The contents below cannot be analyzed with Drugs ([Product]-Drug) when applying the combination\n - Content: Manufacturer, Distributer, ATC, Molecule, KFDA Code, DoseForm, Route";return fn;})(),
    "warning_sum2over_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATC, Product, Molecule, Generic if you select two or more the value of some items may be displayed differently"])};fn.source="ATC, Product, Molecule, Generic if you select two or more the value of some items may be displayed differently";return fn;})(),
    "impossible_colstotal_content": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["It can't be get 'Total' of [", _interpolate(_named("content")), "] on horizontal axis due to list excess."])};fn.source="It can't be get 'Total' of [{content}] on horizontal axis due to list excess.";return fn;})(),
    "impossible_metrics_sum_total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 'Grand Total' option is not available when the measurements are located on the vertical axis. Under Options, turn off 'Grand Total' before analyzing."])};fn.source="The 'Grand Total' option is not available when the measurements are located on the vertical axis. Under Options, turn off 'Grand Total' before analyzing.";return fn;})(),
    "impossible_cross_selection": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cross-selection is not possible when '", _interpolate(_named("content")), "' groups are different."])};fn.source="Cross-selection is not possible when '{content}' groups are different.";return fn;})(),
    "select_channel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select Channel."])};fn.source="Please select Channel.";return fn;})(),
    "can_choose_medical": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only choose a medical department if the type is a higher general hospital, general hospital, or clinic."])};fn.source="You can only choose a medical department if the type is a higher general hospital, general hospital, or clinic.";return fn;})(),
    "cannot_choose_medical": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot select a detailed department if the type is hospital, clinic, health center, or other."])};fn.source="You cannot select a detailed department if the type is hospital, clinic, health center, or other.";return fn;})(),
    "impossible_basecombi_cols_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The base items and The combi items cannot be placed on the horizontal axis."])};fn.source="The base items and The combi items cannot be placed on the horizontal axis.";return fn;})(),
    "validation_basecombi_only1_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The base items and The combi items can be selected only one of following items."])};fn.source="The base items and The combi items can be selected only one of following items.";return fn;})(),
    "select_base_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The base items must be selected."])};fn.source="The base items must be selected.";return fn;})(),
    "select_combi_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The combi items must be selected."])};fn.source="The combi items must be selected.";return fn;})(),
    "validation_diagnosis_base_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The base diagnosis can be analyzed with the combi diagnosis."])};fn.source="The base diagnosis can be analyzed with the combi diagnosis.";return fn;})(),
    "validation_diagnosis_combi_base": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The combi diagnosis can be analyzed with the base diagnosis."])};fn.source="The combi diagnosis can be analyzed with the base diagnosis.";return fn;})(),
    "impossible_dosage_cols_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content other than measurements and periods cannot be placed on the horizontal axis."])};fn.source="Content other than measurements and periods cannot be placed on the horizontal axis.";return fn;})(),
    "impossible_dosage_rows_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurements, periods can only be placed on the horizontal axis."])};fn.source="Measurements, periods can only be placed on the horizontal axis.";return fn;})(),
    "validation_number": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid number."])};fn.source="Please enter a valid number.";return fn;})(),
    "validation_age": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The previous age should be smaller."])};fn.source="The previous age should be smaller.";return fn;})(),
    "notice_age_from_100over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When you want to analyze the patients above 100 and over, we don't offer detail about age./n If you set the age ", _interpolate(_named("age")), " over, the range is converted to 'Over 100' and analyzed."])};fn.source="When you want to analyze the patients above 100 and over, we don't offer detail about age./n If you set the age {age} over, the range is converted to 'Over 100' and analyzed.";return fn;})(),
    "notice_age_to_100over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When you want to analyze the patients above 100 and over, we don't offer detail about age./n If you set the age ", _interpolate(_named("age")), " under, the range is converted to 'Over 100' and analyzed."])};fn.source="When you want to analyze the patients above 100 and over, we don't offer detail about age./n If you set the age {age} under, the range is converted to 'Over 100' and analyzed.";return fn;})(),
    "validation_onequantity_same": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entry you entered cannot contain the same value."])};fn.source="The entry you entered cannot contain the same value.";return fn;})(),
    "validation_onequantity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The previous dose number must be smaller."])};fn.source="The previous dose number must be smaller.";return fn;})(),
    "notice_onequantity_from_9999over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the ", _interpolate(_named("oneQuantity")), " over, the range is converted to 'Over 9999' and analyzed"])};fn.source="When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the {oneQuantity} over, the range is converted to 'Over 9999' and analyzed";return fn;})(),
    "notice_onequantity_to_9999over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the less then ", _interpolate(_named("oneQuantity")), " , the range is converted to 'Over 0' and analyzed"])};fn.source="When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the less then {oneQuantity} , the range is converted to 'Over 0' and analyzed";return fn;})(),
    "validation_perday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The previous daily dose count must be smaller."])};fn.source="The previous daily dose count must be smaller.";return fn;})(),
    "notice_perday_from_10000over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the ", _interpolate(_named("perDay")), " over, the range is converted to 'Over 9999' and analyzed."])};fn.source="When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the {perDay} over, the range is converted to 'Over 9999' and analyzed.";return fn;})(),
    "notice_perday_to_10000over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the ", _interpolate(_named("perDay")), " under, the range is converted to 'Over 1' and analyzed."])};fn.source="When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the {perDay} under, the range is converted to 'Over 1' and analyzed.";return fn;})(),
    "validation_totalday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of prescription days before must be smaller."])};fn.source="The number of prescription days before must be smaller.";return fn;})(),
    "notice_totalday_from_9999over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the ", _interpolate(_named("totalDay")), " over, the range is converted to 'Over 9999' and analyzed."])};fn.source="When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the {totalDay} over, the range is converted to 'Over 9999' and analyzed.";return fn;})(),
    "notice_totalday_to_9999over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the ", _interpolate(_named("totalDay")), " under, the range is converted to 'Over 1' and analyzed."])};fn.source="When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the {totalDay} under, the range is converted to 'Over 1' and analyzed.";return fn;})(),
    "sob_market_select": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected product/molecule does not form the SoB market. For market configuration inquiries, please contact UBIST Sales Representative."])};fn.source="The selected product/molecule does not form the SoB market. For market configuration inquiries, please contact UBIST Sales Representative.";return fn;})(),
    "max_50": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose up to 50 pieces."])};fn.source="You can choose up to 50 pieces.";return fn;})(),
    "combination_new_tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When selecting all views, the full contents of ATC/Ingredients/Medicine in the combined prescription will be displayed, and only ATC/Ingredients/Medicine applicable to the combined range will be displayed when canceled."])};fn.source="When selecting all views, the full contents of ATC/Ingredients/Medicine in the combined prescription will be displayed, and only ATC/Ingredients/Medicine applicable to the combined range will be displayed when canceled.";return fn;})(),
    "only_quarterly_monthly_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When analyzing only the combined use range, only quarterly / monthly period analysis is possible."])};fn.source="When analyzing only the combined use range, only quarterly / monthly period analysis is possible.";return fn;})(),
    "validation_week_end": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a period after the start week."])};fn.source="Please select a period after the start week.";return fn;})(),
    "validation_week_start": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a period before the last week."])};fn.source="Please select a period before the last week.";return fn;})(),
    "select_week_end": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the last week."])};fn.source="Please select the last week.";return fn;})(),
    "select_week_start": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a start week."])};fn.source="Please select a start week.";return fn;})(),
    "favorites_folder_delete": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you delete a folder, the favorites that it contains are also deleted.\nAre you sure you want to delete the '", _interpolate(_named("name")), "' folder?"])};fn.source="If you delete a folder, the favorites that it contains are also deleted.\nAre you sure you want to delete the '{name}' folder?";return fn;})(),
    "favorites_delete": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete '", _interpolate(_named("name")), "'"])};fn.source="Are you sure you want to delete '{name}'";return fn;})(),
    "favorites_folder_with_the_same_name_already_exists": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A favorites folder with the same name already exists"])};fn.source="A favorites folder with the same name already exists";return fn;})(),
    "favorites_with_the_same_name_already_exists": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A favorite with the same name already exists. Are you sure you want to overwrite it?"])};fn.source="A favorite with the same name already exists. Are you sure you want to overwrite it?";return fn;})(),
    "favorites_unable_to_move": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't move to App Favorites folder."])};fn.source="Can't move to App Favorites folder.";return fn;})(),
    "favorites_cannot_add_folder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't add folder."])};fn.source="Can't add folder.";return fn;})(),
    "favorites_cannot_add_favorite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't add favorites."])};fn.source="Can't add favorites.";return fn;})(),
    "input_favorite_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your favorite name."])};fn.source="Please enter your favorite name.";return fn;})(),
    "input_favorite_cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you close the current window without saving, no favorites are added.\nAre you sure you want to close it?"])};fn.source="If you close the current window without saving, no favorites are added.\nAre you sure you want to close it?";return fn;})(),
    "input_folder_cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you close the current window without saving, no folder are added.\nAre you sure you want to close it?"])};fn.source="If you close the current window without saving, no folder are added.\nAre you sure you want to close it?";return fn;})(),
    "validation_dosage_contents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The [Product], [ATC], [Component] content is optional and must be placed on the vertical axis. Please select at least 1 content from these."])};fn.source="The [Product], [ATC], [Component] content is optional and must be placed on the vertical axis. Please select at least 1 content from these.";return fn;})(),
    "validation_dosage_contents2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The [one dose], [dose per day], [total number of prescriptions] content is optional and must be placed on the vertical axis. Please select at least 1 content from these."])};fn.source="The [one dose], [dose per day], [total number of prescriptions] content is optional and must be placed on the vertical axis. Please select at least 1 content from these.";return fn;})(),
    "validation_dosage_order": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contents of [one dose], [dose per day], [total number of prescriptions] must be at the bottom of the vertical condition. Please change the order of the contents located on the vertical axis."])};fn.source="The contents of [one dose], [dose per day], [total number of prescriptions] must be at the bottom of the vertical condition. Please change the order of the contents located on the vertical axis.";return fn;})(),
    "validation_dosage_order2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Product] content is lower than [ATC] and [Ingredients] and cannot enter higher conditions when combined with [ATC] and [Ingredients].\n[Product] Please change the order of the contents so that the contents are below [ATC] and [Ingredient] contents."])};fn.source="[Product] content is lower than [ATC] and [Ingredients] and cannot enter higher conditions when combined with [ATC] and [Ingredients].\n[Product] Please change the order of the contents so that the contents are below [ATC] and [Ingredient] contents.";return fn;})(),
    "validation_dosage_averagemetrics": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you choose [Avg_Qty] or [Avg_Day] from measurement, you cannot place the summated contents and type the text box in the Direct Entry box. Please exclude the summated conditions or the direct entry conditions for analysis."])};fn.source="If you choose [Avg_Qty] or [Avg_Day] from measurement, you cannot place the summated contents and type the text box in the Direct Entry box. Please exclude the summated conditions or the direct entry conditions for analysis.";return fn;})(),
    "validation_dosage_period_averagemetrics": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you choose [Avg_Qty] or [Avg_Day] from measurement, cross-selection of 'Period' groups is not possible."])};fn.source="If you choose [Avg_Qty] or [Avg_Day] from measurement, cross-selection of 'Period' groups is not possible.";return fn;})(),
    "please_custom_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select items to customize."])};fn.source="Please select items to customize.";return fn;})(),
    "optionmodal_save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving changes the result value options. Do you want to save it?"])};fn.source="Saving changes the result value options. Do you want to save it?";return fn;})(),
    "optionmodal_no_save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you close the current window without saving, the option does not apply. Do you really want to close it?"])};fn.source="If you close the current window without saving, the option does not apply. Do you really want to close it?";return fn;})(),
    "analysis_mat_one": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only one MAT analysis is possible."])};fn.source="Only one MAT analysis is possible.";return fn;})(),
    "no_drug_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No drug information."])};fn.source="No drug information.";return fn;})(),
    "main_account_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account information"])};fn.source="My account information";return fn;})(),
    "main_contract_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My contract period"])};fn.source="My contract period";return fn;})(),
    "main_contract_periodM_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["after ", _interpolate(_named("month")), " months"])};fn.source="after {month} months";return fn;})(),
    "main_contract_periodYM_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["after ", _interpolate(_named("year")), " years and ", _interpolate(_named("month")), " months"])};fn.source="after {year} years and {month} months";return fn;})(),
    "main_contract_periodY_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["after ", _interpolate(_named("year")), " years"])};fn.source="after {year} years";return fn;})(),
    "main_contract_contents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My contract contents"])};fn.source="My contract contents";return fn;})(),
    "main_assistance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need any assistance?"])};fn.source="Do you need any assistance?";return fn;})(),
    "main_search_archives": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a title or content\nin the archives."])};fn.source="Search for a title or content\nin the archives.";return fn;})(),
    "main_contact_representative": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact a representative"])};fn.source="Contact a representative";return fn;})(),
    "main_contact_representative2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact the person in charge."])};fn.source="Contact the person in charge.";return fn;})(),
    "validation_notinclude_column": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Horizonal axis cannot be selected for 'top, bottom' in [", _interpolate(_named("content")), "].\nPlease analyze by moving to the vertical axis."])};fn.source="Horizonal axis cannot be selected for 'top, bottom' in [{content}].\nPlease analyze by moving to the vertical axis.";return fn;})(),
    "validation_topbottom_measurement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you choose multiple measurements and arrange them on the vertical axis,\nyou cannot place content selected as 'top, bottom' together.\nPlease either select only one measurement or exclude the 'top, bottom' conditions for anlaysis."])};fn.source="If you choose multiple measurements and arrange them on the vertical axis,\nyou cannot place content selected as 'top, bottom' together.\nPlease either select only one measurement or exclude the 'top, bottom' conditions for anlaysis.";return fn;})(),
    "validation_topbottom_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cannot place the summated [", _interpolate(_named("content")), "]\nabove ", _interpolate(_named("topbottom1")), "-selected [", _interpolate(_named("topbottom2")), "].\nPlease analyze by changing the position."])};fn.source="Cannot place the summated [{content}]\nabove {topbottom1}-selected [{topbottom2}].\nPlease analyze by changing the position.";return fn;})(),
    "validation_topbottom_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cannot place the selected different levels of [", _interpolate(_named("content")), "]\nabove ", _interpolate(_named("topbottom1")), "-selected [", _interpolate(_named("topbottom2")), "].\nPlease reset the conditions for analysis."])};fn.source="Cannot place the selected different levels of [{content}]\nabove {topbottom1}-selected [{topbottom2}].\nPlease reset the conditions for analysis.";return fn;})(),
    "validation_additional_measurement_option_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you choose [", _interpolate(_named("measurement")), "] from measurement option,\nyou cannot place the summated [", _interpolate(_named("content")), "].\nPlease exclude the summated conditions for analysis."])};fn.source="If you choose [{measurement}] from measurement option,\nyou cannot place the summated [{content}].\nPlease exclude the summated conditions for analysis.";return fn;})(),
    "validation_additional_measurement_option_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you choose [", _interpolate(_named("measurement")), "] from measurement option,\nyou cannot place together selected different levels of [", _interpolate(_named("content")), "].\nPlease reset the conditions for analysis."])};fn.source="If you choose [{measurement}] from measurement option,\nyou cannot place together selected different levels of [{content}].\nPlease reset the conditions for analysis.";return fn;})(),
    "validation_additional_measurement_option(share)_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you choose [", _interpolate(_named("measurement")), "] from measurement option,\nthe summated [", _interpolate(_named("content")), "] placed together is only possible at the last position of the other axis.\nPlease analyze by changing the position."])};fn.source="If you choose [{measurement}] from measurement option,\nthe summated [{content}] placed together is only possible at the last position of the other axis.\nPlease analyze by changing the position.";return fn;})(),
    "validation_additional_measurement_option(share)_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you choose [", _interpolate(_named("measurement")), "] from measurement option,\nthe selected different levels of [", _interpolate(_named("content")), "] placed together is only possible at the last position of the other axis.\nPlease analyze by changing the position."])};fn.source="If you choose [{measurement}] from measurement option,\nthe selected different levels of [{content}] placed together is only possible at the last position of the other axis.\nPlease analyze by changing the position.";return fn;})(),
    "sum_topbottom_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When summation [", _interpolate(_named("content")), "]\nlocated above ", _interpolate(_named("topbottom1")), "-selected [", _interpolate(_named("topbottom2")), "].\nautomatically converted to Excel file.\nDo you want to continue?"])};fn.source="When summation [{content}]\nlocated above {topbottom1}-selected [{topbottom2}].\nautomatically converted to Excel file.\nDo you want to continue?";return fn;})(),
    "sum_topbottom_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When selecting different levels of [", _interpolate(_named("content")), "]\nlocated above ", _interpolate(_named("topbottom1")), "-selected [", _interpolate(_named("topbottom2")), "].\nautomatically converted to Excel file.\nDo you want to continue?."])};fn.source="When selecting different levels of [{content}]\nlocated above {topbottom1}-selected [{topbottom2}].\nautomatically converted to Excel file.\nDo you want to continue?.";return fn;})(),
    "sum_additional_measurement_option_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you choose [", _interpolate(_named("measurement")), "] from measurement option,\nWhen summation [", _interpolate(_named("content")), "].\nautomatically converted to Excel file.\nDo you want to continue?"])};fn.source="If you choose [{measurement}] from measurement option,\nWhen summation [{content}].\nautomatically converted to Excel file.\nDo you want to continue?";return fn;})(),
    "sum_additional_measurement_option_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you choose [", _interpolate(_named("measurement")), "] from measurement option,\nWhen together selecting different levels of [", _interpolate(_named("content")), "].\nautomatically converted to Excel file.\nDo you want to continue?"])};fn.source="If you choose [{measurement}] from measurement option,\nWhen together selecting different levels of [{content}].\nautomatically converted to Excel file.\nDo you want to continue?";return fn;})(),
    "sum_additional_measurement_option(share)_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you choose [", _interpolate(_named("measurement")), "] from measurement option,\nWhen summation [", _interpolate(_named("content")), "]\nautomatically converted to Excel file.\nDo you want to continue?"])};fn.source="If you choose [{measurement}] from measurement option,\nWhen summation [{content}]\nautomatically converted to Excel file.\nDo you want to continue?";return fn;})(),
    "sum_additional_measurement_option(share)_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you choose [", _interpolate(_named("measurement")), "] from measurement option,\nWhen selecting different levels of [", _interpolate(_named("content")), "]\nautomatically converted to Excel file.\nDo you want to continue?"])};fn.source="If you choose [{measurement}] from measurement option,\nWhen selecting different levels of [{content}]\nautomatically converted to Excel file.\nDo you want to continue?";return fn;})(),
    "analyze_timeout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The analysze time has expired.\nPlease modify the analysis conditions and try again."])};fn.source="The analysze time has expired.\nPlease modify the analysis conditions and try again.";return fn;})(),
    "analyze_error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The analysze time expired.\nPlease modify the analysis conditions and try again."])};fn.source="The analysze time expired.\nPlease modify the analysis conditions and try again.";return fn;})(),
    "analyze_report_error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The analysze time expired.\nPlease modify the analysis conditions and try again."])};fn.source="The analysze time expired.\nPlease modify the analysis conditions and try again.";return fn;})(),
    "unsubscribed_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact your administrator for unuse contents."])};fn.source="Please contact your administrator for unuse contents.";return fn;})(),
    "unsubscribed_service": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is unsubscribed contents."])};fn.source="This is unsubscribed contents.";return fn;})(),
    "IP_block": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP block"])};fn.source="IP block";return fn;})(),
    "need_register_ubist_account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration for a corporate account is required."])};fn.source="Registration for a corporate account is required.";return fn;})(),
    "validation_hospital_specialty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to analyze [Semi Hospital] and Specialty together, please choose [others] from Specialty. (If not, the [Semi Hospital] data will not be included in the analysis report.)"])};fn.source="If you want to analyze [Semi Hospital] and Specialty together, please choose [others] from Specialty. (If not, the [Semi Hospital] data will not be included in the analysis report.)";return fn;})(),
    "validation_clinic_subSpecialty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to analyze [Clinic] and Subspecialty together, please choose [unclassified] from Subspecialty. (If not, the [Clinic] data will not be included in the analysis report.)"])};fn.source="If you want to analyze [Clinic] and Subspecialty together, please choose [unclassified] from Subspecialty. (If not, the [Clinic] data will not be included in the analysis report.)";return fn;})(),
    "validation_nonreimbursement_projqty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty_P Measurement Guide\n- Qty_P is [One Dose Qty × Dose Per Day × Total Day].\n- Non-reimbursement durgs has different drug counting units and prescription standards for each prescription, so you should pay attention to the interpretation of the Qty_P.\n- Qty_P is temporarily provided as a supplementary reference to help interpret some government-supported drugs and will be discontinued within the year, so please use the Standard_Qty for other products.\n"])};fn.source="Qty_P Measurement Guide\n- Qty_P is [One Dose Qty × Dose Per Day × Total Day].\n- Non-reimbursement durgs has different drug counting units and prescription standards for each prescription, so you should pay attention to the interpretation of the Qty_P.\n- Qty_P is temporarily provided as a supplementary reference to help interpret some government-supported drugs and will be discontinued within the year, so please use the Standard_Qty for other products.\n";return fn;})(),
    "select_all": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot select the entire and other items together."])};fn.source="You cannot select the entire and other items together.";return fn;})(),
    "validation_cl_diagnosis_patient_visit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'New' in Patient Type and 'ReturningPatients' in Visit Type cannot be cross-selected."])};fn.source="'New' in Patient Type and 'ReturningPatients' in Visit Type cannot be cross-selected.";return fn;})(),
    "validation_old_UP_Non": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UP Non-reimbursement data can be analyzed from 2010 onwards.\nPlease select a period again."])};fn.source="UP Non-reimbursement data can be analyzed from 2010 onwards.\nPlease select a period again.";return fn;})(),
    "validation_old_KFDA": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KFDA Code content can be analyzed from 2012 onwards.\nPlease select a period again."])};fn.source="KFDA Code content can be analyzed from 2012 onwards.\nPlease select a period again.";return fn;})(),
    "validation_old_generic": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic content can be analyzed from 2015 onwards.\nPlease select a period again."])};fn.source="Generic content can be analyzed from 2015 onwards.\nPlease select a period again.";return fn;})(),
    "validation_old_reimbursement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimbursement content can be analyzed from 2018 onwards.\nPlease select a period again."])};fn.source="Reimbursement content can be analyzed from 2018 onwards.\nPlease select a period again.";return fn;})(),
    "validation_old_patientType": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PatientType content can be analyzed from 2010 onwards.\nPlease select a period again."])};fn.source="PatientType content can be analyzed from 2010 onwards.\nPlease select a period again.";return fn;})(),
    "validation_old_standardMetrics": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard_Qty_P measurement can be analyzed from 2015 onwards.\nPlease select a period again."])};fn.source="Standard_Qty_P measurement can be analyzed from 2015 onwards.\nPlease select a period again.";return fn;})(),
    "validation_old_dataAlgorithmPanel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are differences in the algorithm and number of panels for each period below.\nPlease refer to this when analyzing data.\n- 2008~2014\n- 2015~2016\n- 2017~2023"])};fn.source="There are differences in the algorithm and number of panels for each period below.\nPlease refer to this when analyzing data.\n- 2008~2014\n- 2015~2016\n- 2017~2023";return fn;})(),
    "validation_old_dataAlgorithmPanel_non": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are differences in the algorithm and number of panels for each period below.\nPlease refer to this when analyzing data.\n- 2010~2014\n- 2015~2016\n- 2017~2023"])};fn.source="There are differences in the algorithm and number of panels for each period below.\nPlease refer to this when analyzing data.\n- 2010~2014\n- 2015~2016\n- 2017~2023";return fn;})()
  },
  "error": {
    "401": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 조건으로는 데이터가 나오지 않습니다.\n다른 조건으로 다시 조회해 주세요."])};fn.source="해당 조건으로는 데이터가 나오지 않습니다.\n다른 조건으로 다시 조회해 주세요.";return fn;})(),
    "402": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ATC] including selected product cannot be removed for analysis.\nPlease add [ATC]."])};fn.source="[ATC] including selected product cannot be removed for analysis.\nPlease add [ATC].";return fn;})(),
    "901": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of rows(1,048,576) or columns(18,278) exceeded while creating excel.\nPlease reduce range of analysis."])};fn.source="Maximum number of rows(1,048,576) or columns(18,278) exceeded while creating excel.\nPlease reduce range of analysis.";return fn;})(),
    "902": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data."])};fn.source="No data.";return fn;})(),
    "903": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The analysze time has expired.\nPlease modify the analysis conditions and try again."])};fn.source="The analysze time has expired.\nPlease modify the analysis conditions and try again.";return fn;})(),
    "909": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The analysze time expired.\nPlease modify the analysis conditions and try again."])};fn.source="The analysze time expired.\nPlease modify the analysis conditions and try again.";return fn;})()
  },
  "footer": {
    "company": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UBcare Co., Ltd"])};fn.source="UBcare Co., Ltd";return fn;})(),
    "bizInfo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Information"])};fn.source="Business Information";return fn;})(),
    "terms": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])};fn.source="Terms of Service";return fn;})(),
    "privacy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])};fn.source="Privacy Policy";return fn;})(),
    "business_number": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business registration number"])};fn.source="Business registration number";return fn;})(),
    "CEO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative Name"])};fn.source="Representative Name";return fn;})(),
    "phone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])};fn.source="Phone number";return fn;})(),
    "address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])};fn.source="Address";return fn;})(),
    "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lee Sang-kyung"])};fn.source="Lee Sang-kyung";return fn;})(),
    "address_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["108, Yeouido-daero, UBcare, 29th floor, 30th floor, and 31st floor (Yeouido-dong, Park One Tower 2), Yeongdeungpo-gu, Seoul, 07335, Republic of Korea"])};fn.source="108, Yeouido-daero, UBcare, 29th floor, 30th floor, and 31st floor (Yeouido-dong, Park One Tower 2), Yeongdeungpo-gu, Seoul, 07335, Republic of Korea";return fn;})()
  }
}