<template>
    <iframe ref="kimsIframe" name="kims_iframe" style="display:block; width:calc(100vw - 15px); height:100vh;"></iframe>
</template>
  
<script>
import {  ref, onMounted, defineComponent } from 'vue';

//KIMS 스크립트 로드
const loadKimsScript = () =>{
    return new Promise((r) => {
        const script = document.createElement('script');
        script.src = 'https://edisweb.kims.co.kr/Script/KIMSeDIS.min.js';
        script.onload = r;
        document.body.appendChild(script);
    });
}

export default defineComponent({
name: "DrugInformation",
props: ['drugCode'],
setup(props) {
    const kimsIframe = ref(null); 
    const EncToken = ref('');     // EncToken을 저장할 변수
    // const route = useRoute();     // 라우터에서 DrugCode 파라미터를 가져오기 위해 사용

    // EncToken을 생성하는 함수
    function generateEncToken() {
        const now = new Date();
        const yyyy = now.getFullYear();
        const MM = String(now.getMonth() + 1).padStart(2, '0');
        const dd = String(now.getDate()).padStart(2, '0');
        const HH = String(now.getHours()).padStart(2, '0');
        const mm = String(now.getMinutes()).padStart(2, '0');
        const ss = String(now.getSeconds()).padStart(2, '0');
        const timestamp = `${yyyy}${MM}${dd}${HH}${mm}${ss}`;

        const str_token = `${timestamp}-DIUBST`;

        // str_token을 바이트 배열로 변환하고, Base64로 인코딩하여 반환
        const bytes = new TextEncoder().encode(str_token);
        return btoa(String.fromCharCode(...bytes));
    }

    // 페이지 로드 시
    onMounted(async () => {
        await loadKimsScript(); // KIMS 스크립트 로드
        EncToken.value = generateEncToken(); // EncToken 값 생성

        const meta = document.createElement('meta');
        meta.name = 'Base-Code';
        meta.content = EncToken.value;
        document.head.appendChild(meta);

        const code = props.drugCode;

        // k_s.setURL() 메서드로 iframe의 URL 설정
        if (kimsIframe.value && window.k_s) {
            window.k_s.setURL(kimsIframe.value.name, 'DrugInfo', code);
        }
    });

    return { kimsIframe };
}
});
</script>
  