<template>
  <div class="pivot-contents">
    <!-- 20230704 삭제 
    <span class="contents_title">Contents</span>
    -->

    <!-- 20230704 추가 { -->
    <div class="main-content-title old-title">
      <h2>
        {{ $t("title.Old_Data") }}
      </h2>
      <!-- <select :value="serviceTitle" @change="(e) => {
        switch(e.target.value) {
          case $t('title.Old_D1_Sales'):  router.push('/Old_D1_Sales'); break;
          case $t('title.Old_D1_Non'):    router.push('/Old_D1_Non');   break;
          case $t('title.Old_UP_Sales'):  router.push('/Old_UP_Sales'); break;
          case $t('title.Old_UP_Non'):    router.push('/Old_UP_Non');   break;
          default: break;
        }
      }">
        <option :value="$t('title.Old_D1_Sales')">{{ $t("title.Old_D1_Sales") }}</option>
        <option :value="$t('title.Old_D1_Non')">{{ $t("title.Old_D1_Non") }}</option>
        <option :value="$t('title.Old_UP_Sales')">{{ $t("title.Old_UP_Sales") }}</option>
        <option :value="$t('title.Old_UP_Non')">{{ $t("title.Old_UP_Non") }}</option>
      </select> -->
      <div class="select" id="select">
        <div class="value" :class="{ 'open': isSelectOpen }" @click="(e) => isSelectOpen = !isSelectOpen">
          <span>{{ serviceTitle }}</span>
          <span class="material-symbols-outlined">{{ isSelectOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
        </div>
        <div class="options" :class="{ 'open': isSelectOpen }">
          <div class="option" :value="$t('title.Old_D1_Sales')" @click="router.push('/Old_D1_Sales');"> <span v-for="(t, i) in ($t('title.Old_D1_Sales')).split(' ')" :class="{ 'bold': i === 0 }">{{ t }}&nbsp</span></div>
          <div class="option" :value="$t('title.Old_D1_Non')"   @click="router.push('/Old_D1_Non');">   <span v-for="(t, i) in ($t('title.Old_D1_Non'))  .split(' ')" :class="{ 'bold': i === 0 }">{{ t }}&nbsp</span></div>
          <div class="option" :value="$t('title.Old_UP_Sales')" @click="router.push('/Old_UP_Sales');"> <span v-for="(t, i) in ($t('title.Old_UP_Sales')).split(' ')" :class="{ 'bold': i === 0 }">{{ t }}&nbsp</span></div>
          <div class="option" :value="$t('title.Old_UP_Non')"   @click="router.push('/Old_UP_Non');">   <span v-for="(t, i) in ($t('title.Old_UP_Non'))  .split(' ')" :class="{ 'bold': i === 0 }">{{ t }}&nbsp</span></div>
        </div>
      </div>
      <div class="select_background" :class="{ 'open': isSelectOpen }" @click="(e) => isSelectOpen = false"></div>
      <!-- <button class="btn" :class="{ active: serviceTitle === $t('title.Old_D1_Sales') }" @click="router.push('/Old_D1_Sales')">
        {{ $t("title.Old_D1_Sales") }}
      </button>
      <button class="btn" :class="{ active: serviceTitle === $t('title.Old_D1_Non') }" @click="router.push('/Old_D1_Non')">
        {{ $t("title.Old_D1_Non") }}
      </button>
      <button class="btn" :class="{ active: serviceTitle === $t('title.Old_UP_Sales') }" @click="router.push('/Old_UP_Sales')">
        {{ $t("title.Old_UP_Sales") }}
      </button>
      <button class="btn" :class="{ active: serviceTitle === $t('title.Old_UP_Non') }" @click="router.push('/Old_UP_Non')">
        {{ $t("title.Old_UP_Non") }}
      </button> -->
    </div>
    <!-- } 20230704 추가 -->

    <div class="contents">
      <button
        v-for="(content, i) in showContents"
        draggable="true"
        :disabled="pivotList.has(content.code)"
        @dragstart="
          ondragstart(
            content.code,
            content.name,
            content.englishName,
            content.type,
            content.subContents
          )
        "
        v-bind:key="(content, i)"
        :title="
          this.$i18n.locale == 'en'
            ? content.englishName || content.name + '(en)'
            : content.name
        "
      >
        {{
          this.$i18n.locale == "en"
            ? content.englishName || content.name + "(en)"
            : content.name
        }}
      </button>
    </div>

    <!-- 20230704 Pivot.vue 에서 가져옴 { -->
    <div class="report">
      <button class="btn" @click="onReload()">
        <span class="material-symbols-outlined">restart_alt</span>
      </button>
      <div class="btn-dropdown-group">
        <button @click="onReport('report')">{{ $t("button.reporting") }}</button>
        <div v-if="!customer.impossibleExcel" class="btn-dropdown" @focusout="focusout">
          <button @click="dropIt">
            <span class="material-symbols-outlined">
              {{ isDropped ? "expand_less" : "expand_more" }}
            </span>
          </button>
          <div
            class="btn-dropdown-content"
            v-if="isDropped"
            @mouseover="handleMouseOver"
            @mouseout="handleMouseOut"
          >
            <a href="#" @click="exportExcel()">{{
              $t("button.excel_export")
            }}</a>
            <a href="#" @click="exportEmail()">{{ $t("button.email_export") }}</a>
          </div>
        </div>
      </div>
    </div>

    <!-- } 20230704 Pivot.vue 에서 가져옴 -->
    <!-- COMBINATION II 일 경우 만 문구 표시 -->
    <p
      v-if="
        parentMenuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE
      "
      style="font-size: 14px; padding: 20px 0 0 0; color: red"
    >
      {{ $t("label.notice_combination_therapy2") }}
    </p>
  </div>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :afterEvent="alertDialog.afterEvent"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import constants from "@/data/constants.js";
import { defineComponent, ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useCommonStore } from "@/store/common";
import { useContentsStore } from "@/store/contents";
import router from "@/router";

export default defineComponent({
  name: "PivotContents",
  components: {
    AlertDialog,
  },
  props: ["parentMenu", "parentMenuCode", "serviceTitle"],
  setup(props) {
    const router = useRouter();
    //AlertDialog
    const isAlertDialogOpen = ref(false);

    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
      afterEvent: "",
    });

    const closeAlertDialog = (afterEvent) => {
      isAlertDialogOpen.value = false;
      if (afterEvent === "reporting") {
        contentsStore.onClickReport(props.parentMenuCode, "report");
      }
    };

    const { t } = useI18n();
    const commonStore = useCommonStore();
    const contentsStore = useContentsStore();

    const {
      loading,
      customer,
    } = storeToRefs(commonStore);
    const {
      contents,
      pivotList,
      columns,
      rows,
      chooseOptions,
      iframe,
      exportUrl,
      exportType,
      selectOptions,
      combineItems,
      itemEditModeYn,
      reportId,
    } = storeToRefs(contentsStore);

    const showContents = ref([]);
    const isSelectOpen = ref(false);

    onMounted(async () => {
      loading.value = true;

      await contentsStore.getContents(props.parentMenuCode);
      await contentsStore.getImpossibleMergeContents(props.parentMenuCode)
      await contentsStore.onInitDropColumn(props.parentMenuCode);
    });

    watch(contents, () => {
      if (props.parentMenuCode === constants.CONSTANT_MENU_DOSAGE_CODE) {
        showContents.value = contents.value.filter(e => {
          return !(constants.CONSTANT_MANUFACTURER_CODE.some(x => x === e.code) || constants.CONSTANT_DISTRIBUTER_CODE.some(x => x === e.code))
        })
      } else {
        showContents.value = contents.value
      }

      loading.value = false;
    });

    // watch([lang], async () => {
    //   await ubistApi.postLogin2();

    // });

    const ondragstart = (code, name, englishName, type, subContents) => {
      contentsStore.onDragStartContent(
        code,
        name,
        englishName,
        type,
        subContents
      );
    };

    const focusout = () => {
      if (!isBtnDiv.value) {
        isDropped.value = false;
      }
    };

    const isBtnDiv = ref(false);

    const handleMouseOver = () => {
      isBtnDiv.value = true;
    };

    const handleMouseOut = () => {
      isBtnDiv.value = false;
    };

    //분석하기 드롭다운
    const isDropped = ref(false);

    //분석하기 드롭다운
    const dropIt = () => {
      isDropped.value = !isDropped.value;
    };

    // 분석하기 및 초기화 버튼 변경 후 추가
    //초기화
    const combine = ref(combineItems);

    const onReload = () => {
      contentsStore.onInitDropColumn(props.parentMenuCode);

      //합치기영역 초기화
      combine.value = {
        name: "",
        englishName: "",
        list: [],
      };

      itemEditModeYn.value = false;
    };

    const runReport = async (type) => {
      // 리포팅 실행
      const resExportUrl = await contentsStore.onClickReport(
        props.parentMenuCode,
        type
      );

      if (!resExportUrl) {
        commonStore.openAlertDialog({
          message: "분석 할 데이터가 없습니다.",
        })

        return;
      }

      if (type === "excel") {
        await contentsStore.onReportExcel(props.parentMenuCode, commonStore.openAlertDialog);
      }
      if (type === "excelSum") {
        await contentsStore.onReportExcelBySum(props.parentMenuCode, commonStore.openAlertDialog);
      }
      if (type === "email") {
        await contentsStore.onReportEmail(props.parentMenuCode, commonStore.openAlertDialog);
      }
    }

    //리포트 결과보기
    const onReport = async (type) => {
      if (
        rows.value.filter(e => { return !contents.value.some(x => x.code === e.code) }).length > 0 ||
        columns.value.filter(e => { return !contents.value.some(x => x.code === e.code) }).length > 0
      ) {
        alertDialog.value = {};
        alertDialog.value.message = t("message.unsubscribed_content")

        isAlertDialogOpen.value = true;

        return false
      }
      
      const validation = await contentsStore.onClickReportValidation(props.parentMenuCode, commonStore.openAlertDialog, runReport, type);

      if (!validation) return false;

      // 리포팅 실행
      runReport(type);

      return true;
    };

    //Excel
    const exportExcel = () => {
      onReport("excel");
    };
    const exportEmail = () => {
      onReport("email");
    }

    return {
      router,

      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,

      constants,
      showContents,
      contents,
      ondragstart,
      pivotList,
      pMenuCode: props.parentMenuCode,
      customer,
      
      dropIt,
      isDropped,
      focusout,
      isBtnDiv,
      handleMouseOver,
      handleMouseOut,

      // 분석하기 및 초기화 버튼 변경 후 추가
      onReload,
      onReport,
      exportExcel,
      exportEmail,
      
      isSelectOpen,
    };
  },
});
</script>

<style scoped>
/* 20230704 수정 { */
.pivot-contents {
  display: flex;
  flex-direction: column;
  position: relative;
  /* width: 221px; */
  width: 252px;
  min-width: 252px;
  padding: 0;
  padding-right: 10px;
  /* border: var(--border-line);
  border-radius: var(--border-radius); */
}

.pivot-contents::before {
  position: absolute;
  right: 0;
  top: -20px;
  content: "";
  width: 1px;
  height: calc(100% + 40px);
  background-color: #ddd;
}

.contents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  /* padding: 10px 0; */ /* 20230704 수정 */
  overflow-y: overlay;
}

/* 20230704 삭제
.contents_title {
  font-size: 18px;
  font-weight: 700;
  padding: 8px 0 8px 4px;
}
*/

.contents button {
  position: relative;
  /* width: 97px; */
  width: 113px;
  height: 34px;
  text-align: left;
  padding-left: 26px;
  padding-right: 13px;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  background-color: #fff;
  border: var(--border-line);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s;
}

.contents button::before {
  position: absolute;
  left: 6px;
  top: 50%;
  content: "\e945";
  font-family: "Material Symbols Outlined";
  font-weight: 300;
  color: var(--primary-color);
  transform: translateY(-50%);
  transition: all 0.3s;
}

.contents button.active {
  border-color: #808080;
}

.contents button:hover {
  background-color: #edf1f9;
}
.contents button:active {
  padding-left: 30px;
  background-color: #fff;
  border-color: var(--primary-color);
  box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.23);
  z-index: 1;
}

.contents button:active::before {
  left: 10px;
  color: var(--primary-color);
}

.contents button:disabled {
  cursor: not-allowed;
  pointer-events: none;
  border-color: #ebebeb;
  background-color: #f4f4f4;
  color: rgba(16, 16, 16, 0.3);
}

.contents button:disabled::before {
  color: rgba(16, 16, 16, 0.3);
}

.report {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  height: 48px;
  padding-right: 10px;
}
.report .btn {
  height: 100%;
  padding: 0 12px;
}

.report .btn-dropdown-group {
  margin-left: 6px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}

.report .btn-dropdown-group button {
  color: #fff;
}

.report .btn-dropdown {
  border-color: #1042be;
}
.report .btn-dropdown-content {
  min-width: auto;
}

/* } 20230704 수정 */

.old-title {
  height: 80px;
  margin-top: 12px;
  margin-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;

  span:not(.material-symbols-outlined) {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 400;
    line-height: 28px;
    white-space: nowrap;
  }
  .material-symbols-outlined {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }

  .select {
    z-index: 99;

    margin-top: 24px;
    cursor: pointer;

    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;

    .value {
      display: flex;
      justify-content: space-between;

      width: 232px;
      height: 32px;
      padding: 0px 12px;

      background: #FFFFFF;
      border: 2px solid #174FDC;
      border-radius: 6px;

      &:hover {
        background: #EDF1F9;
      }
      &.open {
        background: #EDF1F9;
      }
    }
    .options {
      display: none;
      position: absolute;
      margin-top: 4px;
      
      background: #FFFFFF;
      border-radius: 8px;
      box-shadow: 4px 4px 16px 0px #6A6A6A29;

      &.open {
        display: block;
      }

      .option {
        width: 232px;
        height: 32px;
        padding: 0px 12px;

        &:nth-child(1) {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &:nth-child(4) {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        .bold {
          font-weight: 700;
        }

        &:hover {
          background: #EDF1F9;
          color: #174FDC;
          span  {
            font-weight: 700;
          }
        }
      }
      
      .option + .option {
        border-top: 1px solid #F9FAFB;
      } 
    }
  }
  .select_background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0;
    z-index: 98;
    cursor: default;

    &.open {
      display: block;
    }
  }
}


/* .old-title .btn.active {
  cursor: auto;
  color: #fff;
  background-color: var(--primary-color);
} */
</style>
