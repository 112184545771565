<template>
  <div class="modal" :class="{ 'is-open': isProductModalOpen }">
    <div class="modal-overlay" @click="onCancel"></div>
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{ $t("title.popup_prodDetail") }}</h2>
      </div>
      <div class="modal-body">
        <div class="dropdown-container-menu">
          <div class="search-bar">
            <select
              id="detailSelect1"
              :value="searchContent"
              @change="(e) => (searchContent = e.target.value)"
              style="border: var(--border-line); border-radius: 4px"
            >
              <template v-for="(content, i) in showDropBox">
                <option
                  v-if="
                    constants.CONSTANT_MANUFACTURER_CODE.includes(content.code) ||
                    constants.CONSTANT_DISTRIBUTER_CODE.includes(content.code) ||
                    [constants.CONSTANT_ATC_CODE, constants.CONSTANT_OLD_ATC_CODE].includes(content.code) ||
                    constants.CONSTANT_MOLECULE_CODE.includes(content.code) ||
                    constants.CONSTANT_PRODUCT_CODE.includes(content.code)
                  "
                  :key="i"
                  :value="content.code + ':' + content.type"
                  :selected="constants.CONSTANT_PRODUCT_CODE.includes(content.code)"
                >
                  {{
                    $i18n.locale === "en"
                      ? content.englishName || content.name + "(en)"
                      : content.name
                  }}
                </option>
              </template>
            </select>

            <div
              style="
                display: flex;
                align-items: center;
                flex: 1 1 0;
                position: relative;
              "
            >
              <input
                type="text"
                :placeholder="$t('label.search_word')"
                v-model="searchKeyword"
                @keydown.enter="fnSearch(searchContent)"
                style="width: 100%; border-radius: 4px"
              />
              <button
                style="position: absolute; right: 0"
                @click="clearSearchKeyword"
              >
                <span
                  class="material-symbols-outlined"
                  style="
                    font-size: 18px;
                    color: #b7b7b7;
                    vertical-align: middle;
                  "
                >
                  close
                </span>
              </button>
            </div>
            <button
              class="btn"
              style="
                background-color: #8a8a8a;
                border-color: #8a8a8a;
                color: #fff;
                border-radius: 4px;
              "
              @click="fnSearch(searchContent)"
            >
              {{ $t("button.search") }}
            </button>
            <button
              class="btn reset-button"
              style="border-radius: 4px"
              @click="fnSearchReset(searchContent)"
            >
              {{ $t("button.reset") }}
            </button>
          </div>
        </div>
        <div v-if="showContentModal?.list?.length > 0" class="search-content">
          <!-- 목록이 하나 일 경우  -->
          <div
            v-if="showContentModal?.list?.length === 1"
            class="search-content"
            style="padding: 0"
          >
            <div class="search-content-sub">
              <div class="selected">
                <div
                  class="header"
                  :initSort="'initSortModal_' + 0"
                  style="cursor: pointer; position: relative"
                  :class="{
                    collaps: sortCollapsed(
                      showContentModal.list[0].code + '_' + 0
                    ),
                  }"
                  @click.stop="
                    toggleSort(
                      event,
                      0,
                      showContentModal.code,
                      showContentModal.list[0].code,
                      showContentModal.list[0].list,
                      0
                    )
                  "
                >
                  {{
                    this.$i18n.locale == "en"
                      ? showContentModal.list[0].englishName ||
                        showContentModal.list[0].name + "(en)"
                      : showContentModal.list[0].name
                  }}
                  <span>[{{ showContentModal.list[0].list.length }}]</span>
                  <span class="material-symbols-outlined"></span>
                  <!-- 20230612 material-symbols-outlined 클래스 추가 -->
                  <div
                    v-if="showContentModal?.subContents[0]?.desc?.length > 0"
                    style="position: absolute; top: 10%; left: 68%; width: 32%"
                  >
                    <div
                      v-for="(d, i) in showContentModal?.subContents[0]?.desc"
                      v-bind:key="(d, i)"
                      style="width: 100%; position: relative"
                      :initSort="'initSortModal_' + (i + 1)"
                      :class="{
                        collaps: sortCollapsed(
                          showContentModal.list[0].code + '_' + i + 1
                        ),
                      }"
                      @click.stop="
                        toggleSort(
                          event,
                          i + 1,
                          showContentModal.code,
                          showContentModal.list[0].code,
                          showContentModal.list[0].list,
                          i + 1
                        )
                      "
                    >
                      <span
                        style="
                          display: inline-block;
                          padding-left: 6px;
                          border-left: var(--border-line);
                          position: absolute;
                          width: 100%;
                        "
                      >
                        {{
                          this.$i18n.locale == "en"
                            ? d.englishName || d.name + "(en)"
                            : d.name
                        }}
                      </span>
                      <span
                        class="material-symbols-outlined"
                        style="
                          font-size: 26px;
                          color: #b9b9b9;
                          position: absolute;
                          left: 18%;
                        "
                      ></span>
                    </div>
                  </div>
                </div>
                <select
                  class="select-multiple"
                  :contentCode="showContentModal.code"
                  multiple
                  @change="
                    (event) =>
                      onChange(
                        event,
                        0,
                        showContentModal.code,
                        showContentModal.list[0].code
                      )
                  "
                  @focus="(event) => focus(event, 0)"
                  @focusout="(event) => focusout(event, 0)"
                  :id="showContentModal.list[0].code"
                >
                  <optgroup
                    style="margin-top: -25px; width: 69%"
                    :style="{
                      width:
                        showContentModal?.subContents[0]?.desc?.length > 0
                          ? 69 + '%'
                          : 100 + '%',
                    }"
                  >
                    <option
                      v-for="(e, i) in showContentModal.list[0].list"
                      v-bind:key="(e, i)"
                      :value="e.id"
                      :name="e.name"
                      :englishName="e.englishName"
                      @dblclick="onClickExcl()"
                      @contextmenu.prevent="
                        showContextMenu(
                          $event,
                          showContentModal.code,
                          showContentModal.list[0].code,
                          e.id
                        )
                      "
                      :title="
                        this.$i18n.locale == 'en'
                          ? e.englishName || e.name + '(en)'
                          : e.name
                      "
                    >
                      {{
                        this.$i18n.locale == "en"
                          ? e.englishName || e.name + "(en)"
                          : e.name
                      }}
                    </option>
                  </optgroup>
                  <optgroup
                    v-if="showContentModal?.subContents[0]?.desc?.length > 0"
                    style="
                      position: relative;
                      left: 69%;
                      width: 35%;
                      height: 0%;
                    "
                    :style="{
                      top:
                        showContentModal.list[0].list.length === 1
                          ? '-' + 42 + 'px'
                          : '-' +
                            ((showContentModal.list[0].list.length - 1) * 24 +
                              42) +
                            'px',
                    }"
                  >
                    <option
                      v-for="(e, i) in showContentModal.list[0].list"
                      v-bind:key="(e, i)"
                      :value="e.id"
                      :name="e.name"
                      :englishName="e.englishName"
                      @dblclick="onClickExcl()"
                      @contextmenu.prevent="
                        showContextMenu(
                          $event,
                          showContentModal.code,
                          showContentModal.list[0].code,
                          e.id
                        )
                      "
                      :title="
                        this.$i18n.locale == 'en'
                          ? e.englishDesc1 || e.desc1 + '(en)'
                          : e.desc1
                      "
                      style="width: 90%"
                    >
                      {{
                        this.$i18n.locale == "en"
                          ? e.englishDesc1 || e.desc1 + "(en)"
                          : e.desc1
                      }}
                    </option>
                  </optgroup>
                </select>
              </div>
            </div>
          </div>
          <!-- 목록이 여러개 일 경우  -->
          <div v-else class="search-content" style="padding: 0">
            <div
              v-for="(l, i) in showContentModal?.list"
              v-bind:key="(l, i)"
              class="search-content-sub"
              :class="{ listDisabled: isListDisabled && i !== listIndex }"
            >
              <div class="selected">
                <div
                  class="header"
                  :initSort="'initSortModal_' + i"
                  style="cursor: pointer; position: relative"
                  :class="{ collaps: sortCollapsed(l.code + '_' + i) }"
                  @click.stop="
                    toggleSort(
                      event,
                      i,
                      showContentModal.code,
                      l.code,
                      l.list,
                      0
                    )
                  "
                >
                  {{
                    this.$i18n.locale == "en"
                      ? l.englishName || l.name + "(en)"
                      : l.name
                  }}
                  <span :spanId="'span_' + i"
                    >[{{
                      (showContentModal.type === 3 && i === 0) ||
                      showContentModal.type === 1 ||
                      showContentModal.type === 2 ||
                      showContentModal.search === true
                        ? l.list.length
                        : 0
                    }}]</span
                  >
                  <span class="material-symbols-outlined"></span>
                  <!-- 20230612 material-symbols-outlined 클래스 추가 -->
                  <!-- 목록 펼치고 접기 작업  -->
                  <button
                    class="button-wide-open"
                    :class="{ hidden: isListDisabled }"
                    @click.stop="onListDisabled($event, i, true)"
                  >
                    <img src="@/assets/img/arrowLeftRight1.png" alt="펼치기" />
                  </button>

                  <button
                    class="button-wide-close"
                    :class="{
                      hidden:
                        !isListDisabled || (isListDisabled && i !== listIndex),
                    }"
                    @click.stop="onListDisabled($event, i, false)"
                  >
                    <img src="@/assets/img/arrowLeftRight1.png" alt="펼치기" />
                  </button>
                  <!-- 목록 펼치고 접기 작업  -->
                  <div
                    v-if="showContentModal?.subContents[i]?.desc?.length > 0"
                    style="position: absolute; display: flex; top: 16%"
                    :style="{
                      left: i === 0 ? 70 + '%' : (1 + i) * 25 + '%',
                      width: i === 0 ? 30 + '%' : 50 + '%',
                    }"
                  >
                    <div
                      v-for="(d, j) in showContentModal?.subContents[i]?.desc"
                      v-bind:key="(d, j)"
                      :style="{
                        width:
                          showContentModal?.subContents[i]?.desc?.length === 1
                            ? 100 + '%'
                            : j === 0
                            ? 34 + '%'
                            : j === 1
                            ? 34 + '%'
                            : 32 + '%',
                      }"
                      :initSort="'initSortModal_' + l.code + '_' + j"
                      :class="{
                        collaps: sortCollapsed(
                          showContentModal.subContents[0].code + '_' + j + 1
                        ),
                      }"
                      @click.stop="
                        toggleSort(
                          event,
                          l.code + '_' + j,
                          showContentModal.code,
                          l.code,
                          l.list,
                          j + 1
                        )
                      "
                    >
                      <span
                        style="
                          width: 100%;
                          display: inline-block;
                          border-left: var(--border-line);
                          padding-left: 6px;
                        "
                      >
                        {{
                          this.$i18n.locale == "en"
                            ? d.englishName || d.name + "(en)"
                            : d.name
                        }}
                      </span>
                      <span
                        class="material-symbols-outlined"
                        style="
                          font-size: 26px;
                          color: #b9b9b9;
                          position: absolute;
                        "
                        :style="{
                          left:
                            showContentModal?.subContents[i]?.desc?.length === 1
                              ? 28 + '%'
                              : j === 0
                              ? 16 + '%'
                              : j === 1
                              ? 54 + '%'
                              : 80 + '%',
                        }"
                      ></span>
                    </div>
                  </div>
                </div>
                <select
                  class="select-multiple"
                  :contentCode="showContentModal.code"
                  multiple
                  @change="
                    (event) => onChange(event, i, showContentModal.code, l.code)
                  "
                  @focus="(event) => focus(event, i)"
                  @focusout="(event) => focusout(event, i)"
                  :id="l.code"
                >
                  <optgroup
                    style="margin-top: -25px; height: 0"
                    :style="{
                      width:
                        showContentModal?.subContents[i]?.desc?.length > 0
                          ? showContentModal?.subContents[i]?.desc?.length === 1
                            ? 72 + '%'
                            : 51 + '%'
                          : 100 + '%',
                    }"
                  >
                    <option
                      :optionId="'option_' + showContentModal.code + '_' + i"
                      v-show="
                        i !== 0 &&
                        showContentModal.search !== true &&
                        showContentModal.type === 3
                      "
                      disabled=""
                      value=""
                    >
                      {{
                        i !== 0
                          ? this.$i18n.locale == "en"
                            ? showContentModal.list[i - 1].englishName != ""
                              ? $t("label.select_parentItem") +
                                showContentModal.list[i - 1].englishName +
                                "."
                              : $t("label.select_parentItem") +
                                showContentModal.list[i - 1].name +
                                "(en)"
                            : showContentModal.list[i - 1].name +
                              $t("label.select_parentItem")
                          : showContentModal.list[0].name
                      }}
                    </option>
                    <option
                      v-for="(e, j) in l.list"
                      v-bind:key="(e, j)"
                      :value="e.id"
                      :name="e.name"
                      :englishName="e.englishName"
                      :parentId="
                        showContentModal.code + '_' + e.parentId + '_' + i
                      "
                      @dblclick="onClickExcl()"
                      v-show="
                        (showContentModal.type === 3 && i === 0) ||
                        showContentModal.type === 1 ||
                        showContentModal.type === 2 ||
                        showContentModal.search === true
                      "
                      @contextmenu.prevent="
                        showContextMenu(
                          $event,
                          showContentModal.code,
                          l.code,
                          e.id
                        )
                      "
                      :title="
                        this.$i18n.locale == 'en'
                          ? e.englishName || e.name + '(en)'
                          : e.name
                      "
                    >
                      {{
                        this.$i18n.locale == "en"
                          ? e.englishName || e.name + "(en)"
                          : e.name
                      }}
                    </option>
                  </optgroup>
                  <optgroup
                    v-if="showContentModal?.subContents[i]?.desc?.length > 0"
                    style="position: relative; height: 0%"
                    :style="{
                      left:
                        showContentModal?.subContents[i]?.desc?.length === 1
                          ? 72 + '%'
                          : 51 + '%',
                      width:
                        showContentModal?.subContents[i]?.desc?.length === 1
                          ? 35 + '%'
                          : 21 + '%',
                    }"
                  >
                    <option
                      v-for="(e1, j1) in l.list"
                      v-bind:key="(e1, j1)"
                      :value="e1.id"
                      :name="e1.name"
                      :englishName="e1.englishName"
                      style="width: 85%"
                      @dblclick="onClickExcl()"
                      @contextmenu.prevent="
                        showContextMenu(
                          $event,
                          showContentModal.code,
                          l.code,
                          e1.id
                        )
                      "
                      :title="
                        this.$i18n.locale == 'en'
                          ? e1.englishName || e1.name + '(en)'
                          : e1.name
                      "
                    >
                      {{
                        this.$i18n.locale == "en"
                          ? e1.englishDesc1 || e1.desc1 + "(en)"
                          : e1.desc1
                      }}
                    </option>
                  </optgroup>

                  <optgroup
                    v-if="showContentModal?.subContents[i]?.desc?.length > 1"
                    style="position: relative; height: 0%"
                    :style="{
                      left: 69 + '%',
                      width: 21 + '%',
                    }"
                  >
                    <option
                      v-for="(e1, j1) in l.list"
                      v-bind:key="(e1, j1)"
                      :value="e1.id"
                      :name="e1.name"
                      :englishName="e1.englishName"
                      style="width: 85%"
                      @dblclick="onClickExcl()"
                      @contextmenu.prevent="
                        showContextMenu(
                          $event,
                          showContentModal.code,
                          l.code,
                          e1.id
                        )
                      "
                      :title="
                        this.$i18n.locale == 'en'
                          ? e1.englishName || e1.name + '(en)'
                          : e1.name
                      "
                    >
                      {{
                        this.$i18n.locale == "en"
                          ? e1.englishDesc2 || e1.desc2 + "(en)"
                          : e1.desc2
                      }}
                    </option>
                  </optgroup>

                  <optgroup
                    v-if="showContentModal?.subContents[i]?.desc?.length > 2"
                    style="position: relative; height: 0%; text-align: right"
                    :style="{
                      left: 88 + '%',
                      width: 16 + '%',
                    }"
                  >
                    <option
                      v-for="(e1, j1) in l.list"
                      v-bind:key="(e1, j1)"
                      :value="e1.id"
                      :name="e1.name"
                      :englishName="e1.englishName"
                      style="width: 85%"
                      @dblclick="onClickExcl()"
                      @contextmenu.prevent="
                        showContextMenu(
                          $event,
                          showContentModal.code,
                          l.code,
                          e1.id
                        )
                      "
                      :title="
                        typeof e1.englishDesc3 != 'undefined'
                          ? parseInt(e1.desc3).toLocaleString()
                          : ''
                      "
                    >
                      {{
                        typeof e1.englishDesc3 != "undefined"
                          ? parseInt(e1.desc3).toLocaleString()
                          : ""
                      }}
                    </option>
                  </optgroup>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="search-content">
          <div class="search-content">
            <div class="search-content-sub">
              <div class="selected">
                <div class="header">&nbsp;</div>
                <select multiple>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="boxes-container">
          <div class="standard-box">
            <button class="btn standard-button" @click="onClickStd()">
              {{ $t("label.popup_std") }}
            </button>
            <!-- <div class="standard-content">
              <div v-for="(e, i) in standardContent.list" v-bind:key="(e, i)">
                {{
                  this.$i18n.locale == "en"
                    ? e.englishName || e.name + "(en)"
                    : e.name
                }}
              </div>
            </div> -->

            <select
              class="standard-content"
              multiple
              @change="(event) => onChangeItems(event, 99, 'standard')"
              id="standardSelect"
              v-model="standardValues"
              style="padding: 5px"
              @keydown="handleDeleteKeyDownItem($event, 'standard')"
            >
              <option
                v-for="(e, i) in standardContent.list"
                v-bind:key="(e, i)"
                :tabindex="i"
                :value="e"
                @dblclick="removeItem(i, 'standard')"
                @contextmenu.prevent="
                  showContextMenuSelected($event, 'standard', e)
                "
              >
                {{
                  this.$i18n.locale == "en"
                    ? e.englishName || e.name + "(en)"
                    : e.name
                }}
              </option>
            </select>
          </div>
          <div class="exclude-box">
            <button class="btn exclude-button" @click="onClickExcl()">
              {{ $t("label.popup_exp") }}
            </button>
            <select
              class="exclude-content"
              multiple
              @change="(event) => onChangeItems(event, 98, 'exclude')"
              id="excludeSelect"
              v-model="excludeValues"
              style="padding: 5px"
              @keydown="handleDeleteKeyDownItem($event, 'exclude')"
            >
              <option
                v-for="(e, i) in excludeContents.list"
                v-bind:key="(e, i)"
                :tabindex="i"
                :value="e"
                @dblclick="removeItem(i, 'exclude')"
                @contextmenu.prevent="
                  showContextMenuSelected($event, 'exclude', e)
                "
              >
                {{
                  this.$i18n.locale == "en"
                    ? e.englishName || e.name + "(en)"
                    : e.name
                }}
              </option>
            </select>
          </div>
        </div>
        <!-- 제품검색  context-menu -->
        <div
          v-if="isContextMenuVisible"
          class="context-menu"
          :style="{
            top: contextMenuPosition.y + 'px',
            left: contextMenuPosition.x + 'px',
          }"
          @click.stop="productSearch($event)"
        >
          <ul @click="productSearch($event)">
            <li @click="productSearch($event)">
              <button @click="productSearch($event)">
                {{ $t("button.product_search") }}
              </button>
            </li>
          </ul>
        </div>
        <!-- 기준/제외 삭제  context-menu-->
        <div
          v-if="isContextMenuVisibleItem"
          :contextType="contextType"
          class="context-menu"
          :style="{
            top: contextMenuItemPosition.y + 'px',
            left: contextMenuItemPosition.x + 'px',
          }"
        >
          <ul>
            <li @click="deleteItemSelected($event, contextType)">
              <!-- } 20230612 스타일 변경 -->
              <button>
                {{ $t("button.delete") }}
              </button>
            </li>
          </ul>
        </div>

        <footer class="footer-container">
          <span style="font-weight: 700">
            ■ {{ $t("label.popup_customizingName") }} :
          </span>
          <input
            style="width: 368px"
            placeholder="Customize"
            v-model="customName"
            type="text"
          />
          <button class="btn primary" @click="confirm">
            {{ $t("button.complete") }}
          </button>
          <button class="btn" @click="onCancel">
            {{ $t("button.cancel") }}
          </button>
        </footer>
      </div>
    </div>
  </div>
  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import constants from "@/data/constants.js";
import {
  defineComponent,
  ref,
  reactive,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents.js";
import { useCommonStore } from "@/store/common";
import ubistApi from "@/lib/ubistApi";

export default defineComponent({
  name: "ProductModal",
  props: {
    isProductModalOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AlertDialog,
  },
  setup(props, context) {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
    });
    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };

    const { emit } = context;
    const { t } = useI18n();
    const contentsStore = useContentsStore();
    const {
      rows,
      columns,
      contents,
      showContent,
      showContentModal,
      selectedContent,
      selectedItemValue,
      focusIndex0,
      standardItems,
      excludeItems,
      sortInit,
      itemEditModeYn,
    } = storeToRefs(contentsStore);

    const showDropBox = ref([
      { code: 'C004', type: 1, name: '제품', englishName: 'Product' },
      { code: 'C002', type: 1, name: '제조사', englishName: 'Manufacturer' },
      { code: 'C003', type: 1, name: '판매사', englishName: 'Distributor' },
      { code: 'C005', type: 1, name: 'ATC', englishName: 'ATC' },
      { code: 'C006', type: 1, name: '성분', englishName: 'Molecule' },
    ]);

    const CONSTANT_PRODUCT_CODE = ref(null);
    const CONSTANT_PRODUCT_TYPE = ref(null);
    const standardValues = ref([]);
    const excludeValues = ref([]);
    const removeItem = (index, type) => {
      if (type === "standard") {
        //기준항목이 하나이고 제외항목이 있을경우 삭제 못함
        if (
          standardItems.value.list.length === 1 &&
          excludeItems.value.list.length > 0
        ) {
          hideContextMenuItem();
          return;
        }
        standardItems.value.list.splice(index, 1);
        //기준항목이 하나 일경우 삭제 할때 제외항목도 모두 삭제때
        // if (standardItems.value.list.length === 0) {
        //   excludeItems.value.list = [];
        // }
      } else {
        excludeItems.value.list.splice(index, 1);
      }
      hideContextMenuItem();
    };

    watch(showContent, () => {
      if (
        props.isProductModalOpen &&
        showContent.value.list.reduce(
          (sum, list) => sum + list.list.length,
          0
        ) === 0
      ) {
        isAlertDialogOpen.value = true;
        alertDialog.value = {
          message: "검색 결과가 없습니다.",
        };
      }
    });
    watch([contents, selectedContent], () => {
      showDropBox.value = [];
      // contents 에서 제품, 제조사, 판매사, ATC, 성분만 보여준다.
      contents.value.forEach((content) => {
        if (constants.CONSTANT_PRODUCT_CODE.includes(content.code)) {
          showDropBox.value.push({
            code: content.code,
            type: content.type,
            name: content.name,
            englishName: content.englishName,
          });
        }
      });
      contents.value.forEach((content) => {
        if (
          constants.CONSTANT_MANUFACTURER_CODE.includes(content.code) ||
          constants.CONSTANT_DISTRIBUTER_CODE.includes(content.code) ||
          [constants.CONSTANT_ATC_CODE, constants.CONSTANT_OLD_ATC_CODE].includes(content.code) ||
          constants.CONSTANT_MOLECULE_CODE.includes(content.code)
        ) {
          showDropBox.value.push({
            code: content.code,
            type: content.type,
            name: content.name,
            englishName: content.englishName,
          });
        }
      });

      const CONSTANT_PRODUCT = contents.value.find((constant) => constants.CONSTANT_PRODUCT_CODE.includes(constant.code));
      if (CONSTANT_PRODUCT) {
        CONSTANT_PRODUCT_CODE.value = CONSTANT_PRODUCT.code;
        CONSTANT_PRODUCT_TYPE.value = CONSTANT_PRODUCT.type;
        searchContent.value = CONSTANT_PRODUCT_CODE.value + ":" + CONSTANT_PRODUCT_TYPE.value;
      }
    });

    //오른쪽 마우스 기능
    const isContextMenuVisibleItem = ref(false);
    const removeSelectedItemValues = ref([]);
    const contextMenuItemPosition = reactive({ x: 0, y: 0 });
    const contextType = ref("standard");

    const hideContextMenuItem = () => {
      isContextMenuVisibleItem.value = false;
      removeSelectedItemValues.value = [];
      // const checkOptions1 = document.querySelectorAll(
      //   ".standard-content > option:checked"
      // );
      // checkOptions1.forEach((option) => {
      //   option.selected = false;
      // });

      // const checkOptions2 = document.querySelectorAll(
      //   ".exclude-content > option:checked"
      // );
      // checkOptions2.forEach((option) => {
      //   option.selected = false;
      // });
    };
    watch(props, () => {
      if (props.isProductModalOpen && itemEditModeYn.value) {
        customName.value = standardItems.value.name;
      } else {
        customName.value = "Customize";
      }

      const CONSTANT_PRODUCT = contents.value.find((constant) => constants.CONSTANT_PRODUCT_CODE.includes(constant.code));
      if (CONSTANT_PRODUCT) {
        CONSTANT_PRODUCT_CODE.value = CONSTANT_PRODUCT.code;
        CONSTANT_PRODUCT_TYPE.value = CONSTANT_PRODUCT.type;
        searchContent.value = CONSTANT_PRODUCT_CODE.value + ":" + CONSTANT_PRODUCT_TYPE.value;
      }
      if (showContentModal.value) {
        const data = CONSTANT_PRODUCT.subContents.map((content) => ({ ...content, list: []}));
        showContentModal.value = {
          ...CONSTANT_PRODUCT,
          list: new Proxy(data, {}),
          search: true,
        };
      }
    });

    const handleDeleteKeyDownItem = (event, type) => {
      event.preventDefault();
      if (event.key === "Delete" || event.keyCode === 46) {
        // 선택한 option을 삭제하거나 필요한 작업을 수행할 수 있습니다.
        let selLength = 0;
        if (type === "standard") {
          selLength = standardValues.value.length;
          removeSelectedItemValues.value = standardValues.value;
        } else {
          selLength = excludeValues.value.length;
          removeSelectedItemValues.value = excludeValues.value;
        }
        if (selLength > 0) {
          isContextMenuVisibleItem.value = true;
          contextMenuItemPosition.x = event.clientX;
          contextMenuItemPosition.y = event.clientY;
          //선택한 item 삭제
          deleteItemSelected(event, type);
        }
      }
    };

    const showContextMenuSelected = (event, type, value) => {
      event.preventDefault();
      let selLength = 0;

      console.log("value ::", value);

      if (type === "standard") {
        selLength = standardValues.value.length;
        removeSelectedItemValues.value = standardValues.value;
      } else {
        selLength = excludeValues.value.length;
        removeSelectedItemValues.value = excludeValues.value;
      }
      console.log("selLength ::", selLength);

      if (selLength > 0) {
        isContextMenuVisibleItem.value = true;
        contextMenuItemPosition.x = event.clientX;
        contextMenuItemPosition.y = event.clientY;
        contextType.value = type;
      } else {
        isContextMenuVisibleItem.value = true;
        contextMenuItemPosition.x = event.clientX;
        contextMenuItemPosition.y = event.clientY;
        contextType.value = type;
        removeSelectedItemValues.value = [value];
      }
    };

    const deleteItemSelected = (event, type) => {
      event.preventDefault(); // 기본 동작을 중단시킵니다.
      console.log("deleteItemSelected");

      // 230720 기준 전체 삭제 불가
      // if (
      //   removeSelectedItemValues.value.length ===
      //   standardItems.value.list.length
      // ) {
      //   hideContextMenuItem();
      //   return;
      // }

      if (type === "standard") {
        //기준항목이 하나이고 제외항목이 있을경우 삭제 못함
        if (
          removeSelectedItemValues.value.length ===
            standardItems.value.list.length &&
          excludeItems.value.list.length > 0
        ) {
          hideContextMenuItem();
          return;
        }

        if (removeSelectedItemValues.value.length > 0) {
          console.log(
            "removeSelectedItemValues.value ::",
            removeSelectedItemValues.value
          );
          console.log("standardItems.value ::", standardItems.value);
          removeSelectedItemValues.value.forEach((sel) => {
            const removeIndex = standardItems.value.list.findIndex(
              (list) => list === sel
            );
            standardItems.value.list.splice(removeIndex, 1);
          });

          //기준항목이 하나 일경우 삭제 할때 제외항목도 모두 삭제때
          // if (standardItems.value.list.length === 0) {
          //   excludeItems.value.list = [];
          // }
        } else {
          alertDialog.value.message = t("message.validation_deleteItem");
          isAlertDialogOpen.value = true;
        }
      } else {
        if (removeSelectedItemValues.value.length > 0) {
          removeSelectedItemValues.value.forEach((sel) => {
            const removeIndex = excludeItems.value.list.findIndex(
              (list) => list === sel
            );
            excludeItems.value.list.splice(removeIndex, 1);
          });
        } else {
          alertDialog.value.message = t("message.validation_deleteItem");
          isAlertDialogOpen.value = true;
        }
      }
      removeSelectedItemValues.value = [];
      standardValues.value = [];
      excludeValues.value = [];
      hideContextMenuItem();
    };

    //기존
    //230512 여기까지

    const isProductModalOpen = ref(false);
    const searchContent = ref(null);

    //검색 기본값 설정 Placeholder 역할
    const searchKeyword = ref("");

    const openProductModal = () => {
      isProductModalOpen.value = true;
    };

    const closeProductModal = () => {
      isProductModalOpen.value = false;
    };

    const isContextMenuVisible = ref(false);
    const contextMenuPosition = reactive({ x: 0, y: 0 });
    const selectedValues = ref({
      code: null,
      id: null,
    });

    const showContextMenu = (event, pCode, code, id) => {
      if (constants.CONSTANT_PRODUCT_CODE.includes(pCode)) return;

      event.preventDefault();
      isContextMenuVisible.value = true;
      contextMenuPosition.x = event.clientX;
      contextMenuPosition.y = event.clientY;

      selectedValues.value = {
        code,
        id,
      };
    };

    const hideContextMenu = () => {
      isContextMenuVisible.value = false;
      selectedList.value = [];
      const checkOptions = document.querySelectorAll(
        ".select-multiple > option:checked"
      );
      checkOptions.forEach((option) => {
        option.selected = false;
      });
    };

    const clearSearchKeyword = () => {
      searchKeyword.value = "";
    };

    const selectedList = ref([]);
    const focusIndex = ref(focusIndex0);

    const fnSearch = (searchContent) => {
      if (typeof searchContent === "undefined") return;
      const commonStore = useCommonStore();
      const value = searchContent;
      const str = value.split(":");
      
      const result = commonStore.validationSearchText(searchKeyword.value, constants.CONSTANT_EXCEPTION_SEARCH_TEXT.includes(str[0]) ? 1 : 2);
      
      if (!result) {
        if (constants.CONSTANT_EXCEPTION_SEARCH_TEXT.includes(str[0])) alertDialog.value.message = t("message.validation_empty");
        else alertDialog.value.message = t("message.validation_length");

        isAlertDialogOpen.value = true;

        return;
      }
      
      commonStore.onSearchContentModalItem(str[0], str[1], searchKeyword.value);


      selectedList.value = [];
      focusIndex.value = -1;

      const checkSelects = document.querySelectorAll(
        ".select-multiple"
      );
      
      checkSelects.forEach((select) => {
        select.selectedIndex = -1;
      });
    };

    const standardContent = ref(standardItems);
    const excludeContents = ref(excludeItems);

    const fnSearchReset = (code, type) => {
      console.log("fnSearchReset", code, type);
      searchContent.value = code + ":" + type;
      searchKeyword.value = "";

      selectedList.value = [];
      focusIndex.value = -1;

      standardContent.value = {
        name: "",
        englishName: "",
        list: [],
      };
      excludeContents.value = {
        name: "",
        englishName: "",
        list: [],
      };

      if (showContentModal.value) {
        for (let item of showContentModal.value.list) {
          item.list = [];
        }
      }
    };

    const state = reactive({
      isProductModalOpen: false,
      focusedList: null,
      selectedItems: [],
      noItemSelected: true,
    });

    const focus = (event, i) => {
      focusIndex.value = i;
      hideContextMenu();
      hideContextMenuItem();
    };

    const focusout = (event, i) => {
      // console.log("focusout");
      const checkOptions = document.querySelectorAll(
        ".select-multiple > option:checked"
      );
      checkOptions.forEach((option) => {
        option.selected = false;
      });

      const checkOptions1 = document.querySelectorAll(
        ".standard-content > option:checked"
      );
      checkOptions1.forEach((option) => {
        option.selected = false;
      });

      const checkOptions2 = document.querySelectorAll(
        ".exclude-content > option:checked"
      );
      checkOptions2.forEach((option) => {
        option.selected = false;
      });

      // standardValues.value = [];
      // excludeValues.value = [];

      setTimeout(() => {
        if (focusIndex.value !== i) {
          event.target.selectedIndex = -1;
        }
        standardValues.value = [];
        excludeValues.value = [];
      }, 250);
    };

    const selectedItem = ref(null); // 선택한 항목을 저장할 변수

    // 여기에 추가하는 기능 넣기
    const onChange = (event, i, pCode, code) => {
      const options = event.target.selectedOptions;
      const values = [];
      for (let i = 0; i < options.length; i++) {
        const option = options.item(i);
        values.push({
          pCode: pCode,
          code: code,
          id: option.value,
          name: option.getAttribute("name"),
          englishName: option.getAttribute("englishName"),
          type: 2,
        });
      }
      selectedList.value[i] = values;
    };

    onMounted(() => {
      window.addEventListener("click", closeContextMenu);

      if (contents.value) {
        // console.log('mount');
        showDropBox.value = [];
        // contents 에서 제품, 제조사, 판매사, ATC, 성분만 보여준다.
        contents.value.forEach((content) => {
          if (constants.CONSTANT_PRODUCT_CODE.includes(content.code)) {
            showDropBox.value.push({
              code: content.code,
              type: content.type,
              name: content.name,
              englishName: content.englishName,
            });
          }
        });
        contents.value.forEach((content) => {
          if (
            constants.CONSTANT_MANUFACTURER_CODE.includes(content.code) ||
            constants.CONSTANT_DISTRIBUTER_CODE.includes(content.code) ||
            [constants.CONSTANT_ATC_CODE, constants.CONSTANT_OLD_ATC_CODE].includes(content.code) ||
            constants.CONSTANT_MOLECULE_CODE.includes(content.code)
          ) {
            showDropBox.value.push({
              code: content.code,
              type: content.type,
              name: content.name,
              englishName: content.englishName,
            });
          }
        });

        const CONSTANT_PRODUCT = contents.value.find((constant) => constants.CONSTANT_PRODUCT_CODE.includes(constant.code));
        if (CONSTANT_PRODUCT) {
          CONSTANT_PRODUCT_CODE.value = CONSTANT_PRODUCT.code;
          CONSTANT_PRODUCT_TYPE.value = CONSTANT_PRODUCT.type;
          searchContent.value = CONSTANT_PRODUCT_CODE.value + ":" + CONSTANT_PRODUCT_TYPE.value;
        }
      }
    });

    onUnmounted(() => {
      window.removeEventListener("click", closeContextMenu);
    });

    const closeContextMenu = () => {
      isContextMenuVisible.value = false;
      isContextMenuVisibleItem.value = false;
      // hideContextMenu();
      // hideContextMenuItem();
    };

    const onClickStd = () => {
      const index = focusIndex.value;
      if (index === -1) return;
      if (typeof selectedList.value[index] === "undefined") return;
      if (selectedList.value[index].length === 0) return;

      const list = standardContent.value.list;
      selectedItem.value = selectedList.value[index];

      const isSameContent = list.length > 0 && showContentModal.value.subContents.some((content) => content.code === list[0].code);
      if (list.length > 0 && !isSameContent) {
        alertDialog.value.message = t("message.sametype_restriction");
        isAlertDialogOpen.value = true;
        return;
      }

      const error = [];
      selectedList.value[index].forEach((select) => {
        const { code, id, name, englishName, type, pCode } = select;
        if (!list.find((l) => l.id === id)) {
          list.push({ code, id, name, englishName, type, pCode });
        } else {
          error.push({ name: name, englishName: englishName });
        }
      });

      if (error.length > 0) {
        const errorItems = error;
        let itemName = "";
        let itemEnglishName = "";
        errorItems.forEach((item, idx) => {
          if (idx > 0) {
            itemName += ",";
            itemEnglishName += ",";
          }
          itemName += item.name;
          itemEnglishName += item.englishName;
        });

        let item = itemName;
        if (localStorage.getItem("lang") == "en") {
          item = itemEnglishName;
        }
        alertDialog.value.message = t("message.validation_existsCombiItem", {
          item,
        });
        isAlertDialogOpen.value = true;
      }

      state.noItemSelected = selectedList.value.every(
        (list) => list.length === 0
      );
      selectedList.value = [];
      // focusIndex.value = -1;
    };

    const onClickExcl = () => {
      const index = focusIndex.value;
      if (index === -1) return;
      if (typeof selectedList.value[index] === "undefined") return;
      if (selectedList.value[index].length === 0) return;
      const list = excludeContents.value.list;
      const standardList = standardContent.value.list;

      if (standardList.length === 0) {
        alertDialog.value.message = t("message.criterion_prerequisite");
        isAlertDialogOpen.value = true;
        return;
      }

      // 'some' 함수를 사용하여 해당 조건을 만족하는 아이템이 있는지 확인
      const invalidItem = selectedList.value[index].some((select) => {
        return !constants.CONSTANT_PRODUCT_CODE.includes(select.pCode);
      });

      if (invalidItem) {
        alertDialog.value.message = t("message.exclusion_constraint");
        isAlertDialogOpen.value = true;
        return;
      }

      const error = [];
      selectedList.value[index].forEach((select) => {
        const { code, id, name, englishName, pCode, type } = select;
        if (
          constants.CONSTANT_PRODUCT_CODE.includes(pCode) &&
          !list.find((l) => l.id === id)
        ) {
          list.push({ code, id, name, englishName, type });
        } else {
          error.push({ name: name, englishName: englishName });
        }
      });

      if (error.length > 0) {
        const errorItems = error;
        let itemName = "";
        let itemEnglishName = "";
        errorItems.forEach((item, idx) => {
          if (idx > 0) {
            itemName += ",";
            itemEnglishName += ",";
          }
          itemName += item.name;
          itemEnglishName += item.englishName;
        });
        let existItem = itemName;
        if (localStorage.getItem("lang") == "en") {
          existItem = itemEnglishName;
        }
        alertDialog.value.message = t("message.validation_existsItem", {
          existItem,
        });
        isAlertDialogOpen.value = true;
      }
      state.noItemSelected = selectedList.value.every(
        (list) => list.length === 0
      );
      selectedList.value = [];
      // focusIndex.value = -1;
    };

    const confirm = () => {
      const standardList = standardContent.value.list;
      if (standardList.length === 0) {
        alertDialog.value.message = t("message.please_custom_product");
        isAlertDialogOpen.value = true;
        return;
      }
      let existsYn = false;
      let itemEditMode = itemEditModeYn.value;

      const selectedContent = [...rows.value, ...columns.value].find((constant) => constants.CONSTANT_PRODUCT_CODE.includes(constant.code));

      //최초입력시 (수정이 아닐때)
      if (!itemEditMode) {
        //기본 전체 삭제
        const removeIndex = selectedContent.list.findIndex((item) => [97, 98].includes(item.type));
        if (removeIndex > -1) {
          selectedContent.list.splice(removeIndex, 1);
        }

        selectedContent.list.forEach((select) => {
          let item = customName.value;
          if (localStorage.getItem("lang") == "en") {
            item = customName.value;
          }
          if (select.name === "(C) " + item) {
            alertDialog.value.message = t("message.validation_existsItemName", {
              item,
            });
            isAlertDialogOpen.value = true;
            existsYn = true;
          }
        });

        if (!existsYn) {
          selectedContent.list.push({
            strId: standardContent.value.list.map((l) => l.id),
            strCode: standardContent.value.list.map((l) => l.code),
            strItemName: standardContent.value.list.map((l) => l.name),
            strItemEnglishName: standardContent.value.list.map(
              (l) => l.englishName
            ),
            expId: excludeContents.value.list.map((l) => l.id),
            expCode: excludeContents.value.list.map((l) => l.code),
            expItemName: excludeContents.value.list.map((l) => l.name),
            expItemEnglishName: excludeContents.value.list.map(
              (l) => l.englishName
            ),
            id: "(C) " + customName.value,
            name: "(C) " + customName.value,
            englishName: "(C) " + customName.value,
            type: standardContent.value.list[0].type,
          });
        } else {
          return;
        }
      } else {
        //수정 일 떄
        const selContent = selectedContent.list;
        const selItemValue = selectedItemValue.value;

        selContent
          .filter(
            (item) =>
              item.strCode !== selItemValue.strCode &&
              item.strId !== selItemValue.strId &&
              item.expCode !== selItemValue.expCode &&
              item.expId !== selItemValue.expId
          )
          .forEach((select) => {
            let item = customName.value;
            if (localStorage.getItem("lang") == "en") {
              item = customName.value;
            }
            if (select.name === "(C) " + item) {
              alertDialog.value.message = t(
                "message.validation_existsItemName",
                { item }
              );
              isAlertDialogOpen.value = true;
              existsYn = true;
            }
          });

        if (!existsYn) {
          const foundItem = selContent.find(
            (item) =>
              item.strCode === selItemValue.strCode &&
              item.strId === selItemValue.strId &&
              item.expCode === selItemValue.expCode &&
              item.expId === selItemValue.expId
          );
          if (foundItem) {
            foundItem.strCode = standardContent.value.list.map((l) => l.code);
            foundItem.strId = standardContent.value.list.map((l) => l.id);
            foundItem.strItemName = standardContent.value.list.map(
              (l) => l.name
            );
            foundItem.strItemEnglishName = standardContent.value.list.map(
              (l) => l.englishName
            );
            foundItem.expCode = excludeContents.value.list.map((l) => l.code);
            foundItem.expId = excludeContents.value.list.map((l) => l.id);
            foundItem.expItemName = excludeContents.value.list.map(
              (l) => l.name
            );
            foundItem.expItemEnglishName = excludeContents.value.list.map(
              (l) => l.englishName
            );
            foundItem.name = "(C) " + customName.value;
            foundItem.englishName = "(C) " + customName.value;
            foundItem.type = 2;
          }
        } else {
          return;
        }
      }
      selectedContent.custom = true;
      state.noItemSelected = true;
      standardContent.value = {
        name: "",
        englishName: "",
        list: [],
      };
      excludeContents.value = {
        name: "",
        englishName: "",
        list: [],
      };
      onCancel();
    };

    const onCancel = () => {
      standardContent.value = {
        name: "",
        englishName: "",
        list: [],
      };
      excludeContents.value = {
        name: "",
        englishName: "",
        list: [],
      };
      itemEditModeYn.value = false;

      hideContextMenu();
      hideContextMenuItem();
      emit("update:isProductModalOpen", false);
      // fnSearchReset();
      searchKeyword.value = "";
    };

    //정렬순서
    const collaps = ref([]);
    const sortCollapsed = (index) => {
      return collaps.value[index];
    };
    //정렬순서
    const toggleSort = (event, i, pCode, code, list, divIdx) => {
      const initSort = document.querySelector(
        "[initSort='initSortModal_" + i + "']"
      );
      const spans = initSort.querySelectorAll("span");
      collaps.value[code + "_" + i] = !collaps.value[code + "_" + i];
      if (sortInit.value) {
        collaps.value[code + "_" + i] = sortInit.value;
        sortInit.value = false;
      }

      //이름 순서대로 정렬
      if (collaps.value[code + "_" + i] === true) {
        if (divIdx === 0) {
          list.sort((a, b) => {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();
            if (localStorage.getItem("lang") == "en") {
              x = a.englishName.toLowerCase();
              y = b.englishName.toLowerCase();
            }
            return x.localeCompare(y);
          });
        } else if (divIdx === 1) {
          list.sort((a, b) => {
            if (
              typeof a.desc1 !== "undefined" &&
              typeof b.desc1 !== "undefined"
            ) {
              let x = a.desc1.toLowerCase();
              let y = b.desc1.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc1.toLowerCase();
                y = b.englishDesc1.toLowerCase();
              }
              return x.localeCompare(y);
            }
          });
        } else if (divIdx === 2) {
          list.sort((a, b) => {
            if (
              typeof a.desc2 !== "undefined" &&
              typeof b.desc2 !== "undefined"
            ) {
              let x = a.desc2.toLowerCase();
              let y = b.desc2.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc2.toLowerCase();
                y = b.englishDesc2.toLowerCase();
              }
              return x.localeCompare(y);
            }
          });
        } else {
          list.sort((a, b) => {
            let x = "0";
            let y = "0";
            if (typeof a.desc3 !== "undefined") {
              x = a.desc3.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc3.toLowerCase();
              }
            } else {
              x = "0";
            }

            if (typeof b.desc3 !== "undefined") {
              y = b.desc3.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                y = b.englishDesc3.toLowerCase();
              }
            } else {
              y = "0";
            }
            return x.localeCompare(y, undefined, { numeric: true });
          });
        }
        spans[1].innerText = "arrow_drop_down"; // 20230612 구글 아이콘으로 변경
      } else {
        if (divIdx === 0) {
          list.sort((a, b) => {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();
            if (localStorage.getItem("lang") == "en") {
              x = a.englishName.toLowerCase();
              y = b.englishName.toLowerCase();
            }
            return y.localeCompare(x);
          });
        } else if (divIdx === 1) {
          list.sort((a, b) => {
            if (
              typeof a.desc1 !== "undefined" &&
              typeof b.desc1 !== "undefined"
            ) {
              let x = a.desc1.toLowerCase();
              let y = b.desc1.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc1.toLowerCase();
                y = b.englishDesc1.toLowerCase();
              }
              return y.localeCompare(x);
            }
          });
        } else if (divIdx === 2) {
          list.sort((a, b) => {
            if (
              typeof a.desc2 !== "undefined" &&
              typeof b.desc2 !== "undefined"
            ) {
              let x = a.desc2.toLowerCase();
              let y = b.desc2.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc2.toLowerCase();
                y = b.englishDesc2.toLowerCase();
              }
              return y.localeCompare(x);
            }
          });
        } else {
          list.sort((a, b) => {
            let x = "0";
            let y = "0";
            if (typeof a.desc3 !== "undefined") {
              x = a.desc3.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc3.toLowerCase();
              }
            } else {
              x = "0";
            }

            if (typeof b.desc3 !== "undefined") {
              y = b.desc3.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                y = b.englishDesc3.toLowerCase();
              }
            } else {
              y = "0";
            }
            return y.localeCompare(x, undefined, { numeric: true });
          });
        }
        spans[1].innerText = "arrow_drop_up"; // 20230612 구글 아이콘으로 변경
      }
    };

    const customName = ref("Customize");
    const selectedItems = ref([]);

    const productSearch = () => {
      const commonStore = useCommonStore();
      commonStore.onProductSearch(
        CONSTANT_PRODUCT_CODE.value,
        selectedValues.value.code,
        selectedValues.value.id
      );
    };

    const isListDisabled = ref(false);
    const listIndex = ref(0);
    const onListDisabled = (event, idx, val) => {
      isListDisabled.value = val;
      listIndex.value = idx;
    };

    // 여기에 추가하는 기능 넣기
    const onChangeItems = (event, i, type) => {
      closeContextMenu();
      // const options = event.target.selectedOptions;
      // console.log("options ::", event.target);
      // console.log("options ::", options);
      // const values = [];
      // for (let i = 0; i < options.length; i++) {
      //   // const option = options.item(i);
      //   // console.log("option ::", option);
      //   const option = options[i]._value;
      //   console.log("option ::", option);
      //   values.push({
      //     code: option.code,
      //     id: option.id,
      //     name: option.name,
      //     englishName: option.englishName,
      //     type: option.type,
      //   });
      // }
      // if (type === "standard") {
      //   standardValues.value = values;
      // } else {
      //   excludeValues.value = values;
      // }
    };

    return {
      removeItem,
      standardValues,
      excludeValues,
      handleDeleteKeyDownItem,
      deleteItemSelected,
      isContextMenuVisibleItem,
      contextMenuItemPosition,
      showContextMenuSelected,
      contextType,

      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
      //설정값
      constants,

      searchKeyword,
      openProductModal,
      closeProductModal,
      confirm,
      selectedList,
      state,
      onChange,
      focus,
      focusout,
      toggleSort,
      sortCollapsed,
      collaps,
      sortInit,
      contents,
      showContentModal,
      selectedContent,
      focusIndex0,
      searchContent,
      clearSearchKeyword,
      showDropBox,
      fnSearch,
      fnSearchReset,
      customName,
      selectedItems,
      onClickStd,
      onClickExcl,
      standardContent,
      excludeContents,
      selectedItem,
      onCancel,
      showContextMenu,
      isContextMenuVisible,
      contextMenuPosition,
      productSearch,

      isListDisabled,
      listIndex,
      onListDisabled,
      onChangeItems,
    };
  },
  methods: {
    ProductModal() {
      this.$emit("update:isProductModalOpen", true);
    },

    setSelectContent() {
      const cont = [];
      this.contents.forEach((content) => {
        if (
          constants.CONSTANT_MANUFACTURER_CODE.includes(content.code) ||
          constants.CONSTANT_DISTRIBUTER_CODE.includes(content.code) ||
          [constants.CONSTANT_ATC_CODE, constants.CONSTANT_OLD_ATC_CODE].includes(content.code) ||
          constants.CONSTANT_MOLECULE_CODE.includes(content.code) ||
          constants.CONSTANT_PRODUCT_CODE.includes(content.code)
        ) {
          cont.push(this.contents.findIndex((c) => c.code === content.code));
        }
      });

      return cont;
    },
  },
});
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.modal.is-open {
  opacity: 1;
  pointer-events: auto;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  width: 80%;
  /* height: 72%; */
  max-width: 1200px;
  max-height: 80%;
  min-height: calc(38vh + 350px);
  min-width: 1100px;
  /* overflow-y: auto; */
  border-radius: 8px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header > h2 {
  font-size: 18px;
  font-weight: 700;
}

.modal-body {
  margin-top: 5px;
}

.dropdown-container-menu {
  width: 100%;
}

.search-bar {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
}

.search-bar select {
  width: 120px;
  height: 30px;
  font-size: 14px;
}

.search-bar input[type="text"] {
  width: 250px;
  height: 30px;
  font-size: 14px;
}

.search-bar button {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.search-bar button:nth-child(3),
.search-bar button:nth-child(4),
.search-bar button:nth-child(5) {
  width: 80px;
  height: 30px;
  font-size: 14px;
}

.search-content {
  display: flex;
  flex: 1 1 auto;
  padding: 5px 0px;
  min-height: 38vh;
  margin-top: 5px;
}

.search-content-sub {
  flex: 1 1 auto;
  width: 100%;
}
.search-content-sub + .search-content-sub {
  margin-left: 5px;
}

.selected {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.selected .header {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 4px 16px;
  background-color: #f5f5f5;
  border: var(--border-line);
  border-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  /* 목록 펼치고 접기 0623 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected .header > span {
  display: inline-block;
  margin-left: 4px;
}

.selected .header > span.material-symbols-outlined {
  font-size: 26px;
  color: #b9b9b9;
}

.selected select {
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;
  padding: 12px;
  border: var(--border-line);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.selected select:focus {
  /* border: 1px solid #999; */
  outline: none;
}
.selected select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px 6px;
  margin: 1px 0;
}
.selected select option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}

/* select 선택시 라인 없애기  */
select:focus {
  outline: none;
}

/* 마우스 우큭릭 */
.context-menu {
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #666;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(6, 10, 19, 0.06);
}

.context-menu ul {
  width: 80px;
}

.context-menu li {
  display: flex;
  cursor: pointer;
  padding-left: 15px;
}

.context-menu li:hover {
  background-color: #efefef;
}

.boxes-container {
  display: flex;
  /* justify-content: space-between; */
  gap: 5px;
}

.standard-box,
.exclude-box {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 4px;
  border: var(--border-line);
  padding-left: 20px;
  font-size: 13px;
}

.standard-box div,
.exclude-box div {
  padding: 0px 0px 0px 4px;
  /* padding: 4px 6px; */
}

.standard-content,
.exclude-content {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
  height: 150px;
  width: 440px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: auto;
  border: var(--border-line);
  border-radius: var(--border-radius);
}

.standard-content option,
.exclude-content option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px 6px;
  margin: 1px 0;
}

.standard-content option:checked,
.exclude-content option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}

.standard-button,
.exclude-button {
  margin-right: 20px;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px; /* 요소들 사이의 간격 설정 */
  margin-top: 20px;
}

.footer-container textarea {
  height: 30px; /* textarea의 세로 높이 설정 */
  font-size: 20px;
}

.footer-container .btn {
  padding-left: 32px;
  padding-right: 32px;
}

.footer-container .btn:first-of-type {
  margin-left: 10px;
}

/* 목록 펼치고 접기 0623 */

.listDisabled {
  width: 3%;
}
.button-wide-open {
  position: absolute;
  right: 5px;
  height: 18px;
  z-index: 10;
}

.button-wide-open .material-symbols-outlined {
  font-size: 18px;
  color: #fff;
}

.button-wide-close {
  position: absolute;
  right: 5px;
  height: 18px;
  z-index: 10;
}

.button-wide-close .material-symbols-outlined {
  font-size: 18px;
  color: #fff;
}
</style>
