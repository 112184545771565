<template>
  
</template>

<script>
import { ref, defineComponent, onMounted, watch, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common";
import { useFavoritesStore } from "@/store/favorites.js";
import { useContentsStore } from "@/store/contents.js";
import ubistApi from "@/lib/ubistApi";
import menuData from "@/data/menuData.js";

export default defineComponent({
  name: "FavoriteInput",
  props: {
    favorite: {
      type: Object,
      required: false
    }
  },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const favoriteId = ref(route.query.favoriteId);
    const tabs = ref(route.query.tabs);
    const windows = ref(route.query.windows);

    const { t } = useI18n();
    const { favorite } = toRefs(props);

    const commonStore = useCommonStore();
    const favoritesStore = useFavoritesStore();
    const contentsStore = useContentsStore();
    const {
      serviceList,
    } = storeToRefs(commonStore);
    const {
      changedFavoriteList,
      removeFavoriteList,
    } = storeToRefs(favoritesStore);
    const {
      rows,
      columns,
      selectOptions,
      chooseOptions,
      favorites,
      rowsFavorites,
      columnsFavorites,
      selectOptionsFavorites,
      chooseOptionsFavorites,
      patternSelected,
      patternSelectedFavorites,
    } = storeToRefs(contentsStore);

    onMounted(async () => {
      if (!favoriteId.value) return;

      await commonStore.getMenuList(localStorage.getItem('productType'));
      const favorite = await ubistApi.getFavorite(favoriteId.value);
      if (favorite.isFolder) return;

      const service = _.find(serviceList.value, { code: favorite.serviceCode });
      console.log(favorite.serviceCode);
      if (!service) return;

      const serviceType = service.type;
      const servicePath = _.find(menuData, { code: service.code }).path;

      console.log(serviceType, servicePath);

      if (!serviceType) return;

      const payload = JSON.parse(favorite.payload);
      favorite.payload = payload;

      favorites.value = favorite;
      if (serviceType === 1) {
        [...payload.rows, ...payload.columns].forEach((content) => {
          content.list.forEach((item) => {
            if (!item.id) {
              item.id = item.name;
            }
          });
        });

        rowsFavorites.value = payload.rows;
        columnsFavorites.value = payload.columns;
        selectOptionsFavorites.value = payload.options;
        chooseOptionsFavorites.value = payload.chooseOptions;
      } else {
        patternSelectedFavorites.value = payload;
      }

      if (tabs.value) {
        const favorites = tabs.value.split(",");
        const url = `${location.origin}/Favorite?favoriteId=${favorites[0]}&tabs=${favorites.slice(1).join(",")}`;
        window.open(url, "_blank");
      }
      // if (windows.value) {
      //   const favorites = windows.value.split(",");
      //   const url = `${window.location.origin}/Favorite?favoriteId=${favorites[0]}&windows=${favorites.slice(1).join(",")}`;
      //   window.open(url, "_blank", 'top=10, left=10');
      // }

      router.push(servicePath);
    });
    return {

    };
  },
});
</script>

<style scoped>
.folder-tree-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.folder-tree-item.selected {
  background-color: #efefef;
}

.material-symbols-outlined {
  font-size: 18px;
  margin-right: 8px;
  font-variation-settings: 'wght' 300;
}
</style>
