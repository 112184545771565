export default {
  "title": {
    "sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])};fn.source="Sales";return fn;})(),
    "weekly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])};fn.source="Weekly";return fn;})(),
    "non-reimbursement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-reimbursement"])};fn.source="Non-reimbursement";return fn;})(),
    "combinationⅡ": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination Ⅱ"])};fn.source="Combination Ⅱ";return fn;})(),
    "diagnosisⅡ": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnosis Ⅱ"])};fn.source="Diagnosis Ⅱ";return fn;})(),
    "dosage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosage"])};fn.source="Dosage";return fn;})(),
    "vaccine": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaccine Sales"])};fn.source="Vaccine Sales";return fn;})(),
    "gis": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIS"])};fn.source="GIS";return fn;})(),
    "sob": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SoB"])};fn.source="SoB";return fn;})(),
    "combination_therapy1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination I"])};fn.source="Combination I";return fn;})(),
    "combination_therapy_new": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination I (New)"])};fn.source="Combination I (New)";return fn;})(),
    "combination_therapy2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination Ⅱ"])};fn.source="Combination Ⅱ";return fn;})(),
    "diagnosis": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnosis"])};fn.source="Diagnosis";return fn;})(),
    "diagnosis1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnosis I"])};fn.source="Diagnosis I";return fn;})(),
    "diagnosis2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnosis Ⅱ"])};fn.source="Diagnosis Ⅱ";return fn;})(),
    "generic": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic"])};fn.source="Generic";return fn;})(),
    "pattern_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern Product"])};fn.source="Pattern Product";return fn;})(),
    "pattern_molecule": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern Molecule"])};fn.source="Pattern Molecule";return fn;})(),
    "pattern_ATC": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern ATC"])};fn.source="Pattern ATC";return fn;})(),
    "popup_prodDetail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세 제품 검색"])};fn.source="상세 제품 검색";return fn;})(),
    "popup_options": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결과값 옵션"])};fn.source="결과값 옵션";return fn;})(),
    "notice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항"])};fn.source="공지사항";return fn;})(),
    "archives": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자료실"])};fn.source="자료실";return fn;})(),
    "drug_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["약품정보"])};fn.source="약품정보";return fn;})(),
    "vaccine_sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaccine Sales"])};fn.source="Vaccine Sales";return fn;})(),
    "unsubscribed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미구독"])};fn.source="미구독";return fn;})(),
    "sob2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])};fn.source="Sales";return fn;})(),
    "handling": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handling"])};fn.source="Handling";return fn;})(),
    "switching": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching"])};fn.source="Switching";return fn;})(),
    "Old_Data": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거 데이터"])};fn.source="과거 데이터";return fn;})(),
    "Old_D1_Sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D1 Sales (18)"])};fn.source="D1 Sales (18)";return fn;})(),
    "Old_D1_Non": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D1 Non-reimbursement (18)"])};fn.source="D1 Non-reimbursement (18)";return fn;})(),
    "Old_UP_Sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UP Sales (08~23)"])};fn.source="UP Sales (08~23)";return fn;})(),
    "Old_UP_Non": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UP Non-reimbursement (10~23)"])};fn.source="UP Non-reimbursement (10~23)";return fn;})()
  },
  "button": {
    "lang": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENGLISH"])};fn.source="ENGLISH";return fn;})(),
    "login": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])};fn.source="로그인";return fn;})(),
    "logout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])};fn.source="로그아웃";return fn;})(),
    "search": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])};fn.source="검색";return fn;})(),
    "searchClean": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지우기"])};fn.source="지우기";return fn;})(),
    "complete_confirm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])};fn.source="완료";return fn;})(),
    "complete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])};fn.source="완료";return fn;})(),
    "combine": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합치기"])};fn.source="합치기";return fn;})(),
    "put": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담기"])};fn.source="담기";return fn;})(),
    "reporting": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분석하기"])};fn.source="분석하기";return fn;})(),
    "reset": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초기화"])};fn.source="초기화";return fn;})(),
    "detailed_search": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세검색"])};fn.source="상세검색";return fn;})(),
    "prev_result": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분석결과"])};fn.source="분석결과";return fn;})(),
    "favorite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기"])};fn.source="즐겨찾기";return fn;})(),
    "excel_export": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엑셀 내보내기"])};fn.source="엑셀 내보내기";return fn;})(),
    "email_export": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 보내기"])};fn.source="이메일 보내기";return fn;})(),
    "mdx_view": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MDX 보기"])};fn.source="MDX 보기";return fn;})(),
    "openOption": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션"])};fn.source="옵션";return fn;})(),
    "save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])};fn.source="저장";return fn;})(),
    "close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])};fn.source="닫기";return fn;})(),
    "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])};fn.source="취소";return fn;})(),
    "delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])};fn.source="삭제";return fn;})(),
    "confirm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])};fn.source="확인";return fn;})(),
    "product_search": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품검색"])};fn.source="제품검색";return fn;})(),
    "prev_step": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["◀ 이전 단계"])};fn.source="◀ 이전 단계";return fn;})(),
    "next_step": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 단계 ▶"])};fn.source="다음 단계 ▶";return fn;})(),
    "move_top": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맨 위로"])};fn.source="맨 위로";return fn;})(),
    "move_up": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위로"])};fn.source="위로";return fn;})(),
    "move_down": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아래로"])};fn.source="아래로";return fn;})(),
    "move_bottom": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맨 아래로"])};fn.source="맨 아래로";return fn;})(),
    "change_rowsCols": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["행/열 바꾸기"])};fn.source="행/열 바꾸기";return fn;})(),
    "analyze": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분석하기"])};fn.source="분석하기";return fn;})(),
    "select": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택"])};fn.source="선택";return fn;})(),
    "editing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])};fn.source="편집";return fn;})(),
    "manual_download": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매뉴얼 보기"])};fn.source="매뉴얼 보기";return fn;})(),
    "view_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록으로"])};fn.source="목록으로";return fn;})(),
    "show_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록 보기"])};fn.source="목록 보기";return fn;})(),
    "new_folder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 폴더"])};fn.source="새 폴더";return fn;})(),
    "add_folder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 폴더 추가"])};fn.source="새 폴더 추가";return fn;})(),
    "add_folder2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴더 추가"])};fn.source="폴더 추가";return fn;})(),
    "add_favorites": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기 추가"])};fn.source="즐겨찾기 추가";return fn;})(),
    "service_settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 설정"])};fn.source="서비스 설정";return fn;})(),
    "contents_settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠 설정"])};fn.source="콘텐츠 설정";return fn;})(),
    "set_location": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치지정"])};fn.source="위치지정";return fn;})(),
    "market_definition": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장정의"])};fn.source="시장정의";return fn;})(),
    "insert": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력"])};fn.source="입력";return fn;})(),
    "favorites_settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기 관리"])};fn.source="즐겨찾기 관리";return fn;})(),
    "add_current_service_settings_to_favorites": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 서비스 설정을 즐겨찾기에 추가"])};fn.source="현재 서비스 설정을 즐겨찾기에 추가";return fn;})(),
    "add_current_contents_settings_to_favorites": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 콘텐츠 설정을 즐겨찾기에 추가"])};fn.source="현재 콘텐츠 설정을 즐겨찾기에 추가";return fn;})(),
    "select_start_week": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작 주 선택"])};fn.source="시작 주 선택";return fn;})(),
    "select_end_week": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 주 선택"])};fn.source="마지막 주 선택";return fn;})(),
    "rename": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름바꾸기"])};fn.source="이름바꾸기";return fn;})(),
    "add": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가"])};fn.source="추가";return fn;})(),
    "summation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합치기"])};fn.source="합치기";return fn;})(),
    "print": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인쇄"])};fn.source="인쇄";return fn;})(),
    "spread": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["펼치기"])};fn.source="펼치기";return fn;})(),
    "basic_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본정보"])};fn.source="기본정보";return fn;})(),
    "drug_price": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["약품 가격"])};fn.source="약품 가격";return fn;})(),
    "patent_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특허 정보"])};fn.source="특허 정보";return fn;})(),
    "dosage_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["투약 정보"])};fn.source="투약 정보";return fn;})(),
    "sales": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["처방조제액 추이"])};fn.source="처방조제액 추이";return fn;})(),
    "favorite_newTap": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 탭에서 열기"])};fn.source="새 탭에서 열기";return fn;})(),
    "favorite_newWindow": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 창에서 열기"])};fn.source="새 창에서 열기";return fn;})(),
    "favorite_newTap_multi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["새 탭에서 ", _interpolate(_named("count")), "개 모두 열기"])};fn.source="새 탭에서 {count}개 모두 열기";return fn;})(),
    "favorite_newWindow_multi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["새 창에서 ", _interpolate(_named("count")), "개 모두 열기"])};fn.source="새 창에서 {count}개 모두 열기";return fn;})(),
    "aside_hidden": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사이드바 자동 숨기기"])};fn.source="사이드바 자동 숨기기";return fn;})(),
    "register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록하기"])};fn.source="등록하기";return fn;})()
  },
  "label": {
    "selected_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 항목"])};fn.source="선택된 항목";return fn;})(),
    "sum": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합치기"])};fn.source="합치기";return fn;})(),
    "combine_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합치기 이름"])};fn.source="합치기 이름";return fn;})(),
    "summation_count": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합치기 항목 수"])};fn.source="합치기 항목 수";return fn;})(),
    "contents_sum1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠명"])};fn.source="콘텐츠명";return fn;})(),
    "contents_sum2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에 대한 "])};fn.source="에 대한 ";return fn;})(),
    "choose_not1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["◈ 선택하지 않으면, "])};fn.source="◈ 선택하지 않으면, ";return fn;})(),
    "choose_not2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["를 의미합니다."])};fn.source="를 의미합니다.";return fn;})(),
    "search_word": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색어를 입력해 주세요."])};fn.source="검색어를 입력해 주세요.";return fn;})(),
    "search_integrated": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 통합검색"])};fn.source=" 통합검색";return fn;})(),
    "no_content_selected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 콘텐츠가 없습니다."])};fn.source="선택된 콘텐츠가 없습니다.";return fn;})(),
    "columns": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가로축"])};fn.source="가로축";return fn;})(),
    "rows": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세로축"])};fn.source="세로축";return fn;})(),
    "select_parentItem": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["를(을) 선택하세요."])};fn.source="를(을) 선택하세요.";return fn;})(),
    "popup_std": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준"])};fn.source="기준";return fn;})(),
    "popup_exp": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제외"])};fn.source="제외";return fn;})(),
    "popup_customizingName": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["커스텀 이름"])};fn.source="커스텀 이름";return fn;})(),
    "step2_atc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ GIS ATC 검색은 2단계부터 가능합니다."])};fn.source="※ GIS ATC 검색은 2단계부터 가능합니다.";return fn;})(),
    "step4_atc": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D1 Combination ATC 검색은 4단계만 가능합니다."])};fn.source="D1 Combination ATC 검색은 4단계만 가능합니다.";return fn;})(),
    "search_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합치기 영역에서만 검색 가능합니다."])};fn.source="합치기 영역에서만 검색 가능합니다.";return fn;})(),
    "select_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성분/제품을 선택 후 진행하세요."])};fn.source="성분/제품을 선택 후 진행하세요.";return fn;})(),
    "select_product2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품을 선택 후 진행하세요."])};fn.source="제품을 선택 후 진행하세요.";return fn;})(),
    "option_notice1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 선택 시에만 적용됩니다."])};fn.source=" 선택 시에만 적용됩니다.";return fn;})(),
    "option_notice2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([])};fn.source="";return fn;})(),
    "option_notice3": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 콘텐츠가 선택 시에만 적용됩니다."])};fn.source=" 콘텐츠가 선택 시에만 적용됩니다.";return fn;})(),
    "option_notice4": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택하지 않으면 '정렬안함'으로 표시됩니다."])};fn.source="선택하지 않으면 '정렬안함'으로 표시됩니다.";return fn;})(),
    "not_selected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미선택"])};fn.source="미선택";return fn;})(),
    "combination_treatment_precautions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종별이 상급종합병원, 종합병원, 의원인 경우에만 진료과를 선택할 수 있습니다. 세부진료과는 종별이 상급종합병원 또는 종합병원인 경우에만 선택이 가능합니다."])};fn.source="종별이 상급종합병원, 종합병원, 의원인 경우에만 진료과를 선택할 수 있습니다. 세부진료과는 종별이 상급종합병원 또는 종합병원인 경우에만 선택이 가능합니다.";return fn;})(),
    "trend_view": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trend 보기"])};fn.source="Trend 보기";return fn;})(),
    "molecule_doses": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성분검색은 용량만 가능합니다."])};fn.source="성분검색은 용량만 가능합니다.";return fn;})(),
    "doseform_not_supported": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품 선택 시 제형 검색은 지원되지 않습니다."])};fn.source="제품 선택 시 제형 검색은 지원되지 않습니다.";return fn;})(),
    "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])};fn.source="제목";return fn;})(),
    "content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용"])};fn.source="내용";return fn;})(),
    "market_analysis": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장분석"])};fn.source="시장분석";return fn;})(),
    "education_information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교육정보"])};fn.source="교육정보";return fn;})(),
    "the_latest_drug_price": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신약가"])};fn.source="최신약가";return fn;})(),
    "manual": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매뉴얼"])};fn.source="매뉴얼";return fn;})(),
    "no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순번"])};fn.source="순번";return fn;})(),
    "date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성일"])};fn.source="작성일";return fn;})(),
    "attachment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부"])};fn.source="첨부";return fn;})(),
    "prev": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전글"])};fn.source="이전글";return fn;})(),
    "next": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음글"])};fn.source="다음글";return fn;})(),
    "attachment_file": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부파일"])};fn.source="첨부파일";return fn;})(),
    "input_folder_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴더명을 입력하세요."])};fn.source="폴더명을 입력하세요.";return fn;})(),
    "edit_folder_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴더명 수정"])};fn.source="폴더명 수정";return fn;})(),
    "set_folder_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴더명 설정"])};fn.source="폴더명 설정";return fn;})(),
    "folder_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴더명"])};fn.source="폴더명";return fn;})(),
    "input_favorite_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기명을 입력하세요."])};fn.source="즐겨찾기명을 입력하세요.";return fn;})(),
    "edit_favorite_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기명 수정"])};fn.source="즐겨찾기명 수정";return fn;})(),
    "set_favorite_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기 설정"])};fn.source="즐겨찾기 설정";return fn;})(),
    "favorite_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기명"])};fn.source="즐겨찾기명";return fn;})(),
    "favorite_location": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기 위치"])};fn.source="즐겨찾기 위치";return fn;})(),
    "notice_combination_therapy2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* 기준항목과 병용항목은 각각 하나씩만 선택 가능합니다. 단, 질병 분석 항목은 제품, 성분, ATC 항목과 조합할 수 없습니다."])};fn.source="* 기준항목과 병용항목은 각각 하나씩만 선택 가능합니다. 단, 질병 분석 항목은 제품, 성분, ATC 항목과 조합할 수 없습니다.";return fn;})(),
    "only_disease": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준을 질병으로 선택한 경우에만 결과형식을 선택할 수 있습니다."])};fn.source="기준을 질병으로 선택한 경우에만 결과형식을 선택할 수 있습니다.";return fn;})(),
    "measurement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["측정치"])};fn.source="측정치";return fn;})(),
    "measurement_info_title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 SoB 분석과 동일한 기준 선택 방법"])};fn.source="기존 SoB 분석과 동일한 기준 선택 방법";return fn;})(),
    "measurement_info_subtitle1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 SoB analytics와 동일한 기준 선택"])};fn.source="기존 SoB analytics와 동일한 기준 선택";return fn;})(),
    "measurement_info_subtitle2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 SoB analytics II와 동일한 기준 선택"])};fn.source="기존 SoB analytics II와 동일한 기준 선택";return fn;})(),
    "measurement_info_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비교기간: 이전 3개월"])};fn.source="비교기간: 이전 3개월";return fn;})(),
    "measurement_info_measurement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["측정치: 환자수"])};fn.source="측정치: 환자수";return fn;})(),
    "measurement_info_output_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출력기간: 이전 2년"])};fn.source="출력기간: 이전 2년";return fn;})(),
    "notice_combination_new_base": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병용범위 내 분석 시 기준항목 선택 없이 병용범위만 선택하세요."])};fn.source="병용범위 내 분석 시 기준항목 선택 없이 병용범위만 선택하세요.";return fn;})(),
    "view_all": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 보기"])};fn.source="전체 보기";return fn;})(),
    "age_over": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세 이상"])};fn.source="세 이상";return fn;})(),
    "age_under": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세 이하"])};fn.source="세 이하";return fn;})(),
    "onequantity_over": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이상"])};fn.source="이상";return fn;})(),
    "onequantity_under": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미만"])};fn.source="미만";return fn;})(),
    "perday_over": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회 이상"])};fn.source="회 이상";return fn;})(),
    "perday_under": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회 이하"])};fn.source="회 이하";return fn;})(),
    "totalday_over": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일 이상"])};fn.source="일 이상";return fn;})(),
    "totalday_under": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일 이하"])};fn.source="일 이하";return fn;})(),
    "start_week": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작 주"])};fn.source="시작 주";return fn;})(),
    "end_week": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 주"])};fn.source="마지막 주";return fn;})(),
    "molecule_total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성분 전체"])};fn.source="성분 전체";return fn;})(),
    "molecule_volume_total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성분(용량) 전체"])};fn.source="성분(용량) 전체";return fn;})(),
    "drug_info_infomain": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["· 브랜드/약품 검색, 약품 가격, 특허 정보는 당월의 최신 약품 변경 사항이 적용된 Weekly 라이브러리 기준 정보를 제공합니다.\n· 투약 정보, 처방조제액 추이는 전월까지의 약품 변경 사항이 적용된 Monthly 라이브러리 기준 정보를 제공합니다."])};fn.source="· 브랜드/약품 검색, 약품 가격, 특허 정보는 당월의 최신 약품 변경 사항이 적용된 Weekly 라이브러리 기준 정보를 제공합니다.\n· 투약 정보, 처방조제액 추이는 전월까지의 약품 변경 사항이 적용된 Monthly 라이브러리 기준 정보를 제공합니다.";return fn;})(),
    "drug_info_message1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["약품정보를 검색해보세요."])};fn.source="약품정보를 검색해보세요.";return fn;})(),
    "drug_info_message2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["약품명은 최소 2글자 이상 입력해주세요."])};fn.source="약품명은 최소 2글자 이상 입력해주세요.";return fn;})(),
    "detail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세히 보기"])};fn.source="자세히 보기";return fn;})(),
    "more_notice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항 더보기"])};fn.source="공지사항 더보기";return fn;})(),
    "browse_article": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자료실 찾아보기"])};fn.source="자료실 찾아보기";return fn;})(),
    "none": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비어있음"])};fn.source="비어있음";return fn;})(),
    "total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체"])};fn.source="전체";return fn;})(),
    "market_definition_selection": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 제품(브랜드/약품) 포함하는 ATC를 체크 해제 하는 경우 분석되지 않습니다.\n시장을 구성하는 ATC 중에서, 원하는 ATC만 체크한 후 분석할 수 있습니다."])};fn.source="선택한 제품(브랜드/약품) 포함하는 ATC를 체크 해제 하는 경우 분석되지 않습니다.\n시장을 구성하는 ATC 중에서, 원하는 ATC만 체크한 후 분석할 수 있습니다.";return fn;})(),
    "comparisonPeriod": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비교기간"])};fn.source="비교기간";return fn;})()
  },
  "message": {
    "validation_empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색어를 입력해 주세요."])};fn.source="검색어를 입력해 주세요.";return fn;})(),
    "validation_length": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색어를 입력해 주세요. 검색어는 최소한 두 자 이상이어야 합니다."])};fn.source="검색어를 입력해 주세요. 검색어는 최소한 두 자 이상이어야 합니다.";return fn;})(),
    "validation_menu": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("menu")), " 회원 전용 메뉴입니다."])};fn.source="{menu} 회원 전용 메뉴입니다.";return fn;})(),
    "select_newItem": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["중복된 항목 '", _interpolate(_named("existItem")), "'을(를) 제외한 새로운 항목 '", _interpolate(_named("newItem")), "'이(가) 추가 됩니다."])};fn.source="중복된 항목 '{existItem}'을(를) 제외한 새로운 항목 '{newItem}'이(가) 추가 됩니다.";return fn;})(),
    "validation_existsItem": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목이 중복되었습니다."])};fn.source="항목이 중복되었습니다.";return fn;})(),
    "validation_existsCombiItem": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("item")), "'은(는) 합치기 할 항목에 이미 포함되어 있습니다."])};fn.source="'{item}'은(는) 합치기 할 항목에 이미 포함되어 있습니다.";return fn;})(),
    "validation_intersectionCombiItem": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[", _interpolate(_named("content")), "]의 종속 관계인 [", _interpolate(_named("subContents")), "]을 합치기 하는 경우\n하위 범주인 [", _interpolate(_named("lowSubContent")), "]은 상위 범주인 [", _interpolate(_named("upSubContent")), "]에 추가 합산되어 분석되지 않습니다.\n하위 범주를 제외하고 분석하시기 바랍니다"])};fn.source="[{content}]의 종속 관계인 [{subContents}]을 합치기 하는 경우\n하위 범주인 [{lowSubContent}]은 상위 범주인 [{upSubContent}]에 추가 합산되어 분석되지 않습니다.\n하위 범주를 제외하고 분석하시기 바랍니다";return fn;})(),
    "validation_existsItemName": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("item")), "'항목은 이미 존재합니다. 다른 이름으로 변경해 주시기 바랍니다."])};fn.source="'{item}'항목은 이미 존재합니다. 다른 이름으로 변경해 주시기 바랍니다.";return fn;})(),
    "validation_deleteItem": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제 할 항목을 선택하세요."])};fn.source="삭제 할 항목을 선택하세요.";return fn;})(),
    "check_loginId": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID를 입력하세요."])};fn.source="ID를 입력하세요.";return fn;})(),
    "check_password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 입력하세요."])};fn.source="비밀번호를 입력하세요.";return fn;})(),
    "select_columnsOrRows": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가로축과 세로축에서 각각 1개 이상의 콘텐츠를 선택해야 합니다."])};fn.source="가로축과 세로축에서 각각 1개 이상의 콘텐츠를 선택해야 합니다.";return fn;})(),
    "select_columns": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["열을 선택해 주세요."])};fn.source="열을 선택해 주세요.";return fn;})(),
    "select_rows": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["행을 선택해 주세요."])};fn.source="행을 선택해 주세요.";return fn;})(),
    "select_metrics": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["측정치를 선택해 주세요."])};fn.source="측정치를 선택해 주세요.";return fn;})(),
    "select_contents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠가 선택되지 않았습니다. 검색할 콘텐츠를 먼저 선택해 주시기 바랍니다."])};fn.source="콘텐츠가 선택되지 않았습니다. 검색할 콘텐츠를 먼저 선택해 주시기 바랍니다.";return fn;})(),
    "select_items": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목이 선택되지 않았습니다. 항목을 먼저 선택해 주시기 바랍니다."])};fn.source="항목이 선택되지 않았습니다. 항목을 먼저 선택해 주시기 바랍니다.";return fn;})(),
    "select_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기간을 선택해 주시기 바랍니다."])};fn.source="기간을 선택해 주시기 바랍니다.";return fn;})(),
    "sametype_restriction": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준항목은 같은 종류의 항목만 선택할 수 있습니다."])};fn.source="기준항목은 같은 종류의 항목만 선택할 수 있습니다.";return fn;})(),
    "criterion_prerequisite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품을 제외하기 전에 먼저 기준을 선택해주시기 바랍니다."])};fn.source="제품을 제외하기 전에 먼저 기준을 선택해주시기 바랍니다.";return fn;})(),
    "exclusion_constraint": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제외할 제품은 선택된 기준에 포함된 제품이 아니기 때문에 제외할 수 없습니다."])};fn.source="제외할 제품은 선택된 기준에 포함된 제품이 아니기 때문에 제외할 수 없습니다.";return fn;})(),
    "delete_contents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠를 삭제할 경우 해당 콘텐츠에서 선택한 상세 항목이 모두 삭제됩니다. 정말 삭제하시겠습니까?"])};fn.source="콘텐츠를 삭제할 경우 해당 콘텐츠에서 선택한 상세 항목이 모두 삭제됩니다. 정말 삭제하시겠습니까?";return fn;})(),
    "loading_data": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터를 분석 중입니다."])};fn.source="데이터를 분석 중입니다.";return fn;})(),
    "validation_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("contentName")), "은(는) 합치기 할 수 없습니다."])};fn.source="{contentName}은(는) 합치기 할 수 없습니다.";return fn;})(),
    "alert_metricShare": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["측정치 내 share 를 선택 시, 아래와 같은 분석 조건일 경우 share 값이 부정확할 수 있습니다 .\n(참고) 분석 조건 \n1. 2 개 이상 콘텐츠를 복합적으로 선택한 경우 \n2. 합치기 항목이 포함된 경우 \n3. 콘텐츠 내 레벨이 다른 항목을 선택한 경우 \n( 예 - ATC 2단계와 3단계 동시 선택)"])};fn.source="측정치 내 share 를 선택 시, 아래와 같은 분석 조건일 경우 share 값이 부정확할 수 있습니다 .\n(참고) 분석 조건 \n1. 2 개 이상 콘텐츠를 복합적으로 선택한 경우 \n2. 합치기 항목이 포함된 경우 \n3. 콘텐츠 내 레벨이 다른 항목을 선택한 경우 \n( 예 - ATC 2단계와 3단계 동시 선택)";return fn;})(),
    "validation_combiName_empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합치기 할 항목의 이름을 입력하시기 바랍니다."])};fn.source="합치기 할 항목의 이름을 입력하시기 바랍니다.";return fn;})(),
    "validation_combiName_100over": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합치기 이름은 최대 100자를 넘을수 없습니다. 이름을 다시 입력해주세요."])};fn.source="합치기 이름은 최대 100자를 넘을수 없습니다. 이름을 다시 입력해주세요.";return fn;})(),
    "validation_combi_edit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합치기 편집중에는 리스트에 추가 할 수 없습니다."])};fn.source="합치기 편집중에는 리스트에 추가 할 수 없습니다.";return fn;})(),
    "combi_no_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합치기 할 항목이 없습니다."])};fn.source="합치기 할 항목이 없습니다.";return fn;})(),
    "search_no_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색 할 합치기 항목이 없습니다."])};fn.source="검색 할 합치기 항목이 없습니다.";return fn;})(),
    "select_no_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 선택되지 않은 항목이 있습니다."])};fn.source="아직 선택되지 않은 항목이 있습니다.";return fn;})(),
    "impossible_2over_total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성분, 제품, ATC의 전체 값 2개 이상 조합은 분석이 불가합니다."])};fn.source="성분, 제품, ATC의 전체 값 2개 이상 조합은 분석이 불가합니다.";return fn;})(),
    "impossible_combi_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종별, 병상의 조합은 분석이 불가합니다."])};fn.source="종별, 병상의 조합은 분석이 불가합니다.";return fn;})(),
    "impossible_combi_channel_region": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소지역, 병상의 조합은 분석이 불가합니다."])};fn.source="소지역, 병상의 조합은 분석이 불가합니다.";return fn;})(),
    "impossible_null_1over_total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘빈값 보기’ 옵션을 성분, 제품, ATC 전체와 조합하여 분석하는 경우 불필요한 분석으로 인해 결과값이 나오지 않는 경우가 발생할 수 있습니다. ‘빈값 보기’ 해제 후 분석하시기 바랍니다."])};fn.source="‘빈값 보기’ 옵션을 성분, 제품, ATC 전체와 조합하여 분석하는 경우 불필요한 분석으로 인해 결과값이 나오지 않는 경우가 발생할 수 있습니다. ‘빈값 보기’ 해제 후 분석하시기 바랍니다.";return fn;})(),
    "impossible_prodcombi_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아래 콘텐츠는 약품 합치기([제품]-약품)와 함께 분석할 수 없습니다.\n - 콘텐츠 : 제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로"])};fn.source="아래 콘텐츠는 약품 합치기([제품]-약품)와 함께 분석할 수 없습니다.\n - 콘텐츠 : 제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로";return fn;})(),
    "impossible_prodcust_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[제품] 콘텐츠에 ‘상세검색’으로 만들어진 ‘(C)’ 항목은 [제조사], [판매사], [ATC], [성분], [식약처], [제형], [투여경로] 콘텐츠와 조합할 수 없습니다.\n콘텐츠를 제거하여 주십시오."])};fn.source="[제품] 콘텐츠에 ‘상세검색’으로 만들어진 ‘(C)’ 항목은 [제조사], [판매사], [ATC], [성분], [식약처], [제형], [투여경로] 콘텐츠와 조합할 수 없습니다.\n콘텐츠를 제거하여 주십시오.";return fn;})(),
    "not_exists_board_prev": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전글이 없습니다."])};fn.source="이전글이 없습니다.";return fn;})(),
    "not_exists_board_next": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음글이 없습니다."])};fn.source="다음글이 없습니다.";return fn;})(),
    "impossible_etccombi_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아래 콘텐츠는 합치기 적용 시 약품([제품]-약품)과 함께 분석할 수 없습니다.\n - 콘텐츠 :  제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로"])};fn.source="아래 콘텐츠는 합치기 적용 시 약품([제품]-약품)과 함께 분석할 수 없습니다.\n - 콘텐츠 :  제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로";return fn;})(),
    "warning_sum2over_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATC, 제품, 성분, Generic 중 2가지 이상의 콘텐츠에서 sum을 선택하실 경우 일부 항목의 값이 다르게 표시될 수 있습니다."])};fn.source="ATC, 제품, 성분, Generic 중 2가지 이상의 콘텐츠에서 sum을 선택하실 경우 일부 항목의 값이 다르게 표시될 수 있습니다.";return fn;})(),
    "impossible_colstotal_content": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["목록 초과로 인해 가로축에 [", _interpolate(_named("content")), "]의 전체값을 구할 수 없습니다."])};fn.source="목록 초과로 인해 가로축에 [{content}]의 전체값을 구할 수 없습니다.";return fn;})(),
    "impossible_metrics_sum_total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["측정치가 세로축에 위치하는 경우 ‘총합계 보기’ 옵션을 사용할 수 없습니다. 옵션에서 ‘총합계 보기’를 해제한 후 분석하시기 바랍니다."])};fn.source="측정치가 세로축에 위치하는 경우 ‘총합계 보기’ 옵션을 사용할 수 없습니다. 옵션에서 ‘총합계 보기’를 해제한 후 분석하시기 바랍니다.";return fn;})(),
    "impossible_cross_selection": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("content")), " 그룹이 다른 경우 교차 선택이 불가능합니다."])};fn.source="{content} 그룹이 다른 경우 교차 선택이 불가능합니다.";return fn;})(),
    "select_channel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종별을 선택해 주시기 바랍니다."])};fn.source="종별을 선택해 주시기 바랍니다.";return fn;})(),
    "can_choose_medical": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종별이 상급종합병원, 종합병원, 의원인 경우에만 진료과를 선택할 수 있습니다."])};fn.source="종별이 상급종합병원, 종합병원, 의원인 경우에만 진료과를 선택할 수 있습니다.";return fn;})(),
    "cannot_choose_medical": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종별이 병원, 의원, 보건소, 기타인 경우에 세부진료과를 선택할 수 없습니다."])};fn.source="종별이 병원, 의원, 보건소, 기타인 경우에 세부진료과를 선택할 수 없습니다.";return fn;})(),
    "impossible_basecombi_cols_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준항목과 병용항목은 가로축에 배치할 수 없습니다."])};fn.source="기준항목과 병용항목은 가로축에 배치할 수 없습니다.";return fn;})(),
    "validation_basecombi_only1_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준항목과 병용항목은 하나씩만 선택 가능합니다."])};fn.source="기준항목과 병용항목은 하나씩만 선택 가능합니다.";return fn;})(),
    "select_base_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준항목은 반드시 선택해야 합니다."])};fn.source="기준항목은 반드시 선택해야 합니다.";return fn;})(),
    "select_combi_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병용항목은 반드시 선택해야 합니다."])};fn.source="병용항목은 반드시 선택해야 합니다.";return fn;})(),
    "validation_diagnosis_base_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준질병은 병용질병과 분석 가능합니다."])};fn.source="기준질병은 병용질병과 분석 가능합니다.";return fn;})(),
    "validation_diagnosis_combi_base": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병용질병은 기준질병과 분석 가능합니다."])};fn.source="병용질병은 기준질병과 분석 가능합니다.";return fn;})(),
    "impossible_dosage_cols_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["측정치, 기간 외의 다른 콘텐츠들은 가로축에 배치 할 수 없습니다."])};fn.source="측정치, 기간 외의 다른 콘텐츠들은 가로축에 배치 할 수 없습니다.";return fn;})(),
    "impossible_dosage_rows_item": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["측정치, 기간은 가로축에만 배치 할 수 있습니다."])};fn.source="측정치, 기간은 가로축에만 배치 할 수 있습니다.";return fn;})(),
    "validation_number": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숫자를 입력해 주시기 바랍니다."])};fn.source="숫자를 입력해 주시기 바랍니다.";return fn;})(),
    "validation_age": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앞의 연령이 더 작아야 합니다."])};fn.source="앞의 연령이 더 작아야 합니다.";return fn;})(),
    "notice_age_from_100over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["100세 이상의 세부 연령 정보는 제공하지 않습니다.\n직접 입력에서 ", _interpolate(_named("age")), "세 이상을 입력할 경우, 범위가 100세 이상으로 변환되어 분석됩니다."])};fn.source="100세 이상의 세부 연령 정보는 제공하지 않습니다.\n직접 입력에서 {age}세 이상을 입력할 경우, 범위가 100세 이상으로 변환되어 분석됩니다.";return fn;})(),
    "notice_age_to_100over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["100세 이상의 세부 연령 정보는 제공하지 않습니다.\n직접 입력에서 ", _interpolate(_named("age")), "세 이하를 입력할 경우, 범위가 100세 이상으로 변환되어 분석됩니다."])};fn.source="100세 이상의 세부 연령 정보는 제공하지 않습니다.\n직접 입력에서 {age}세 이하를 입력할 경우, 범위가 100세 이상으로 변환되어 분석됩니다.";return fn;})(),
    "validation_onequantity_same": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력하신 항목에는 같은 값이 들어갈 수 없습니다."])};fn.source="입력하신 항목에는 같은 값이 들어갈 수 없습니다.";return fn;})(),
    "validation_onequantity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앞의 1회 투약량 숫자가 더 작아야 합니다."])};fn.source="앞의 1회 투약량 숫자가 더 작아야 합니다.";return fn;})(),
    "notice_onequantity_from_9999over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["9999 이상의 투약량 정보는 제공하지 않습니다.\n직접 입력에서 ", _interpolate(_named("oneQuantity")), " 이상을 입력할 경우, 범위가 9999 이상으로 변환되어 분석됩니다."])};fn.source="9999 이상의 투약량 정보는 제공하지 않습니다.\n직접 입력에서 {oneQuantity} 이상을 입력할 경우, 범위가 9999 이상으로 변환되어 분석됩니다.";return fn;})(),
    "notice_onequantity_to_9999over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["9999 이상의 투약량 정보는 제공하지 않습니다.\n직접 입력에서 ", _interpolate(_named("oneQuantity")), " 미만을 입력할 경우, 범위가 0 이상으로 변환되어 분석됩니다."])};fn.source="9999 이상의 투약량 정보는 제공하지 않습니다.\n직접 입력에서 {oneQuantity} 미만을 입력할 경우, 범위가 0 이상으로 변환되어 분석됩니다.";return fn;})(),
    "validation_perday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앞의 1일 투약횟수 숫자가 더 작아야 합니다."])};fn.source="앞의 1일 투약횟수 숫자가 더 작아야 합니다.";return fn;})(),
    "notice_perday_from_9999over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["9999회 이상의 투약횟수 정보는 제공하지 않습니다.\n직접 입력에서 ", _interpolate(_named("perDay")), " 이상을 입력할 경우, 범위가 9999회 이상으로 변환되어 분석됩니다."])};fn.source="9999회 이상의 투약횟수 정보는 제공하지 않습니다.\n직접 입력에서 {perDay} 이상을 입력할 경우, 범위가 9999회 이상으로 변환되어 분석됩니다.";return fn;})(),
    "notice_perday_to_9999over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["9999회 이상의 투약횟수 정보는 제공하지 않습니다.\n직접 입력에서 ", _interpolate(_named("perDay")), " 이하를 입력할 경우, 범위가 1회 이상으로 변환되어 분석됩니다."])};fn.source="9999회 이상의 투약횟수 정보는 제공하지 않습니다.\n직접 입력에서 {perDay} 이하를 입력할 경우, 범위가 1회 이상으로 변환되어 분석됩니다.";return fn;})(),
    "validation_totalday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앞의 처방일수 숫자가 더 작아야 합니다."])};fn.source="앞의 처방일수 숫자가 더 작아야 합니다.";return fn;})(),
    "notice_totalday_from_9999over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["9999일 이상의 처방일수 정보는 제공하지 않습니다.\n직접 입력에서 ", _interpolate(_named("totalDay")), " 이상을 입력할 경우, 범위가 9999일 이상으로 변환되어 분석됩니다.."])};fn.source="9999일 이상의 처방일수 정보는 제공하지 않습니다.\n직접 입력에서 {totalDay} 이상을 입력할 경우, 범위가 9999일 이상으로 변환되어 분석됩니다..";return fn;})(),
    "notice_totalday_to_9999over": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["9999일 이상의 처방일수 정보는 제공하지 않습니다.\n직접 입력에서 ", _interpolate(_named("totalDay")), " 이하를 입력할 경우, 범위가 1일 이상으로 변환되어 분석됩니다."])};fn.source="9999일 이상의 처방일수 정보는 제공하지 않습니다.\n직접 입력에서 {totalDay} 이하를 입력할 경우, 범위가 1일 이상으로 변환되어 분석됩니다.";return fn;})(),
    "sob_market_select": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 제품/성분은 SoB 시장구성이 되어 있지 않는 제품입니다. 시장 구성 문의는 UBIST 영업 담당자에게 문의해주세요."])};fn.source="선택된 제품/성분은 SoB 시장구성이 되어 있지 않는 제품입니다. 시장 구성 문의는 UBIST 영업 담당자에게 문의해주세요.";return fn;})(),
    "max_50": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 50개까지 선택이 가능합니다."])};fn.source="최대 50개까지 선택이 가능합니다.";return fn;})(),
    "combination_new_tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 보기 선택 시 병용 대상 처방전의 ATC/성분/약품 전체 내용이 표시되며, 해제 시 병용 범위에 해당하는 ATC/성분/약품만 표시됩니다."])};fn.source="전체 보기 선택 시 병용 대상 처방전의 ATC/성분/약품 전체 내용이 표시되며, 해제 시 병용 범위에 해당하는 ATC/성분/약품만 표시됩니다.";return fn;})(),
    "only_quarterly_monthly_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병용범위만 지정 분석 시 분기 / 월 기간 분석만 가능합니다."])};fn.source="병용범위만 지정 분석 시 분기 / 월 기간 분석만 가능합니다.";return fn;})(),
    "validation_week_end": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작 주보다 이후의 기간을 선택해 주시기 바랍니다."])};fn.source="시작 주보다 이후의 기간을 선택해 주시기 바랍니다.";return fn;})(),
    "validation_week_start": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 주보다 이전의 기간을 선택해 주시기 바랍니다."])};fn.source="마지막 주보다 이전의 기간을 선택해 주시기 바랍니다.";return fn;})(),
    "select_week_end": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 주를 선택하세요."])};fn.source="마지막 주를 선택하세요.";return fn;})(),
    "select_week_start": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작 주를 선택하세요."])};fn.source="시작 주를 선택하세요.";return fn;})(),
    "favorites_folder_delete": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["폴더를 삭제할 경우 해당 폴더에 포함된 즐겨찾기도 함께 삭제됩니다.\n'", _interpolate(_named("name")), "' 폴더를 삭제하시겠습니까?"])};fn.source="폴더를 삭제할 경우 해당 폴더에 포함된 즐겨찾기도 함께 삭제됩니다.\n'{name}' 폴더를 삭제하시겠습니까?";return fn;})(),
    "favorites_delete": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "'을 삭제하시겠습니까?"])};fn.source="'{name}'을 삭제하시겠습니까?";return fn;})(),
    "favorites_folder_with_the_same_name_already_exists": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 이름의 즐겨찾기 폴더가 이미 존재합니다"])};fn.source="동일한 이름의 즐겨찾기 폴더가 이미 존재합니다";return fn;})(),
    "favorites_with_the_same_name_already_exists": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 이름의 즐겨찾기가 이미 존재합니다. 덮어쓰시겠습니까?"])};fn.source="동일한 이름의 즐겨찾기가 이미 존재합니다. 덮어쓰시겠습니까?";return fn;})(),
    "favorites_unable_to_move": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 위치로 폴더를 옮길 수 없습니다."])};fn.source="현재 위치로 폴더를 옮길 수 없습니다.";return fn;})(),
    "favorites_cannot_add_folder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 위치에서는 폴더를 추가 할 수 없습니다."])};fn.source="현재 위치에서는 폴더를 추가 할 수 없습니다.";return fn;})(),
    "favorites_cannot_add_favorite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 위치에서는 즐겨찾기를 추가 할 수 없습니다."])};fn.source="현재 위치에서는 즐겨찾기를 추가 할 수 없습니다.";return fn;})(),
    "input_favorite_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기명을 입력해 주세요."])};fn.source="즐겨찾기명을 입력해 주세요.";return fn;})(),
    "input_favorite_cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장을 하지 않고 현재 창을 닫게 되면 즐겨찾기가 추가되지 않습니다.\n정말 닫으시겠습니까?"])};fn.source="저장을 하지 않고 현재 창을 닫게 되면 즐겨찾기가 추가되지 않습니다.\n정말 닫으시겠습니까?";return fn;})(),
    "input_folder_name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴더명을 입력해 주세요."])};fn.source="폴더명을 입력해 주세요.";return fn;})(),
    "input_folder_cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장을 하지 않고 현재 창을 닫게 되면 폴더가 추가되지 않습니다.\n정말 닫으시겠습니까?"])};fn.source="저장을 하지 않고 현재 창을 닫게 되면 폴더가 추가되지 않습니다.\n정말 닫으시겠습니까?";return fn;})(),
    "validation_dosage_contents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[제품], [ATC], [성분] 콘텐츠는 필수 선택 사항이며 세로축에 배치되어 야 합니다. 이 중에서 최소 1개의 콘텐츠를 선택하여 주십시오."])};fn.source="[제품], [ATC], [성분] 콘텐츠는 필수 선택 사항이며 세로축에 배치되어 야 합니다. 이 중에서 최소 1개의 콘텐츠를 선택하여 주십시오.";return fn;})(),
    "validation_dosage_contents2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[1회 투약량], [1일 투약횟수], [총 처방일수] 콘텐츠는 필수 선택 사항이며 세로축에 배치되어야 합니다. 이 중에서 최소 1개의 콘텐츠를 선택하여 주십시오."])};fn.source="[1회 투약량], [1일 투약횟수], [총 처방일수] 콘텐츠는 필수 선택 사항이며 세로축에 배치되어야 합니다. 이 중에서 최소 1개의 콘텐츠를 선택하여 주십시오.";return fn;})(),
    "validation_dosage_order": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[1회 투약량], [1일 투약횟수], [총 처방일수] 콘텐츠는 세로 조건의 가장 아래에 위치해야 합니다. 세로축에 위치한 콘텐츠들의 순서를 변경하여 주세요."])};fn.source="[1회 투약량], [1일 투약횟수], [총 처방일수] 콘텐츠는 세로 조건의 가장 아래에 위치해야 합니다. 세로축에 위치한 콘텐츠들의 순서를 변경하여 주세요.";return fn;})(),
    "validation_dosage_order2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[제품] 콘텐츠는 [ATC]와[성분]보다 하위 콘텐츠로 [ATC], [성분]과 조합할 경우 상위 조건으로 들어갈 수 없습니다.\n[제품] 콘텐츠가 [ATC], [성분] 콘텐츠보다 아래에 위치하도록 콘텐츠들의 순서를 변경하여 주세요."])};fn.source="[제품] 콘텐츠는 [ATC]와[성분]보다 하위 콘텐츠로 [ATC], [성분]과 조합할 경우 상위 조건으로 들어갈 수 없습니다.\n[제품] 콘텐츠가 [ATC], [성분] 콘텐츠보다 아래에 위치하도록 콘텐츠들의 순서를 변경하여 주세요.";return fn;})(),
    "validation_dosage_averagemetrics": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["측정치에서 [평균 처방량] 또는 [평균 처방일수]를 선택할 경우, 콘텐츠를 [합치기] 하거나, 분석할 범위를 텍스트 박스에 직접 입력할 수 없습니다. [합치기] 조건 또는 직접 입력 조건을 제외하고 분석하시기 바랍니다."])};fn.source="측정치에서 [평균 처방량] 또는 [평균 처방일수]를 선택할 경우, 콘텐츠를 [합치기] 하거나, 분석할 범위를 텍스트 박스에 직접 입력할 수 없습니다. [합치기] 조건 또는 직접 입력 조건을 제외하고 분석하시기 바랍니다.";return fn;})(),
    "validation_dosage_period_averagemetrics": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["측정치에서 [평균 처방량] 또는 [평균 처방일수]를 선택할 경우, 기간 그룹의 교차 선택이 불가능합니다."])};fn.source="측정치에서 [평균 처방량] 또는 [평균 처방일수]를 선택할 경우, 기간 그룹의 교차 선택이 불가능합니다.";return fn;})(),
    "please_custom_product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["커스텀할 항목을 선택해 주시기 바랍니다."])};fn.source="커스텀할 항목을 선택해 주시기 바랍니다.";return fn;})(),
    "optionmodal_save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장할 경우 결과값 옵션이 변경됩니다. 저장하시겠습니까?"])};fn.source="저장할 경우 결과값 옵션이 변경됩니다. 저장하시겠습니까?";return fn;})(),
    "optionmodal_no_save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장을 하지 않고 현재 창을 닫게 되면 옵션이 적용되지 않습니다. 정말 닫으시겠습니까?"])};fn.source="저장을 하지 않고 현재 창을 닫게 되면 옵션이 적용되지 않습니다. 정말 닫으시겠습니까?";return fn;})(),
    "analysis_mat_one": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAT 분석은 한 개만 가능합니다."])};fn.source="MAT 분석은 한 개만 가능합니다.";return fn;})(),
    "no_drug_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["약품 정보가 없습니다."])};fn.source="약품 정보가 없습니다.";return fn;})(),
    "main_account_info": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 사용 정보"])};fn.source="나의 사용 정보";return fn;})(),
    "main_contract_period": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 계약 기간"])};fn.source="나의 계약 기간";return fn;})(),
    "main_contract_periodM_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("month")), "개월 후 계약 종료"])};fn.source="{month}개월 후 계약 종료";return fn;})(),
    "main_contract_periodYM_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("year")), "년 ", _interpolate(_named("month")), "개월 후 계약 종료"])};fn.source="{year}년 {month}개월 후 계약 종료";return fn;})(),
    "main_contract_periodY_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("year")), "년 후 계약 종료"])};fn.source="{year}년 후 계약 종료";return fn;})(),
    "main_contract_contents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 계약 콘텐츠"])};fn.source="나의 계약 콘텐츠";return fn;})(),
    "main_assistance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도움이 필요하신가요?"])};fn.source="도움이 필요하신가요?";return fn;})(),
    "main_search_archives": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자료실에서\n\r제목/내용을 검색하세요."])};fn.source="자료실에서\n\r제목/내용을 검색하세요.";return fn;})(),
    "main_contact_representative": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자에게 문의하기"])};fn.source="담당자에게 문의하기";return fn;})(),
    "main_contact_representative2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 담당자에게 문의하세요."])};fn.source="나의 담당자에게 문의하세요.";return fn;})(),
    "validation_notinclude_column": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["가로축 [", _interpolate(_named("content")), "]에는 '상위(top), 하위(bottom)'를 선택할 수 없습니다.\n위치를 세로축으로 이동하여 분석하시기 바랍니다. "])};fn.source="가로축 [{content}]에는 '상위(top), 하위(bottom)'를 선택할 수 없습니다.\n위치를 세로축으로 이동하여 분석하시기 바랍니다. ";return fn;})(),
    "validation_topbottom_measurement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["측정치를 여러 개 선택 후 세로축 배치 시,\n'상위(top), 하위(bottom)'를 선택한 콘텐츠를 함께 배치할 수 없습니다.\n측정치를 한 개만 선택하거나, '상위(top), 하위(bottom)' 조건을 제외하고 분석하시기 바랍니다."])};fn.source="측정치를 여러 개 선택 후 세로축 배치 시,\n'상위(top), 하위(bottom)'를 선택한 콘텐츠를 함께 배치할 수 없습니다.\n측정치를 한 개만 선택하거나, '상위(top), 하위(bottom)' 조건을 제외하고 분석하시기 바랍니다.";return fn;})(),
    "validation_topbottom_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("topbottom1")), "를 선택한 [", _interpolate(_named("topbottom2")), "]보다 위에 위치한\n", _interpolate(_named("message")), " 조건을 다시 설정하거나,\n콘텐츠 배치 순서를 변경하여 분석하시기 바랍니다."])};fn.source="{topbottom1}를 선택한 [{topbottom2}]보다 위에 위치한\n{message} 조건을 다시 설정하거나,\n콘텐츠 배치 순서를 변경하여 분석하시기 바랍니다.";return fn;})(),
    "validation_topbottom_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("topbottom1")), "를 선택한 [", _interpolate(_named("topbottom2")), "]보다 위에 위치한\n", _interpolate(_named("message")), " 조건을 다시 설정하거나,\n콘텐츠 배치 순서를 변경하여 분석하시기 바랍니다."])};fn.source="{topbottom1}를 선택한 [{topbottom2}]보다 위에 위치한\n{message} 조건을 다시 설정하거나,\n콘텐츠 배치 순서를 변경하여 분석하시기 바랍니다.";return fn;})(),
    "validation_additional_measurement_option_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["측정치 [", _interpolate(_named("measurement")), "] 선택 시,\n", _interpolate(_named("message")), "를 할 수 없습니다.\n[합치기] 조건을 제외하고 분석하시기 바랍니다."])};fn.source="측정치 [{measurement}] 선택 시,\n{message}를 할 수 없습니다.\n[합치기] 조건을 제외하고 분석하시기 바랍니다.";return fn;})(),
    "validation_additional_measurement_option_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["측정치 [", _interpolate(_named("measurement")), "] 선택 시,\n", _interpolate(_named("message")), "를 할 수 없습니다.\n조건을 다시 설정하여 분석하시기 바랍니다."])};fn.source="측정치 [{measurement}] 선택 시,\n{message}를 할 수 없습니다.\n조건을 다시 설정하여 분석하시기 바랍니다.";return fn;})(),
    "validation_additional_measurement_option(share)_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["측정치 [", _interpolate(_named("measurement")), "] 선택 시,\n", _interpolate(_named("message")), "는 측정치가 위치하지 않은 축의 가장 마지막에 위치해야 합니다.\n콘텐츠 배치 순서를 변경하여 분석하시기 바랍니다."])};fn.source="측정치 [{measurement}] 선택 시,\n{message}는 측정치가 위치하지 않은 축의 가장 마지막에 위치해야 합니다.\n콘텐츠 배치 순서를 변경하여 분석하시기 바랍니다.";return fn;})(),
    "validation_additional_measurement_option(share)_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["측정치 [", _interpolate(_named("measurement")), "] 선택 시,\n", _interpolate(_named("message")), "는 측정치가 위치하지 않은 축의 가장 마지막에 위치해야 합니다.\n콘텐츠 배치 순서를 변경하여 분석하시기 바랍니다."])};fn.source="측정치 [{measurement}] 선택 시,\n{message}는 측정치가 위치하지 않은 축의 가장 마지막에 위치해야 합니다.\n콘텐츠 배치 순서를 변경하여 분석하시기 바랍니다.";return fn;})(),
    "validation_combination": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[", _interpolate(_named("content")), "]의 [합치기]"])};fn.source="[{content}]의 [합치기]";return fn;})(),
    "validation_combination(share)": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[", _interpolate(_named("content")), "]의 [합치기]"])};fn.source="[{content}]의 [합치기]";return fn;})(),
    "validation_hiddencombi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[", _interpolate(_named("content")), "]의 [", _interpolate(_named("hiddencombi")), "]"])};fn.source="[{content}]의 [{hiddencombi}]";return fn;})(),
    "validation_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[", _interpolate(_named("content")), "]의 [서로 다른 단계인 [", _interpolate(_named("otherlevel")), "]을 동시 선택]"])};fn.source="[{content}]의 [서로 다른 단계인 [{otherlevel}]을 동시 선택]";return fn;})(),
    "validation_otherlevel(share)": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[", _interpolate(_named("content")), "]에 [서로 다른 단계인 [", _interpolate(_named("otherlevel")), "]]"])};fn.source="[{content}]에 [서로 다른 단계인 [{otherlevel}]]";return fn;})(),
    "sum_topbottom_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("topbottom1")), "를 선택한 [", _interpolate(_named("topbottom2")), "]보다 위에 위치한\n", _interpolate(_named("message")), "를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?"])};fn.source="{topbottom1}를 선택한 [{topbottom2}]보다 위에 위치한\n{message}를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?";return fn;})(),
    "sum_topbottom_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("topbottom1")), "를 선택한 [", _interpolate(_named("topbottom2")), "]보다 위에 위치한\n", _interpolate(_named("message")), "를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?"])};fn.source="{topbottom1}를 선택한 [{topbottom2}]보다 위에 위치한\n{message}를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?";return fn;})(),
    "sum_additional_measurement_option_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["측정치 [", _interpolate(_named("measurement")), "] 선택 시,\n", _interpolate(_named("message")), "를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?"])};fn.source="측정치 [{measurement}] 선택 시,\n{message}를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?";return fn;})(),
    "sum_additional_measurement_option_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["측정치 [", _interpolate(_named("measurement")), "] 선택 시,\n", _interpolate(_named("message")), "를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?"])};fn.source="측정치 [{measurement}] 선택 시,\n{message}를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?";return fn;})(),
    "sum_additional_measurement_option(share)_combi": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["측정치 [", _interpolate(_named("measurement")), "] 선택 시,\n", _interpolate(_named("message")), "를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?"])};fn.source="측정치 [{measurement}] 선택 시,\n{message}를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?";return fn;})(),
    "sum_additional_measurement_option(share)_otherlevel": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["측정치 [", _interpolate(_named("measurement")), "] 선택 시,\n", _interpolate(_named("message")), "를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?"])};fn.source="측정치 [{measurement}] 선택 시,\n{message}를 하는 경우에는\n엑셀파일로 자동 변환됩니다.\n계속하시겠습니까?";return fn;})(),
    "analyze_timeout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분석 시간이 만료되었습니다. 분석 조건을 변경 후 다시 시도하여 주시기 바랍니다."])};fn.source="분석 시간이 만료되었습니다. 분석 조건을 변경 후 다시 시도하여 주시기 바랍니다.";return fn;})(),
    "analyze_error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분석에 실패하였습니다.\n잠시 후 다시 시도하여 주시기 바랍니다."])};fn.source="분석에 실패하였습니다.\n잠시 후 다시 시도하여 주시기 바랍니다.";return fn;})(),
    "analyze_report_error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분석에 실패하였습니다.\n잠시 후 다시 시도하여 주시기 바랍니다."])};fn.source="분석에 실패하였습니다.\n잠시 후 다시 시도하여 주시기 바랍니다.";return fn;})(),
    "unsubscribed_content": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미구독 콘텐츠가 포함되어 있습니다.\n 담당자에게 문의해 주시기 바랍니다."])};fn.source="미구독 콘텐츠가 포함되어 있습니다.\n 담당자에게 문의해 주시기 바랍니다.";return fn;})(),
    "unsubscribed_service": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미구독 콘텐츠입니다."])};fn.source="미구독 콘텐츠입니다.";return fn;})(),
    "IP_block": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 IP는 차단 되었습니다."])};fn.source="해당 IP는 차단 되었습니다.";return fn;})(),
    "need_register_ubist_account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업 계정 등록이 필요합니다."])};fn.source="기업 계정 등록이 필요합니다.";return fn;})(),
    "validation_hospital_specialty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[참고] 종별 [병원] 및 진료과 분석 시, 진료과 [others]가 선택되어야 분석 결과에 [병원] 데이터가 포함됩니다.\n해당 사항을 참고하여 분석하시기 바랍니다."])};fn.source="[참고] 종별 [병원] 및 진료과 분석 시, 진료과 [others]가 선택되어야 분석 결과에 [병원] 데이터가 포함됩니다.\n해당 사항을 참고하여 분석하시기 바랍니다.";return fn;})(),
    "validation_clinic_subSpecialty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[참고] 종별 [의원] 및 세부진료과 분석 시, [분리되지않은내과]가 선택되어야 분석 결과에 [의원] 데이터가 포함됩니다.\n해당 사항을 참고하여 분석하시기 바랍니다."])};fn.source="[참고] 종별 [의원] 및 세부진료과 분석 시, [분리되지않은내과]가 선택되어야 분석 결과에 [의원] 데이터가 포함됩니다.\n해당 사항을 참고하여 분석하시기 바랍니다.";return fn;})(),
    "validation_nonreimbursement_projqty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["처방량_Projection 측정치 안내\n- 처방량_Projection 측정치는 [1회 투약량 × 1회 투약횟수 × 총 투약일수]를 의미합니다.\n- 비급여 제품은 처방전별로 약품단가 및 처방기준이 상이하여, 처방량 해석에 주의가 필요합니다.\n- 처방량_Projection은 일부 정부지원 약품의 해석을 돕기 위해 보조적인 참고치로 임시 제공하고 있으며, 연내 제공 중단 예정이오니 타 제품은 표준가격처방량_Projection 측정치를 사용하여 주십시오."])};fn.source="처방량_Projection 측정치 안내\n- 처방량_Projection 측정치는 [1회 투약량 × 1회 투약횟수 × 총 투약일수]를 의미합니다.\n- 비급여 제품은 처방전별로 약품단가 및 처방기준이 상이하여, 처방량 해석에 주의가 필요합니다.\n- 처방량_Projection은 일부 정부지원 약품의 해석을 돕기 위해 보조적인 참고치로 임시 제공하고 있으며, 연내 제공 중단 예정이오니 타 제품은 표준가격처방량_Projection 측정치를 사용하여 주십시오.";return fn;})(),
    "select_all": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체와 다른 항목은 함께 선택 할 수 없습니다."])};fn.source="전체와 다른 항목은 함께 선택 할 수 없습니다.";return fn;})(),
    "validation_cl_diagnosis_patient_visit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신환여부의 'New'와 초재진구분의 '재진'은 교차 선택이 불가능합니다."])};fn.source="신환여부의 'New'와 초재진구분의 '재진'은 교차 선택이 불가능합니다.";return fn;})(),
    "validation_old_UP_Non": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UP Non-reimbursement 과거데이터는 2010년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오."])};fn.source="UP Non-reimbursement 과거데이터는 2010년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오.";return fn;})(),
    "validation_old_KFDA": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["식약처 컨텐츠는 2012년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오."])};fn.source="식약처 컨텐츠는 2012년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오.";return fn;})(),
    "validation_old_generic": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic 컨텐츠는 2015년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오."])};fn.source="Generic 컨텐츠는 2015년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오.";return fn;})(),
    "validation_old_reimbursement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["급여구분 컨텐츠는 2018년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오."])};fn.source="급여구분 컨텐츠는 2018년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오.";return fn;})(),
    "validation_old_patientType": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PatientType 컨텐츠는 2010년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오."])};fn.source="PatientType 컨텐츠는 2010년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오.";return fn;})(),
    "validation_old_standardMetrics": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표준가격처방량P 측정치는 2015년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오."])};fn.source="표준가격처방량P 측정치는 2015년 이후부터 분석 가능합니다.\n기간을 다시 선택하여 주십시오.";return fn;})(),
    "validation_old_dataAlgorithmPanel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아래 기간 별로 알고리즘과 패널 수에 차이가 있습니다.\n데이터 분석 시 참고하시기 바랍니다.\n- 2008~2014\n- 2015~2016\n- 2017~2023"])};fn.source="아래 기간 별로 알고리즘과 패널 수에 차이가 있습니다.\n데이터 분석 시 참고하시기 바랍니다.\n- 2008~2014\n- 2015~2016\n- 2017~2023";return fn;})(),
    "validation_old_dataAlgorithmPanel_non": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아래 기간 별로 알고리즘과 패널 수에 차이가 있습니다.\n데이터 분석 시 참고하시기 바랍니다.\n- 2010~2014\n- 2015~2016\n- 2017~2023"])};fn.source="아래 기간 별로 알고리즘과 패널 수에 차이가 있습니다.\n데이터 분석 시 참고하시기 바랍니다.\n- 2010~2014\n- 2015~2016\n- 2017~2023";return fn;})()
  },
  "error": {
    "401": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 조건으로는 데이터가 나오지 않습니다.\n다른 조건으로 다시 조회해 주세요."])};fn.source="해당 조건으로는 데이터가 나오지 않습니다.\n다른 조건으로 다시 조회해 주세요.";return fn;})(),
    "402": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 제품이 포함된 ATC가 제외된 경우, 분석할 수 없습니다.\n해당 ATC를 추가한 후 분석하시기 바랍니다."])};fn.source="선택한 제품이 포함된 ATC가 제외된 경우, 분석할 수 없습니다.\n해당 ATC를 추가한 후 분석하시기 바랍니다.";return fn;})(),
    "901": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엑셀 생성시 허용 최대 행수(1,048,576)나 열수(18,278)를 초과하였습니다.\n분석범위를 줄이시기 바랍니다."])};fn.source="엑셀 생성시 허용 최대 행수(1,048,576)나 열수(18,278)를 초과하였습니다.\n분석범위를 줄이시기 바랍니다.";return fn;})(),
    "902": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터가 없습니다."])};fn.source="데이터가 없습니다.";return fn;})(),
    "903": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분석 시간이 만료되었습니다. 분석 조건을 변경 후 다시 시도하여 주시기 바랍니다."])};fn.source="분석 시간이 만료되었습니다. 분석 조건을 변경 후 다시 시도하여 주시기 바랍니다.";return fn;})(),
    "909": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분석에 실패하였습니다.\n잠시 후 다시 시도하여 주시기 바랍니다."])};fn.source="분석에 실패하였습니다.\n잠시 후 다시 시도하여 주시기 바랍니다.";return fn;})()
  },
  "footer": {
    "company": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(주)유비케어"])};fn.source="(주)유비케어";return fn;})(),
    "bizInfo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자 정보"])};fn.source="사업자 정보";return fn;})(),
    "terms": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관"])};fn.source="이용약관";return fn;})(),
    "privacy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 처리방침"])};fn.source="개인정보 처리방침";return fn;})(),
    "business_number": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자 등록번호"])};fn.source="사업자 등록번호";return fn;})(),
    "CEO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표자명"])};fn.source="대표자명";return fn;})(),
    "phone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])};fn.source="전화번호";return fn;})(),
    "address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])};fn.source="주소";return fn;})(),
    "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이상경"])};fn.source="이상경";return fn;})(),
    "address_detail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07335 서울특별시 영등포구 여의대로 108, 29층, 30층, 31층(여의도동, 파크원타워2) 유비케어"])};fn.source="07335 서울특별시 영등포구 여의대로 108, 29층, 30층, 31층(여의도동, 파크원타워2) 유비케어";return fn;})()
  }
}